import { Cliente } from './Cliente';
import { Contato } from './Contato';


export class ContatoCliente {

    contatoId: number = 0;
    contato!: Contato;
    clienteId: number = 0;
    cliente!: Cliente;
    

    constructor(model?: ContatoCliente){

        if(!model)
            return;

        this.contatoId = model.contatoId;
        this.contato = model.contato;
        this.clienteId = model.clienteId;
        this.cliente = model.cliente;
    }
}