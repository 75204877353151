import { Empresa } from "../geral";

export class Faina implements Shared.IEntity{

    id: number = 0;
    empresaId: number = 0;
    empresa!: Empresa;
    nome: string = '';
    nomeIngles: string = '';

    constructor(model?: Faina){

        if(!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;
        this.nome = model.nome;
        this.nomeIngles = model.nomeIngles;
    }
}