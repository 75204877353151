import { http, httpHeader } from "@/core/ApiConfig";
import { Service } from "../shared";

export class PedidoCompraService extends Service {
    
    constructor() {
      super('pedidoCompra');
    }

    private GetFiltro(parametros: string, filter: any) {
      if (filter) {
        let paramTemp = '';

        if (filter.numero) {
          if (paramTemp) { paramTemp += ' and '; }
          paramTemp += `numero eq ${filter.numero}`;
        }

        if (filter.numeroRo) {
          if (paramTemp) { paramTemp += ' and '; }
          // paramTemp += `registroOperacionalEquipamento/registroOperacional/numero eq ${filter.numeroRo}`;
          paramTemp += `equipamentos/any(o: o/equipamento/registroOperacional/numero eq ${filter.numeroRo})`;
        }

        if (filter.numeroOs) {
          if (paramTemp) { paramTemp += ' and '; }
          // paramTemp += `registroOperacionalEquipamento/sequencia eq ${filter.numeroOs}`;
          paramTemp += `equipamentos/any(o: o/equipamento/sequencia eq ${filter.numeroOs})`;
        }

        if (filter.responsavelId) {
          if (paramTemp) { paramTemp += ' and '; }
          paramTemp += `responsavelId eq ${filter.responsavelId}`;
        }

        if (filter.equipamentoId) {
          if (paramTemp) { paramTemp += ' and '; }
          // paramTemp += `registroOperacionalEquipamento/equipamentoId eq ${filter.equipamentoId}`;
          paramTemp += `equipamentos/any(o: o/equipamento/equipamentoId eq ${filter.equipamentoId})`;
        }
  
        if (filter.fornecedorId) {
          if (paramTemp) { paramTemp += ' and '; }
          // paramTemp += `registroOperacionalEquipamento/fornecedorId eq ${filter.fornecedorId}`;
          paramTemp += `equipamentos/any(o: o/equipamento/fornecedorId eq ${filter.fornecedorId})`;
        }

        if (filter.dataHoraSolicitacaoInicial) {
          if (paramTemp) { paramTemp += ' and '; }            
            paramTemp += `equipamentos/any(o: o/dataHoraSolicitacao ge ${new Date(filter.dataHoraSolicitacaoInicial).toISOString()})`;
        }

        if (filter.dataHoraSolicitacaoFinal) {
          if (paramTemp) { paramTemp += ' and '; }          
            paramTemp += `equipamentos/any(o: o/dataHoraSolicitacao le ${new Date(filter.dataHoraSolicitacaoFinal).toISOString()})`;
        }

        if (filter.dataHoraLiberacaoInicial) {
          if (paramTemp) { paramTemp += ' and '; }
            paramTemp += `equipamentos/any(o: o/dataHoraLiberacao ge ${new Date(filter.dataHoraLiberacaoInicial).toISOString()})`;
        }

        if (filter.dataHoraLiberacaoFinal) {
          if (paramTemp) { paramTemp += ' and '; }          
            paramTemp += `equipamentos/any(o: o/dataHoraLiberacao le ${new Date(filter.dataHoraLiberacaoFinal).toISOString()})`;
        }

        if (filter.dataPagamentoInicial) {
          if (paramTemp) { paramTemp += ' and '; }
            paramTemp += `equipamentos/any(o: o/dataPagamento ge ${filter.dataPagamentoInicial})`;
        }

        if (filter.dataPagamentoFinal) {
          if (paramTemp) { paramTemp += ' and '; }          
            paramTemp += `equipamentos/any(o: o/dataPagamento le ${filter.dataPagamentoFinal})`;
        }
  
        if (paramTemp) {
  
          if (parametros) {
            parametros += '&';
          }
          else {
            parametros += '?';
          }
  
          parametros += `$filter=${paramTemp}`;
        }  
      }
      return parametros;
    }

    public AutoComplete(q: string) {
      return http.get(`${this.GetNomeControle()}/AutoComplete`, {
        params: {
          q: q
        },
        headers: httpHeader.headers
      });
    }

    ListarComFiltro(page: number, pageSize: number, sortBy: any[], desc: any[], search: any, columns: any[], filter: any, include?: string) {
      let parametros = this.GetParamentroPaginacaoOrdenacao(page, pageSize, sortBy, desc, search, columns);
      parametros = this.GetFiltro(parametros, filter);
      return http.get(`${this.GetNomeControle()}${parametros}`, this.GetHeader(include));
    }

    public ObterPorRegistroOperacional(registroOperacionalId: number) {
      return http.get(`${this.GetNomeControle()}/ObterPorRegistroOperacional`,
      {
        params:{
          registroOperacionalId
        }
      });
    }

    public Detalhar(id: number) {
      return http.get(`${this.GetNomeControle()}/${id}/Detalhar`);
    }
}