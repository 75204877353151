





















































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import jiff from 'jiff';
import { Cabeco } from "@/core/models/operacional/Cabeco";
import { CabecoService } from "@/core/services/operacional/CabecoService";
import { ClienteService, PortoService } from "@/core/services/geral";
import { Cliente, Porto } from "@/core/models/geral";
import { AlertSimpleErr, AlertSimpleRes } from "@/core/services/shared/AlertService";

@Component
export default class CadastroCabeco extends Vue {
    @Prop() private item!: Cabeco;
    @Prop() private value!: string;

    itemOriginal!:Cabeco;

    loading: boolean = false;
    service = new CabecoService();
    valid = true;
    dialog = false;
    fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
    $refs!: {
        form: HTMLFormElement
    }

    operadorService = new ClienteService();
    operadores: Cliente [] = [];
    onSearchOperador:any = null;
    isOperadorLoading:boolean = false;

    operadorPorService = new ClienteService();
    operadoresPor: Cliente [] = [];
    onSearchOperadorPor:any = null;
    isOperadorPorLoading:boolean = false;

    portoService = new PortoService();
    portos : Porto [] = [];
    onSearchPorto:any = null;
    isPortoLoading:boolean = false;

    @Watch('item')
    Item(){
        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }
        // if(this.item.id > 0 && this.item.operadorId > 0){
        //     this.operadores = [];
        //     this.operadores.push(this.item.operador);
        // }
        // if (this.item.id > 0 && this.item.operadorPorId > 0){
        //     this.operadoresPor = []
        //     this.operadoresPor.push(this.item.operadorPor);
        // }
    }

    @Watch("item.latitude")
    @Watch("item.longitude")
    GerarLink(){
        this.item.link = `https://maps.google.com/?q=${this.item.latitude},${this.item.longitude}`;
    }

    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
        if (this.dialog){
            this.itemOriginal = jiff.clone(this.item);
        }
    }

    mounted() {
        this.portoService.ListarTudo().then(
            res=>{
                this.portos = res.data.items;
            },
            err=> AlertSimpleErr("Aviso!", err)
        )
    }

    @Watch("dialog")
    Dialog() {
        if (!this.dialog) {
            this.$emit("fechou");
        }
    }

    @Watch('onSearchOperador')
    SearchOperador (val: string) {
        if (this.isOperadorLoading) return;
        if (!val) return;
        this.isOperadorLoading = true
        this.operadorService.AutoComplete(val).then(
            res => {
                this.operadores = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => (this.isOperadorLoading = false));
    }

    @Watch('onSearchOperadorPor')
    SearchOperadorPor(val:string){
        if (this.isOperadorPorLoading) return;
        if (!val) return;
        this.isOperadorPorLoading = true;
        this.operadorPorService.AutoComplete(val).then(
            res => {
                this.operadoresPor = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally (() => (this.isOperadorPorLoading = false));
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    Salvar() {
        if (this.$refs.form.validate()) {
            this.loading = true;
            let pacthModel = jiff.diff(this.itemOriginal, this.item, false);
            (this.item.id > 0 ? this.service.Salvar(pacthModel, this.item.id) : this.service.Salvar(this.item)).then(
                res => {
                    AlertSimpleRes("Aviso!", res)
                    this.$emit("salvou");
                    this.Close();
                },
                err => AlertSimpleErr("Aviso!", err)
            ).finally(() => {
                this.loading = false;
            });
        }
    }
    Close() {
        this.dialog = false;
    }
}
