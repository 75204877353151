















































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import jiff from 'jiff';

import { EmpresaService, EnderecoService } from "@/core/services/geral";
import { Empresa } from "@/core/models/geral";
import { ArquivoService } from '@/core/services/shared';
import { AlertSimpleErr, AlertSimpleRes } from "@/core/services/shared/AlertService";

@Component
export default class CadastroEmpresa extends Vue {
  @Prop() private item!: Empresa;
  @Prop() private value!: string;

  itemOriginal!: Empresa;

  loading: boolean = false;
  service = new EmpresaService();
  enderecoService = new EnderecoService();
  estados: any[] = [];
  municipios: any[] = [];

  valid = true;
  dialog = false;
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

  mskCnpj = process.env.VUE_APP_MSK_CNPJ;
  mskTelefone = process.env.VUE_APP_MSK_TELEFONE;
  mskCep = process.env.VUE_APP_MSK_CEP;

  logo: any = null;

  $refs!: {
    formEmpresa: HTMLFormElement
  }
  onSearchCliente: any = null;
  isClienteLoading: boolean = false;
  foto: any = null;

  
  @Watch("item.endereco.estadoId")
  onEstadoIdChanged(value: number) {
    this.CarregarMunicipio(value);
  } 

  @Watch('item')
  Item() {
    if (this.$refs.formEmpresa) {
      this.$refs.formEmpresa.resetValidation();
    }
  }

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;

    if (this.dialog){
      this.itemOriginal = jiff.clone(this.item);
    }
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  beforeUpdate(){
    if (!this.dialog){
      this.$emit('fechou');
    }
  }

  async LoadImage(){
    if(!this.logo){
      return;
    }

    const arquivoService = new ArquivoService();
    const dados = await arquivoService.Ler(this.logo);
    this.item.logo = dados.replace(/^[^,]*,/, "");
  }

  RemoveImage(){
    this.item.logo = null;
    this.logo = null;
  }

  mounted() {
    this.enderecoService.ObterEstados().then(
      res => {
        this.estados = res.data.items;
      },
      err => AlertSimpleErr("Aviso!", err)
    );
  }

  CarregarMunicipio(estadoId: number){
    this.enderecoService.ObterMunicipios(estadoId).then(
      res => {
        this.municipios = res.data.items;
      },
      err => AlertSimpleErr("Aviso!", err)
    );
  }

  ObterEndereco(cep: string){
    this.enderecoService.ObterPorCep(cep).then(
      res => {
        const endereco = res.data;
        if (endereco) {
          this.item.endereco.logradouro = endereco.logradouro;
          this.item.endereco.bairro = endereco.bairro;
          this.item.endereco.estadoId = endereco.estadoId;
          this.item.endereco.municipioId = endereco.municipioId;
        }
      },
      err => AlertSimpleErr("Aviso!", err)
    );
  }

  Salvar() {
    if (this.$refs.formEmpresa.validate()) {
      this.loading = true;
      let pacthModel = jiff.diff(this.itemOriginal,this.item,false);
      (this.item.id > 0 ? this.service.Salvar(pacthModel, this.item.id) : this.service.Salvar(this.item)).then(
        res => {
          AlertSimpleRes("Aviso!", res);
          this.$emit("salvou");
          this.Close();
        },
        err => AlertSimpleErr("Aviso!", err)
      ).finally(() => {
        this.loading = false;
      });
    }
  }

  Close() {
    this.logo = null;
    this.dialog = false;
  }
}

