







































import { PageBase } from "@/core/models/shared";
import { UsuarioService } from "@/core/services/geral";
import { AlertSimpleErr, AlertSimpleRes } from "@/core/services/shared/AlertService";
import { Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class AlterarSenha extends PageBase{
    @Prop() private value!: string;

    validDialog: boolean = true;
    dialog: boolean = false;

    senhaAntiga: string = '';
    senhaNova: string = '';
    repetirSenha: string = '';

    showPassword1: boolean = false;
    showPassword2: boolean = false;
    showPassword3: boolean = false;

    fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

    $refs!:{
        form: HTMLFormElement
    }

    usuarioService = new UsuarioService();

    @Watch("value")
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (!this.dialog) {
        this.$emit("fechou");
        }
    }

    beforeUpdate() {
        if(!this.dialog){
            this.$emit("fechou");
        }
    }

    Salvar(){

        this.usuarioService.AlterarSenha(this.app.usuarioId, this.senhaAntiga, this.senhaNova)
            .then(
                res => {
                    this.sessionApp.dados.primeiroAcesso = false;
                    this.app.primeiroAcesso = false;
                    localStorage.setItem('sessionApp', JSON.stringify(this.sessionApp));
                    this.Close();
                    AlertSimpleRes("Aviso!", res);
                },
                err=> AlertSimpleErr("Aviso!", err)
            );
        
    };

    Close() {
        this.dialog = false;
    }
}
