var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","transition":"dialog-bottom-transition"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){return _vm.Close()}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v("Selecionar Equipamento/Serviço")]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"dark":"","text":"","disabled":_vm.localSelected.length == 0},on:{"click":function($event){return _vm.Selecionar()}}},[_vm._v("Selecionar")])],1)],1),_c('v-card-text',{staticClass:"px-0 py-4"},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.lista,"loading":_vm.loading,"single-select":false,"show-select":"","items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.data-table-select",fn:function(ref){
var isSelected = ref.isSelected;
var select = ref.select;
return [_c('v-checkbox',{staticClass:"mt-0",attrs:{"hide-details":"","value":isSelected},on:{"change":function($event){return select($event)},"input":function($event){return select($event)}}})]}},{key:"item.dataHoraInicio",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.dataHoraInicio? item.dataHoraInicio.toDateTimeDDMMYYYY() : '')+" ")]}},{key:"item.dataHoraTermino",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.dataHoraTermino? item.dataHoraTermino.toDateTimeDDMMYYYY() : '')+" ")]}}],null,true),model:{value:(_vm.localSelected),callback:function ($$v) {_vm.localSelected=$$v},expression:"localSelected"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }