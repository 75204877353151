import { TipoReceita } from ".";
import { Cliente, Empresa, TipoServicoEquipamento } from "../geral";
import { Equipamento, RegistroOperacional } from "../operacional";
import { Categoria } from "../operacional/Categoria";

export class Receita implements Shared.IEntity {
    id: number = 0;
    empresaId: number = 0;
    empresa!: Empresa;
    registroOperacionalId: number = 0;
    registroOperacional!: RegistroOperacional;
    observacao: string = "";
    clienteId: number = 0;
    cliente!: Cliente;
    tipoId: number = 0;
    tipo!: TipoReceita;
    categoriaId?: number;
    categoria!: Categoria;
    equipamentoServicoId?: number;
    equipamentoServico!: Equipamento;
    equipamento: string = "";
    dataEmissao?: string;
    dataPagamento: string = "";
    notaFiscal: string = "";
    fatLocacao: string = "";
    valorUnitario: number = 0;
    quantidade: number = 1;
    tipoImposto: string = "";
    imposto: number = 0;
    linkNotaFiscal: string = "";
    linkFatLocacao: string = "";

    constructor(model?: Receita) {
        if (!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;
        this.registroOperacionalId = model.registroOperacionalId;
        this.registroOperacional = model.registroOperacional;
        this.observacao = model.observacao;
        this.clienteId = model.clienteId;
        this.cliente = model.cliente;
        this.tipoId = model.tipoId;
        this.tipo = model.tipo;
        this.categoriaId = model.categoriaId;
        this.categoria = model.categoria;
        this.equipamento = model.equipamento;
        this.dataEmissao = model.dataEmissao ? model.dataEmissao.toDateYYYYMMDD() : '';
        this.dataPagamento = model.dataPagamento ? model.dataPagamento.toDateYYYYMMDD() : '';
        this.notaFiscal = model.notaFiscal;
        this.fatLocacao = model.fatLocacao;
        this.valorUnitario = model.valorUnitario;
        this.quantidade = model.quantidade;
        this.tipoImposto = model.tipoImposto;
        this.imposto = model.imposto;
        this.linkNotaFiscal = model.linkNotaFiscal;
        this.linkFatLocacao = model.linkFatLocacao;
        this.equipamentoServicoId = model.equipamentoServicoId,
            this.equipamentoServico = model.equipamentoServico
    }
}