













































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import jiff from 'jiff';
import { Despesa, TipoDespesa } from "@/core/models/financeiro";
import { DespesaService, TipoDespesaService } from "@/core/services/financeiro";
import { Equipamento, PedidoCompra, PedidoCompraEquipamento, RegistroOperacional } from "@/core/models/operacional";
import { EquipamentoService, PedidoCompraService, RegistroOperacionalService } from "@/core/services/operacional";
import { Fornecedor } from "@/core/models/geral";
import { FornecedorService, TipoServicoEquipamentoService } from "@/core/services/geral";
import { AlertSimpleErr, AlertSimpleRes } from "@/core/services/shared/AlertService";


@Component
export default class CadastroDespesa extends Vue {
  @Prop() private value!: string;
  @Prop() item!: Despesa;

  itemOriginal!: Despesa;

  loading: boolean = false;
  service = new DespesaService();
  valid = true;
  dialog = false;
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
  $refs!: {
    form: HTMLFormElement
  }
  
  tipoDespesas: TipoDespesa[] = [];
  tipoDespesaService: TipoDespesaService = new TipoDespesaService();

  registroOperacoes: RegistroOperacional[] = [];
  roService: RegistroOperacionalService = new RegistroOperacionalService();
  isROLoading: boolean = false;
  onSearchRO: any = null;
  
  fornecedorService = new FornecedorService()
  fornecedores: Fornecedor[] = [];
  isFornecedorLoading: boolean = false;
  onSearchFornecedor: any = null;

  equipamentoServicoService = new EquipamentoService();
  equipamentoServicos: Equipamento[] = [];
  isEquipamentoServicoLoading: boolean = false;
  onSearchEquipamentoServico: any = null;

  isPedidoCompraLoading: boolean = false;
  onSearchPedidoCompra: any = null;
  pedidoCompraService: PedidoCompraService = new PedidoCompraService();
  pedidoCompras: PedidoCompra[] = [];

  pedidoCompraEquipamento: PedidoCompraEquipamento[] = [];

  imposto: number = 0;
  valorUnitario: number = 0;
  quantidade: number = 0;
  total: number = 0;
  totalGeral: number = 0;

  @Watch('item')
  Item() {
    if (this.$refs.form) {
      this.$refs.form.resetValidation();
    }
    if(this.item.id > 0){
      if(this.item.registroOperacionalId){
        this.registroOperacoes = [];
        this.registroOperacoes.push(this.item.registroOperacional);
      }
      if(this.item.pedidoCompraId){
        this.pedidoCompras = [];
        this.pedidoCompras.push(this.item.pedidoCompra);
      }
      if(this.item.fornecedorId > 0){
        this.fornecedores = [];
        this.fornecedores.push(this.item.fornecedor);
      }
      if(this.item.equipamentoServicoId){
        this.equipamentoServicos = [];
        this.equipamentoServicos.push(this.item.equipamentoServico);
      }
      if(this.item.pedidoCompraEquipamentoId){
        this.pedidoCompraEquipamento = [];
        this.pedidoCompraEquipamento.push(this.item.pedidoCompraEquipamento);
      }
    }
  }

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;

    if (this.dialog){
      this.itemOriginal = jiff.clone(this.item);
    }
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  @Watch('item.valorUnitario')
  @Watch('item.imposto')
  @Watch('item.quantidade')
  Valores(){
    this.quantidade = this.item.quantidade;
    this.valorUnitario = this.item.valorUnitario;
    this.imposto = this.item.imposto;

    this.total = this.quantidade * this.valorUnitario;
    this.imposto = this.total * (this.imposto / 100);
    this.totalGeral = this.total + this.imposto;
  }

  @Watch('onSearchPedidoCompra')
  SearchPedidoCompra(val: string){
    if(this.isPedidoCompraLoading) return;
    if(this.item.pedidoCompraId) return;
    if (!val) return;
    this.isPedidoCompraLoading = true;
    this.pedidoCompraService.AutoComplete(val).then(
      res => {
        this.pedidoCompras = res.data;
      },
      err => AlertSimpleErr("Aviso!", err)
    ).finally(() => {
      this.isPedidoCompraLoading = false;
    })
  }

  @Watch('onSearchFornecedor')
  SearchFornecedor (val: string) {
    if (this.isFornecedorLoading) return;
    if(this.item.fornecedorId) return;
    if (!val) return;
    this.isFornecedorLoading = true
    this.fornecedorService.AutoComplete(val).then(
      res => {
        this.fornecedores = res.data;
      },
      err => AlertSimpleErr("Aviso!", err)
    )
    .finally(() => (this.isFornecedorLoading = false));
  }

  @Watch('onSearchEquipamentoServico')
  SearchEquipamentoServico(val:string){
    if(this.isEquipamentoServicoLoading)return;
    if(this.item.equipamentoServicoId)return;
    if(this.item.pedidoCompraId)return;
    if(!val) return;
    this.isEquipamentoServicoLoading = true;
    this.equipamentoServicoService.AutoComplete(val).then(
      res => {
        this.equipamentoServicos = res.data;
      },
      err => AlertSimpleErr("Aviso!", err)
    )
    .finally(() => (this.isEquipamentoServicoLoading = false));
  }  

  @Watch('onSearchRO')
  SearchRO (val: string) {
    if (this.isROLoading) return;
    if(this.item.registroOperacionalId) return;
    if (!val) return;
    this.isROLoading = true
    this.roService.AutoComplete(val).then(
      res => {
        this.registroOperacoes = res.data;
      },
      err => AlertSimpleErr("Aviso!", err)
    )
    .finally(() => (this.isROLoading = false));
  }

  @Watch('item.pedidoCompraId')
  WatchPedidoCompra(){
    if(this.item.id == 0 && this.item.pedidoCompraId! > 0){
      var po = this.pedidoCompras.find(x => x.id == this.item.pedidoCompraId);      
      if(po){
        this.item.pedidoCompra = po;
        po.equipamentos.forEach(equipamento => {
          this.pedidoCompraEquipamento.push(equipamento);
        });        
      }
    }
  }

  @Watch('item.pedidoCompraEquipamentoId')
  WatchEquipamento(){
    if(this.item.id == 0 && this.item.pedidoCompraEquipamentoId && this.item.pedidoCompraEquipamentoId > 0){
      var pedidoCompraEquipamento = this.item.pedidoCompra.equipamentos.find(x => x.id == this.item.pedidoCompraEquipamentoId)!;
      if(pedidoCompraEquipamento){
        this.item.pedidoCompraEquipamento = pedidoCompraEquipamento;
        this.item.equipamentoServicoId = pedidoCompraEquipamento.equipamento.equipamentoId;
        this.item.valorUnitario = pedidoCompraEquipamento.valorUnitario;
        this.item.quantidade = pedidoCompraEquipamento.quantidade;
        this.fornecedores.push(pedidoCompraEquipamento.equipamento.fornecedor);
        this.item.fornecedorId = pedidoCompraEquipamento.equipamento.fornecedorId;
      }      
    }
  }

  beforeUpdate(){
    if (!this.dialog){
      this.$emit('fechou');
    }
  }

  mounted() {
    this.tipoDespesaService.ListarTudo().then(
      res => {
        this.tipoDespesas = res.data.items;
      },
      err => AlertSimpleErr("Aviso!", err)
    )
  }

  Salvar() {
    if (this.$refs.form.validate()) {
      this.loading = true;
      let pacthModel = jiff.diff(this.itemOriginal, this.item, false);
      (this.item.id > 0 ? this.service.Salvar(pacthModel, this.item.id) : this.service.Salvar(this.item)).then(
        res => {
          AlertSimpleRes("Aviso!", res);
          this.$emit("salvou");
          this.Close();
        },
        err => AlertSimpleErr("Aviso!", err)
      ).finally(() => {
        this.loading = false;
      });
    }
  }

  Close() {
    this.dialog = false;
  }
}
