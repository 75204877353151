import { LocalOperacao } from "./LocalOperacao";

export class RegistroOperacionalAtracacao {

    dataHoraEntrada: string = '';
    dataHoraSaida: string = '';
    armazem: string = '';
    cabeco: string = '';
    ddsExecutado: boolean = false;
    ddsBordoExecutado: boolean = false;
    desconto: number = 0;
    descontoAmarracao: number = 0;
    localOperacaoId?: number;
    localOperacao!: LocalOperacao;


    constructor(model?: RegistroOperacionalAtracacao) {

        if (!model)
            return;

        this.dataHoraEntrada = model.dataHoraEntrada;
        this.dataHoraSaida = model.dataHoraSaida;
        this.armazem = model.armazem;
        this.cabeco = model.cabeco;
        this.ddsExecutado = model.ddsExecutado;
        this.ddsBordoExecutado = model.ddsBordoExecutado;
        this.desconto = model.desconto;
        this.descontoAmarracao = model.descontoAmarracao;
        this.localOperacaoId = model.localOperacaoId;
        this.localOperacao = model.localOperacao;
    }
}