import { Empresa } from ".";

export class TipoServicoEquipamento implements Shared.IEntity {
    id: number = 0;
    empresaId: number = 0;
    empresa!: Empresa;
    nome: string = '';

    constructor(model?: TipoServicoEquipamento) {
        if (!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;
        this.empresa = model.empresa;
        this.nome = model.nome;
    }
}