import { http } from '@/core/ApiConfig';
import { Service } from '../shared';

export class CotacaoService extends Service{

  constructor(){
    super('cotacao');
  }

  Enviar(id: number){
    return http.post(`${this.GetNomeControle()}/${id}/Enviar`);
  }

  Cancelar(id: number, justificativa: string){
    return http.post(`${this.GetNomeControle()}/${id}/Cancelar`, undefined, {
      params: {
        justificativa
      }
    });
  }

}