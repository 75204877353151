




































































































































































import { PageBase } from '@/core/models/shared';
import { EnumTipoUsuario } from '@/core/models/shared/Enumerados';
import { UsuarioService } from '@/core/services/geral';
import { AlertSimpleErr } from '@/core/services/shared/AlertService';
import { Component, Prop, Watch, Vue } from "vue-property-decorator";

@Component
export default class MasterPage extends PageBase {
    @Prop() private icone!: string;
    @Prop() private titulo!: string;
    @Prop() private subTitulo!: string;

    drawer: boolean = false;
    active: boolean = false;
    dialogAlterarSenha: boolean = false;
    tipoUsuario = EnumTipoUsuario;

    cadastroMenuButtons: any[] = [
        {name:'Cliente', icon:'mdi-account', path:'/cliente', restrito: this.Restrito([this.tipoUsuario.Administrador, this.tipoUsuario.Comercial])},
        {name:'Contato', icon:'mdi-account-arrow-left', path:'/contato', restrito: this.Restrito([this.tipoUsuario.Administrador, this.tipoUsuario.Comercial])},
        {name:'Funcionario', icon:'mdi-account-supervisor', path:'/funcionario', restrito: this.Restrito([this.tipoUsuario.Administrador, this.tipoUsuario.Financeiro, this.tipoUsuario.Comercial])},
        {name:'Fornecedor', icon:'mdi-truck', path:'/fornecedor', restrito: this.Restrito([this.tipoUsuario.Administrador, this.tipoUsuario.Comercial])},
        {name:'Porto', icon:'mdi-waves', path:'/porto', restrito: this.Restrito([this.tipoUsuario.Administrador, this.tipoUsuario.Comercial])},
        {name:'Tipo Embarcação', icon:'mdi-ferry', path:'/tipoEmbarcacao', restrito: this.Restrito([this.tipoUsuario.Administrador, this.tipoUsuario.Comercial])},
        {name:'Embarcação', icon:'mdi-ferry', path:'/embarcacao', restrito: this.Restrito([this.tipoUsuario.Administrador, this.tipoUsuario.Financeiro, this.tipoUsuario.Comercial])},
        {name:'Tipo Equipamento/Serviço', icon:'mdi-account-wrench',path:'/tipoServicoEquipamento',restrito:this.Restrito([this.tipoUsuario.Administrador, this.tipoUsuario.Comercial])},
        {name:'Equipamento/Serviço', icon:'mdi-hammer-wrench', path:'/equipamento', restrito: this.Restrito([this.tipoUsuario.Administrador, this.tipoUsuario.Financeiro, this.tipoUsuario.Comercial])},
        {name:'Faina', icon:'mdi-notebook-edit', path:'/faina', restrito: this.Restrito([this.tipoUsuario.Administrador, this.tipoUsuario.Comercial])},
        {name:'Função', icon:'mdi-account-star', path:'/funcao', restrito: this.Restrito([this.tipoUsuario.Administrador, this.tipoUsuario.Comercial])},
        {name:'Terno', icon:'mdi-account-group', path:'/terno', restrito: this.Restrito([this.tipoUsuario.Administrador, this.tipoUsuario.Comercial])},
        {name:'Unidades de Medida', icon:'mdi-ruler', path:'/unidadeMedida', restrito: this.Restrito([this.tipoUsuario.Administrador, this.tipoUsuario.Comercial])}
    ]

    operacionalMenuButtons: any[] = [
        {name:'Proposta', icon:'mdi-file-document-edit', path:'/proposta', restrito: this.Restrito([this.tipoUsuario.Administrador, this.tipoUsuario.Financeiro, this.tipoUsuario.Comercial])},
        {name:'Agendamento', icon:'mdi-file-sign', path:'/agendamento', restrito: this.Restrito([this.tipoUsuario.Administrador, this.tipoUsuario.Operacional, this.tipoUsuario.Cliente, this.tipoUsuario.Comercial])},
        {name:'Registro Operacional', icon:'mdi-file-edit-outline', path:'/registroOperacional', restrito: this.Restrito([this.tipoUsuario.Administrador, this.tipoUsuario.Operacional, this.tipoUsuario.Financeiro, this.tipoUsuario.Comercial])},
        {name:'Controle Ordem Serviço', icon:'mdi-file-edit-outline', path:'/controleOrdemServico', restrito: this.Restrito([this.tipoUsuario.Administrador, this.tipoUsuario.Operacional])},
        {name:'Relatório Medição', icon:'mdi-file-edit-outline', path:'/relatoriomedicao', restrito: this.Restrito([])},
        {name:'Pedido de Compra', icon:'mdi-cart-outline', path:'/pedidoCompra', restrito: this.Restrito([this.tipoUsuario.Administrador, this.tipoUsuario.Operacional, this.tipoUsuario.Financeiro, this.tipoUsuario.Comercial])},
        {name:'Agenda de Funcionário', icon:'mdi-account-details', path:'/configuracao/agendaFuncionario', restrito: this.Restrito([this.tipoUsuario.Administrador, this.tipoUsuario.Operacional])},
        // {name:'Cabeços',icon:'mdi-ruler',path:'/cabeco', restrito:this.Restrito([])},
    ]

    financeiroMenuButtons: any[] = [
        // {name:'Cotação',        icon:'mdi-cash',            path:'/cotacao',        restrito: this.Restrito([])},
        {name:'Tipo Despesa',   icon:'mdi-cash',            path:'/tipodespesa',    restrito: this.Restrito([this.tipoUsuario.Administrador, this.tipoUsuario.Comercial])},
        {name:'Despesa',        icon:'mdi-cash-register',   path:'/despesa',        restrito: this.Restrito([this.tipoUsuario.Administrador, this.tipoUsuario.Financeiro, this.tipoUsuario.Comercial])},
        {name:'Tipo Receita',   icon:'mdi-cash-multiple',   path:'/tiporeceita',    restrito: this.Restrito([this.tipoUsuario.Administrador, this.tipoUsuario.Comercial])},
        {name:'Receita',        icon:'mdi-cash-plus',       path:'/receita',        restrito: this.Restrito([this.tipoUsuario.Administrador, this.tipoUsuario.Financeiro, this.tipoUsuario.Comercial])},
    ]

    administradorMenuButtons: any[]= [
        {name:'Administrador', icon:'mdi-account-cog', path:'/configuracao',restrito:this.Restrito([this.tipoUsuario.Administrador])}
    ]

    opcoesMenuButtons: any[] = [
        {name:'Empresa', icon:'mdi-domain', path:'/empresa', restrito: this.Restrito([this.tipoUsuario.Administrador])},
        {name:'Usuário', icon:'mdi-account-lock', path:'/usuario', restrito: this.Restrito([this.tipoUsuario.Administrador])},
        {name:'Parâmetros', icon:'mdi-file-document-edit', path:'/parametros', restrito: this.Restrito([this.tipoUsuario.Administrador])},
    ]

    mounted() {
        this.$vuetify.theme.dark = this.app.temaEscuro;
    }

    GetAppVersion(){
      const pack = require('../../../package.json');
      return pack.version;
    }

    //CASO ESTEJA NA LISTA DE RESTRITO, APENAS AQUELES PODERAO ACESSAR
    Restrito(id: any[]){
        
        var sessionApp = JSON.parse(localStorage.sessionApp);
        const perfilId = sessionApp.dados.perfilId;

        if(id.length > 0 && !id.some(x => x == perfilId)){
            return true;
        }
        else{
            return false;
        }

    }

    Logout() {
        localStorage.clear();
        this.$vuetify.theme.dark = false;
        this.$router.push({name: 'login'});
    }

    MudarTema(){
        const usuarioService = new UsuarioService();
        usuarioService.MudarTema(this.app.usuarioId).then(
            res => super.MudarTema(res.data),
            err => AlertSimpleErr("Aviso!", err)
        );
    };

    AlterarSenha(){
        this.dialogAlterarSenha = true;
    }
}

