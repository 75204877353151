import { RegistroOperacionalEnvioAprovacao, RegistroOperacionalEquipamentoDescritivo, RegistroOperacionalFuncionario } from '@/core/models/operacional';
import { Porto } from './../geral/Porto';
import { SituacaoRegistroOperacional } from './SituacaoRegistroOperacional';
import { Embarcacao } from './Embarcacao';
import { Proposta, RegistroOperacionalAtracacao, RegistroOperacionalEquipamento, RegistroOperacionalFaina, RegistroOperacionalTerno } from ".";
import { Empresa } from "../geral";
import { Validacao } from '../shared';
import { RegistroOperacionalCabeco } from './RegistroOperacionalCabeco';
import { Agendamento } from './Agendamento';
export class RegistroOperacional implements Shared.IEntity {

    id: number = 0;
    empresaId: number = 0;
    empresa!: Empresa;
    numero: number = 0;
    propostaId: number = 0;
    proposta!: Proposta;
    situacaoId: number = 0;
    situacao!: SituacaoRegistroOperacional;
    portoId: number = 0;
    porto!: Porto;
    dataDocumento: string = new Date().toYYYYMMDD();
    dataSaida: string = "";
    linkDocumentos: string = '';
    linkDocumentosCliente: string = '';
    hasAtracacao: boolean = false;
    hasExtra: boolean = false;
    detalharImposto: boolean = false;
    embarcacaoId?: number;
    embarcacao!: Embarcacao;
    fainaOutro: string = '';
    observacao: string = '';
    atracacao: RegistroOperacionalAtracacao = new RegistroOperacionalAtracacao();
    validacao?: Validacao;
    numeroNotaFiscal: string = '';
    wo: string = "";
    agendamentoId?: number;
    agendamento!: Agendamento;
    desconto: number = 0;

    ternos: RegistroOperacionalTerno[] = [];
    fainas: RegistroOperacionalFaina[] = [];
    equipamentos: RegistroOperacionalEquipamento[] = [];
    // descritivos: RegistroOperacionalEquipamentoDescritivo[] = [];
    aprovacoes: RegistroOperacionalEnvioAprovacao[] = [];
    cabecos: RegistroOperacionalCabeco[] = [];
    funcionarios: RegistroOperacionalFuncionario[] = [];

    constructor(model?: RegistroOperacional) {

        if (!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;
        this.numero = model.numero;
        this.propostaId = model.propostaId;
        this.proposta = model.proposta;
        this.situacaoId = model.situacaoId;
        this.situacao = model.situacao;
        this.portoId = model.portoId;
        this.porto = model.porto;
        this.dataDocumento = model.dataDocumento ? model.dataDocumento.toDateYYYYMMDD() : '';
        this.dataSaida = model.dataSaida ? model.dataSaida.toDateYYYYMMDD() : '';
        this.linkDocumentos = model.linkDocumentos;
        this.linkDocumentosCliente = model.linkDocumentosCliente;
        this.hasAtracacao = model.hasAtracacao;
        this.hasExtra = model.hasExtra;
        this.detalharImposto = model.detalharImposto;
        this.embarcacaoId = model.embarcacaoId;
        this.embarcacao = model.embarcacao;
        this.fainaOutro = model.fainaOutro;
        this.observacao = model.observacao;
        this.atracacao = model.atracacao;
        this.validacao = model.validacao;
        this.numeroNotaFiscal = model.numeroNotaFiscal;
        this.wo = model.wo;
        this.agendamentoId = model.agendamentoId;
        this.agendamento = model.agendamento;
        this.desconto = model.desconto;

        this.ternos = model.ternos;
        this.fainas = model.fainas;
        this.equipamentos = model.equipamentos;
        // this.descritivos = model.descritivos;
        this.aprovacoes = model.aprovacoes;
        this.cabecos = model.cabecos;
        this.funcionarios = model.funcionarios;
    }
}