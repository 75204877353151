import { http, httpHeader } from "@/core/ApiConfig";
import { Service } from "../shared";

export class TipoServicoEquipamentoService extends Service {
    constructor() {
        super('tipoServicoEquipamento')
    }

    public AutoComplete(q: string) {
        return http.get(`${this.GetNomeControle()}/AutoComplete`, {
            params: {
                q: q
            },
            headers: httpHeader.headers
        });
    }
}