import { http, httpHeader } from '@/core/ApiConfig';
import { Service } from '../shared';

export class PortoService extends Service{

  constructor(){
    super('porto');
  }

  public AutoComplete(q: string, include?: string) {
    var header = httpHeader.headers

    if(include)
      header.include = include

    return http.get(`${this.GetNomeControle()}/AutoComplete`, {
      params: {
        q: q
      },
      headers: httpHeader.headers
    });
  }
  
}