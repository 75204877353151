import { Fornecedor } from './../geral/Fornecedor';
import { Equipamento } from './Equipamento';
import { PedidoCompraEquipamento } from './PedidoCompraEquipamento';
import { RegistroOperacional } from "./RegistroOperacional";

export class RegistroOperacionalEquipamento implements Shared.IEntity{

    id: number = 0;
    sequencia: number = 0;
    registroOperacionalId: number = 0;
    registroOperacional!: RegistroOperacional;
    equipamentoId: number = 0;
    equipamento!: Equipamento;
    fornecedorId: number = 0;
    fornecedor!: Fornecedor;
    dataHoraInicio: string = '';
    dataHoraTermino: string = '';
    gerarCotacao: boolean = false;
    gerarPedidoCompra: boolean = false;
    observacao: string = '';

    dataPagamento?: string;
    notaFiscal: string = '';
    fatLocacao: string = '';
    desconto: number = 0;
    valorUnitario: number = 0;
    quantidade: number = 1;
    tipoImposto: string = '';
    imposto: number = 0;

    pedidoCompras: PedidoCompraEquipamento[] = [];

    verificado: boolean = false;
    avulso: boolean = false;
    alterado: boolean = false;

    equipamentoFornecedor!: string;

    constructor(model?: RegistroOperacionalEquipamento){

        if(!model)
            return;

        this.id = model.id;
        this.sequencia = model.sequencia;
        this.registroOperacionalId = model.registroOperacionalId;
        this.equipamentoId = model.equipamentoId;
        this.equipamento = model.equipamento;
        this.fornecedorId = model.fornecedorId;
        this.fornecedor = model.fornecedor;
        this.dataHoraInicio = model.dataHoraInicio.toDateTimeDDMMYYYY();
        this.dataHoraTermino = model.dataHoraTermino ? model.dataHoraTermino.toDateTimeDDMMYYYY() : '';
        this.gerarPedidoCompra = model.gerarPedidoCompra;
        this.observacao = model.observacao;

        this.verificado = model.verificado;
        this.avulso = model.avulso;
        this.alterado = model.alterado;

        this.pedidoCompras = model.pedidoCompras;

        this.equipamentoFornecedor = model.equipamentoFornecedor;
    }
}