import { Cliente, Empresa, Porto } from "../geral";
import { AgendamentoDocumento } from "./AgendamentoDocumento";
import { AgendamentoEquipamento } from "./AgendamentoEquipamento";
import { Embarcacao } from "./Embarcacao";
import { SituacaoAgendamento } from "./SituacaoAgendamento";

export class Agendamento implements Shared.IEntity {
    
    id: number = 0;
    empresaId: number = 0;
    empresa!: Empresa;
    clienteId: number = 0;
    cliente!: Cliente;
    embarcacaoId?: number;
    embarcacao!: Embarcacao;
    roNumero?: number;
    atracacao: boolean = false;
    portoId: number = 0;
    porto!: Porto;
    dataHoraEntrada: string = '';
    praticoEntrada?: string;
    praticoSaida?: string;
    calado: number = 0;
    linkDocumentos: string = '';
    observacao: string = '';
    equipamentos: AgendamentoEquipamento[] = [];
    documentos: AgendamentoDocumento[] = [];
    criacao: Criacao = new Criacao();
    cancelamento: Cancelamento = new Cancelamento();
    situacaoId: number = 1;
    situacao!: SituacaoAgendamento;

    constructor(model?: Agendamento) {
        
        if (!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;
        this.empresa = model.empresa;
        this.clienteId = model.clienteId;
        this.cliente = model.cliente;
        this.roNumero = model.roNumero;
        this.atracacao = model.atracacao;
        this.portoId = model.portoId;
        this.porto = model.porto;
        this.dataHoraEntrada = model.dataHoraEntrada;
        this.praticoEntrada = model.praticoEntrada;
        this.praticoSaida = model.praticoSaida;
        this.calado = model.calado;
        this.linkDocumentos = this.linkDocumentos;
        this.observacao = model.observacao;
        this.equipamentos = model.equipamentos;
        this.documentos = model.documentos;
    }
}

class Criacao {

    usuarioId?: number;
    usuarioNome: string = "";
    dataHora: string = "";

    constructor(model?: Criacao){

        if(!model)
            return;

        this.usuarioId = model.usuarioId;
        this.usuarioNome = model.usuarioNome
        this.dataHora = model.dataHora;
    }
}

class Cancelamento {

    justificativa: string = "";
    usuarioId?: number;
    dataHora: string = "";

    constructor(model?: Cancelamento){

        if(!model)
            return;

        this.justificativa = model.justificativa;
        this.usuarioId = model.usuarioId;
        this.dataHora = model.dataHora;
    }
}