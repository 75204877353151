import { RegistroOperacionalEnvioAprovacaoItem } from '.';
import { Usuario } from './../geral/Usuario';
import { RegistroOperacional } from './RegistroOperacional';

export class RegistroOperacionalEnvioAprovacao implements Shared.IEntity{

    id: number = 0;
    registroOperacionalId: number = 0;
    registroOperacional!: RegistroOperacional;
    usuarioId: number = 0;
    usuario!: Usuario;
    assunto: string = "";
    texto: string = "";
    itens: RegistroOperacionalEnvioAprovacaoItem[] = []

    constructor(model?: RegistroOperacionalEnvioAprovacao){

        if(!model)
            return;

        this.id = model.id;
        this.registroOperacionalId = model.registroOperacionalId;
        this.usuarioId = model.usuarioId;
        this.assunto = model.assunto;
        this.texto = model.texto;
        this.itens = model.itens;
    }

}