








































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import jiff from 'jiff';
import { Equipamento } from "@/core/models/operacional";
import { EquipamentoService } from "@/core/services/operacional"
import { Categoria } from "@/core/models/operacional/Categoria";
import { TipoServicoEquipamentoService } from "@/core/services/geral";
import { CategoriaService } from "@/core/services/operacional/CategoriaService";
import { AlertSimpleErr, AlertSimpleRes } from "@/core/services/shared/AlertService";

@Component
export default class CadastroEquipamento extends Vue {
  @Prop() private item!: Equipamento;
  @Prop() private value!: string;

  itemOriginal!: Equipamento;

  loading: boolean = false;
  service = new EquipamentoService();
  valid = true;
  dialog = false;
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
  $refs!: {
    form: HTMLFormElement
  }
  categorias: Categoria[] = [];
  categoriaService = new CategoriaService();

  tiposServicoEquipamentos : any [] = [];
  tipoServicoEquipamentoService = new TipoServicoEquipamentoService();
  isTipoServicoEquipamento: boolean = false;
  onSearchTipoServicoEquipamento: any = null;

  @Watch('item')
  Item() {
    if (this.$refs.form) {
      this.$refs.form.resetValidation();
    }
  }

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;

    if (this.dialog){
      this.itemOriginal = jiff.clone(this.item);
    }
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  beforeUpdate(){
    if (!this.dialog){
      this.$emit('fechou');
    }
  }

  mounted() {
    this.categoriaService.ListarTudo().then(
      res=>{
        this.categorias = res.data.items;
      },
      err => AlertSimpleErr("Aviso!", err)
    )

    this.tipoServicoEquipamentoService.ListarTudo().then(
      res => {
        this.tiposServicoEquipamentos = res.data.items;
      },
      err => AlertSimpleErr("Aviso!", err)
    )
  }

  Salvar() {
    if (this.$refs.form.validate()) {
      this.loading = true;
      let pacthModel = jiff.diff(this.itemOriginal, this.item, false);
      (this.item.id > 0 ? this.service.Salvar(pacthModel, this.item.id) : this.service.Salvar(this.item)).then(
        res => {
          AlertSimpleRes("Aviso!", res);
          this.$emit("salvou");
          this.Close();
        },
        err => AlertSimpleErr("Aviso!", err)
      ).finally(() => {
        this.loading = false;
      });
    }
  }



  Close() {
    this.dialog = false;
  }
}
