






























import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import jiff from 'jiff';
import { TipoDespesa } from "@/core/models/financeiro";
import { TipoDespesaService } from "@/core/services/financeiro";
import { AlertSimpleErr, AlertSimpleRes } from "@/core/services/shared/AlertService";


@Component
export default class CadastroTipoDespesa extends Vue {
  @Prop() private item!: TipoDespesa;
  @Prop() private value!: string;

  itemOriginal!: TipoDespesa;

  service = new TipoDespesaService();
  valid = true;
  dialog = false;
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
  $refs!: {
    form: HTMLFormElement
  }

  @Watch('item')
  Item() {
    if (this.$refs.form) {
      this.$refs.form.resetValidation();
    }
  }

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;

    if (this.dialog){
      this.itemOriginal = jiff.clone(this.item);
    }
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  beforeUpdate(){
    if (!this.dialog){
      this.$emit('fechou');
    }
  }

  mounted() {
    
  }

  Salvar() {
    if (this.$refs.form.validate()) {
      let pacthModel = jiff.diff(this.itemOriginal, this.item, false);
      (this.item.id > 0 ? this.service.Salvar(pacthModel, this.item.id) : this.service.Salvar(this.item)).then(
        res => {
          AlertSimpleRes("Aviso!", res);
          this.$emit("salvou");
          this.Close();
        },
        err => AlertSimpleErr("Aviso!", err)
      );
    }
  }

  Close() {
    this.dialog = false;
  }
}
