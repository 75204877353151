import { Equipamento } from './Equipamento';
import { RegistroOperacional } from "./RegistroOperacional";

export class RegistroOperacionalEquipamentoDescritivo implements Shared.IEntity{

    id: number = 0;
    registroOperacionalId: number = 0;
    registroOperacional!: RegistroOperacional;
    equipamentoId: number = 0;
    equipamento!: Equipamento;
    quantidade: number = 1;
    dataHoraInicio?: string;
    dataHoraTermino?: string;
    observacao: string = '';
    valorUnitario: number = 0;

    constructor(model?: RegistroOperacionalEquipamentoDescritivo){

        if(!model)
            return;

        this.id = model.id;
        this.registroOperacionalId = model.registroOperacionalId;
        this.equipamentoId = model.equipamentoId;
        this.equipamento = model.equipamento;
        this.quantidade = model.quantidade;
        this.dataHoraInicio = model.dataHoraInicio ? model.dataHoraInicio.toDateTimeDDMMYYYY() : '';
        this.dataHoraTermino = model.dataHoraTermino ? model.dataHoraTermino.toDateTimeDDMMYYYY() : '';
        this.observacao = model.observacao;
        this.valorUnitario = model.valorUnitario;
    }
}