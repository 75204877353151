import { Proposta } from ".";

export class PropostaTerno implements Shared.IEntity {

    id: number = 0;
    propostaId: number = 0;
    proposta!: Proposta;
    horas: number = 6;
    valor: number = 0;
    porcentagemNoturna: number = 0;
    porcentagemFimSemanaFeriado: number = 0;

    constructor(model?: PropostaTerno){

        if(!model)
            return;

        this.id = model.id;
        this.propostaId = model.propostaId;
        this.proposta = model.proposta;
        this.horas = model.horas;
        this.valor = model.valor;
        this.porcentagemNoturna = model.porcentagemNoturna;
        this.porcentagemFimSemanaFeriado = model.porcentagemFimSemanaFeriado;
    }
}