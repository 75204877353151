import { Documento } from '../geral';
import { Equipamento } from './Equipamento';

export class RegistroOperacionalEquipamentoDocumento implements Shared.IEntity{

    id: number = 0;
    equipamentoId: number = 0;
    equipamento!: Equipamento;
    documentoId: number = 0;
    documento: Documento = new Documento();
    descricao: string = "";
    excluir: boolean = false;

    constructor(model?: RegistroOperacionalEquipamentoDocumento){

        if(!model)
            return;

        this.id = model.id;
        this.equipamentoId = model.equipamentoId;
        this.equipamento = model.equipamento;
        this.documentoId = model.documentoId;
        this.documento = model.documento;
        this.descricao = model.descricao;
    }
}