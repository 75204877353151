import { Empresa, Fornecedor, Funcionario } from "../geral";
import { Equipamento } from "./Equipamento";
import { PedidoCompraEquipamento } from "./PedidoCompraEquipamento";
import { Proposta } from "./Proposta";
import { RegistroOperacional } from "./RegistroOperacional";
import { RegistroOperacionalEquipamento } from "./RegistroOperacionalEquipamento";

export class PedidoCompra implements Shared.IEntity{

    id: number = 0;
    empresaId: number = 0;
    empresa!: Empresa;
    numero: number = 0;
    fornecedorId: number = 0;
    fornecedor!: Fornecedor;
    equipamentoId: number = 0;
    equipamento!: Equipamento;
    responsavelId: number = 0;
    responsavel!: Funcionario;
    dataHoraCriacao!: string;
    
    equipamentos: PedidoCompraEquipamento[] = [];
    
    //DELETAR APOS MIGRACAO
    registroOperacionalEquipamentoId?: number;
    registroOperacionalEquipamento!: RegistroOperacionalEquipamento;
    dataHoraSolicitacao?: string;
    dataHoraLiberacao?: string;
    dataPagamento?: string;
    quantidade?: number;
    valorUnitario?: number;
    tempoMinimo?: number;
    porcentagemExcedente?: number;

    constructor(model?: PedidoCompra){

        if(!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;
        this.numero = model.numero;
        this.fornecedorId = model.fornecedorId;
        this.fornecedor = model.fornecedor;
        this.equipamentoId = model.equipamentoId;
        this.equipamento = model.equipamento;
        this.responsavelId = model.responsavelId;
        this.responsavel = model.responsavel;
        this.dataHoraCriacao = model.dataHoraCriacao;
        
        this.equipamentos = model.equipamentos;
        
        this.registroOperacionalEquipamentoId = model.registroOperacionalEquipamentoId;
        this.registroOperacionalEquipamento = model.registroOperacionalEquipamento;        
        this.dataHoraSolicitacao = model.dataHoraSolicitacao;
        this.dataHoraLiberacao = model.dataHoraLiberacao;
        this.dataPagamento = model.dataPagamento ? model.dataPagamento.toDateYYYYMMDD() : '';
        this.quantidade = model.quantidade;
        this.valorUnitario = model.valorUnitario;
        this.tempoMinimo = model.tempoMinimo;
        this.porcentagemExcedente = model.porcentagemExcedente;
    }
}