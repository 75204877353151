




































import { FornecedorEquipamentoServico, Porto } from "@/core/models/geral";
import { Equipamento, PropostaEquipamentoPeriodo } from "@/core/models/operacional";
import { LocalOperacao } from "@/core/models/operacional/LocalOperacao";
import { PortoService } from "@/core/services/geral";
import { EquipamentoService, PropostaEquipamentoPeriodoService } from "@/core/services/operacional";
import { AlertSimpleErr } from "@/core/services/shared/AlertService";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class CadastroFornecedorEquipamentoServico extends Vue {
    @Prop() private item!: FornecedorEquipamentoServico;
    @Prop() private value!: string;

    dialog: boolean = false;
    valid: boolean = false;
    fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
    $refs!: {
        form: HTMLFormElement
    }
    
    portoId: number = 0;
    portos: Porto[] = [];    
    portoService: PortoService = new PortoService();

    locaisOperacao: LocalOperacao[] = [];

    equipamentoServicos: Equipamento[] = [];
    equipamentoServicoService: EquipamentoService = new EquipamentoService();

    periodos: PropostaEquipamentoPeriodo[] = [];
    periodoService: PropostaEquipamentoPeriodoService = new PropostaEquipamentoPeriodoService();

    @Watch('item')
    Item(){
        if(this.$refs.form){
            this.$refs.form.resetValidation();
        }
    }

    @Watch("value")
    Value(){
        this.dialog = this.value ? true : false;

        if(this.dialog){
            this.Carregar();
        }
    }

    @Watch("dialog")
    Dialog() {
        if (!this.dialog) {
            this.$emit("fechou");
        }
    }

    beforeUpdate() {
        if(!this.dialog){
            this.$emit("fechou");
        }
    }

    @Watch("portoId")
    WatchPorto(){
        if(this.portoId > 0){
            this.portoService.ObterPorId(this.portoId, "LocaisOperacao").then(
                res => {
                    let porto = res.data;
                    this.locaisOperacao = porto.locaisOperacao.filter(x => x.Ativo);
                },
                err => AlertSimpleErr("Aviso!", err)
            )   
        }
    }

    Carregar(){
        this.periodoService.ListarTudo(undefined, 'nome').then(
            res => this.periodos = res.data.items,
            err => AlertSimpleErr("Aviso!", err)
        )
        this.equipamentoServicoService.ListarTudo().then(
            res => this.equipamentoServicos = res.data.items,
            err => AlertSimpleErr("Aviso!", err)
        )
        this.portoService.ListarTudo().then(
            res => this.portos = res.data.items,
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    Salvar(){
        if (this.$refs.form.validate()) {
            this.item.equipamentoServico = this.equipamentoServicos.find(x => x.id == this.item.equipamentoServicoId)!;
            this.item.localOperacao = this.locaisOperacao.find(x => x.id == this.item.localOperacaoId)!;
            this.item.periodo = this.periodos.find(x => x.id == this.item.periodoId)!;
            this.Salvou();
        }
    }

    Salvou(){
        this.$emit("salvou");
        this.Close();
    }

    Close(){
        this.portoId = 0;
        this.dialog = false;
    }
}
