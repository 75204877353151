










































































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import jiff from 'jiff';
import { Porto } from "@/core/models/geral";
import { PortoService } from "@/core/services/geral";
import { PortoTerno } from "@/core/models/operacional";
import { LocalOperacao } from "@/core/models/operacional/LocalOperacao";
import { PageBase } from "@/core/models/shared";
import { EnumTipoUsuario } from "@/core/models/shared/Enumerados";
import { AlertExcludeQuestion, AlertSimpleErr, AlertSimpleRes } from "@/core/services/shared/AlertService";


@Component
export default class CadastroPorto extends PageBase {
  @Prop() private item!: Porto;
  @Prop() private value!: string;

  itemOriginal!: Porto;
  service = new PortoService();

  tipoUsuario = EnumTipoUsuario;
  
  loading: boolean = false;
  valid = true;
  dialog = false;
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
  $refs!: {
    form: HTMLFormElement
  }
  tabActive: any = {};

  headerTerno: any[] = [
    { text: '',value:'actions' ,sortable: false },
    { text: 'Data/Hora Inicio', value: 'terno.horaInicial' },
    { text: 'Data/Hora Término', value: 'terno.horaFinal' }
  ];
  portoTerno: PortoTerno = new PortoTerno();
  dialogTerno: boolean = false;
  editaTerno: boolean = false;

  headerLocalOperacao: any[] = [
    { text: '',value:'actions' ,sortable: false },
    { text: 'Nome', value: 'nome' },
    { text: 'Calcular DWT', value: 'calcularDWT' },
    { text: 'Calcular Período Operante/Inoperante', value: 'calcularPeriodoOperanteInoperante' }
  ];
  localOperacao: LocalOperacao = new LocalOperacao();
  dialogLocalOperacao: boolean = false;
  editaLocalOperacao: boolean = false;

  @Watch('item')
  Item() {
    if (this.$refs.form) {
      this.$refs.form.resetValidation();
    }
  }

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;

    if (this.dialog){
      this.itemOriginal = jiff.clone(this.item);
    }
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  beforeUpdate(){
    if (!this.dialog){
      this.$emit('fechou');
    }
  }

  mounted() {
    this.app.perfilId == this.tipoUsuario.Administrador ? this.headerLocalOperacao.push({text: "Ativo", value: 'ativo'}) : '';
  }

  AbrirDialogTerno(portoTerno?: PortoTerno){

    if(portoTerno){
      this.portoTerno = new PortoTerno();
      this.portoTerno = portoTerno;
      this.editaTerno = true;
    }
    else{
      this.portoTerno = new PortoTerno();
      this.portoTerno.portoId = this.item.id;
      this.editaTerno = false;
    }
    this.dialogTerno = true;

  }

  SalvarTerno(){
    if(this.editaTerno == false){
      this.item.ternos.push(this.portoTerno);
    }
    this.dialogTerno = false;
  }

  ExcluirTerno(portoTerno: PortoTerno){
    const context = this;
    const excluir = function () {
      return new Promise( async function (resolve, reject){
        const index = context.item.ternos.indexOf(portoTerno);
        context.item.ternos.splice(index,1);
      });
    }
    AlertExcludeQuestion(excluir, true);
  }

  AbrirDialogLocalOperacao(localOperacao?: LocalOperacao){

    if(localOperacao){
      this.localOperacao = localOperacao;
      this.editaLocalOperacao = true;
    }
    else{
      this.localOperacao = new LocalOperacao();
      this.localOperacao.portoId = this.item.id;
      this.editaLocalOperacao = false;
    }
    this.dialogLocalOperacao = true;

  }

  SalvarLocalOperacao(){
    if(this.editaLocalOperacao == false){
      this.item.locaisOperacao.push(this.localOperacao);
    }
    this.dialogLocalOperacao = false;
  }

  ExcluirLocalOperacao(localOperacao: LocalOperacao){
    const context = this;
    const excluir = function () {
      return new Promise( async function (resolve, reject){
        const index = context.item.locaisOperacao.indexOf(localOperacao);
        context.item.locaisOperacao.splice(index,1);
      });
    }
    AlertExcludeQuestion(excluir, true);
  }

  Salvar() {
    if (this.$refs.form.validate()) {
      this.loading = true;
      let pacthModel = jiff.diff(this.itemOriginal, this.item, false);
      (this.item.id > 0 ? this.service.Salvar(pacthModel, this.item.id) : this.service.Salvar(this.item)).then(
        res => {
          AlertSimpleRes("Aviso!", res);
          this.$emit("salvou");
          this.Close();
        },
        err => AlertSimpleErr("Aviso!", err)
      ).finally(() => {
        this.loading = false;
      });
    }
  }

  Close() {
    this.dialog = false;
  }
}
