import { Empresa } from "../geral";

export class TipoEmbarcacao implements Shared.IEntity{

    id: number = 0;
    empresaId: number = 0;
    empresa!: Empresa;
    nome: string = '';

    constructor(model?: TipoEmbarcacao){

        if(!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;
        this.nome = model.nome;
    }
}