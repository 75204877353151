import { Faina } from './Faina';
import { UnidadeMedida } from "../geral";
import { RegistroOperacional } from "./RegistroOperacional";
import { RegistroOperacionalFainaDocumento } from './RegistroOperacionalFainaDocumento';

export class RegistroOperacionalFaina implements Shared.IEntity{

    id: number = 0;
    registroOperacionalId: number = 0;
    registroOperacional!: RegistroOperacional;
    fainaId: number = 0;
    faina!: Faina;
    quantidade: number = 0;
    volume: number = 0;
    unidadeMedidaId: number = 0;
    unidadeMedida!: UnidadeMedida;
    dataHoraInicio: string = '';
    dataHoraTermino: string = '';
    desconto: number = 0;
    observacao: string = "";
    link: string = "";    

    constructor(model?: RegistroOperacionalFaina){

        if(!model)
            return;

        this.id = model.id;
        this.registroOperacionalId = model.registroOperacionalId;
        this.fainaId = model.fainaId;
        this.quantidade = model.quantidade;
        this.volume = model.volume;
        this.unidadeMedidaId = model.unidadeMedidaId;
        this.dataHoraInicio = model.dataHoraInicio;
        this.dataHoraTermino = model.dataHoraTermino;
        this.desconto = model.desconto;
        this.observacao = model.observacao;
        this.link = model.link;        
    }
}