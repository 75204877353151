import { RegistroOperacional } from "./RegistroOperacional";
import { Terno } from "./Terno";

export class RegistroOperacionalTerno implements Shared.IEntity{

    id: number = 0;
    registroOperacionalId: number = 0;
    registroOperacional!: RegistroOperacional;
    ternoId: number = 0;
    terno!: Terno;
    data: string = '';
    desconto: number = 0;
    fimSemanaFeriado: boolean = false;

    constructor(model?: RegistroOperacionalTerno){

        if(!model)
            return;

        this.id = model.id;
        this.registroOperacionalId = model.registroOperacionalId;
        this.ternoId = model.ternoId;
        this.data = model.data.toDateYYYYMMDD();
        this.desconto = model.desconto;
        this.fimSemanaFeriado = model.fimSemanaFeriado;
    }
}