import { PedidoCompra } from "./PedidoCompra";
import { PropostaEquipamentoPeriodo } from "./PropostaEquipamentoPeriodo";
import { RegistroOperacionalEquipamento } from "./RegistroOperacionalEquipamento";

export class PedidoCompraEquipamento implements Shared.IEntity{

    id: number = 0;
    pedidoCompraId: number = 0;
    pedidoCompra!: PedidoCompra;
    equipamentoId: number = 0;
    equipamento!: RegistroOperacionalEquipamento;    
    dataHoraSolicitacao: string = '';
    dataHoraLiberacao?: string;
    dataPagamento?: string;
    quantidade: number = 1;
    valorProposta: number = 0;
    valorUnitario: number = 0;
    porcentagemExcedente: number = 0;
    tempoMinimo: number = 0;
    periodoId: number = 0;
    periodo!: PropostaEquipamentoPeriodo;

    constructor(model?: PedidoCompraEquipamento){

        if(!model)
            return;

        this.id = model.id;
        this.pedidoCompraId = model.pedidoCompraId;
        this.pedidoCompra = model.pedidoCompra;
        this.equipamentoId = model.equipamentoId;
        this.equipamento = model.equipamento;
        this.dataHoraSolicitacao = model.dataHoraSolicitacao;
        this.dataHoraLiberacao = model.dataHoraLiberacao;
        this.dataPagamento = model.dataPagamento;
        this.quantidade = model.quantidade;
        this.valorProposta = model.valorProposta;
        this.valorUnitario = model.valorUnitario;
        this.porcentagemExcedente = model.porcentagemExcedente;
        this.tempoMinimo = model.tempoMinimo;
        this.periodoId = model.periodoId;
        this.periodo = model.periodo;
    }
}