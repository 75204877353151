import Vue from 'vue'

import MasterPage from "./shared/MasterPage.vue"
import RelatorioPage from "./shared/RelatorioPage.vue"
import AlterarSenha from "./shared/AlterarSenha.vue"

import CadastroDocumento from "./shared/Documento/Cadastro.vue"

import CadastroCliente from "./geral/cliente/Cadastro.vue"
import CadastroContato from "./geral/contato/Cadastro.vue"
import CadastroTipoEmbarcacao from "./geral/tipoEmbarcacao/Cadastro.vue"
import CadastroEmbarcacao from "./geral/embarcacao/Cadastro.vue"
import CadastroEquipamento from "./geral/equipamento/Cadastro.vue"
import CadastroFaina from "./geral/faina/Cadastro.vue"

import CadastroFornecedor from "./geral/fornecedor/Cadastro.vue"
import CadastroFornecedorEquipamentoServico from "./geral/fornecedor/CadastroEquipamentoServico.vue"

import CadastroFuncao from "./geral/funcao/Cadastro.vue"

import CadastroFuncionario from "./geral/funcionario/Cadastro.vue"

import CadastroPorto from "./geral/porto/Cadastro.vue"
import CadastroPortoTerno from "./geral/porto/CadastroPortoTerno.vue"
import CadastroPortoLocalOperacao from "./geral/porto/CadastroPortoLocalOperacao.vue"

import CadastroTerno from "./geral/terno/Cadastro.vue"
import CadastroUnidadeMedida from "./geral/unidadeMedida/Cadastro.vue"
import CadastroTipoServicoEquipamento from './geral/tipoServicoEquipamento/Cadastro.vue'

import CadastroControleOrdemServico from "./operacional/controleOrdemServico/Cadastro.vue"

import CadastroCotacao from "./financeiro/cotacao/Cadastro.vue"

import CadastroDespesa from "./financeiro/despesa/Cadastro.vue"
import CadastroTipoDespesa from "./financeiro/tipodespesa/Cadastro.vue"

import CadastroReceita from "./financeiro/receita/Cadastro.vue"
import CadastroTipoReceita from './financeiro/tiporeceita/Cadastro.vue'

import CadastroProposta from "./operacional/proposta/Cadastro.vue"
import CadastroPropostaEquipamento from "./operacional/proposta/CadastroPropostaEquipamento.vue"

import CadastroAgendamento from "./operacional/agendamento/Cadastro.vue"
import CadastroAgendamentoEquipamentoServico from "./operacional/agendamento/CadastroAgendamentoEquipamentoServico.vue"

import CadastroRelatorioMedicao from "./operacional/relatorioMedicao/Cadastro.vue"

import CadastroRegistroOperacional from "./operacional/registroOperacional/Cadastro.vue"
import CadastroRegistroOperacionalCabeco from "./operacional/registroOperacional/CadastroRegistroOperacionalCabeco.vue"
import CadastroRegistroOperacionalTerno from "./operacional/registroOperacional/CadastroRegistroOperacionalTerno.vue"
import CadastroRegistroOperacionalFaina from "./operacional/registroOperacional/CadastroRegistroOperacionalFaina.vue"
import CadastroRegistroOperacionalEquipamento from "./operacional/registroOperacional/CadastroRegistroOperacionalEquipamento.vue"
import CadastroRegistroOperacionalEquipamentoDescritivo from "./operacional/registroOperacional/CadastroRegistroOperacionalEquipamentoDescritivo.vue"
import CadastroRegistroOperacionalFuncionario from "./operacional/registroOperacional/CadastroRegistroOperacionalFuncionario.vue"
import SelecionaRegistroOperacional from "./operacional/registroOperacional/SelecionarEquipamentoServico.vue"

import CadastroPedidoCompra from "./operacional/pedidoCompra/Cadastro.vue"
import CadastroPedidoCompraEquipamento from "./operacional/pedidoCompra/CadastroEquipamento.vue"

import CadastroEmpresa from "./geral/empresa/Cadastro.vue"
import CadastroUsuario from "./geral/usuario/Cadastro.vue"
import CadastroCabeco from './operacional/cabeco/Cadastro.vue'

import CadastroSmtpEmpresa from "./configuracao/smtpEmpresa/Cadastro.vue"

Vue.component("master-page", MasterPage);
Vue.component("relatorio-page", RelatorioPage);
Vue.component("alterar-senha", AlterarSenha);

Vue.component("cadastro-documento", CadastroDocumento);

Vue.component("cadastro-cliente", CadastroCliente);
Vue.component("cadastro-contato", CadastroContato);
Vue.component("cadastro-tipoEmbarcacao", CadastroTipoEmbarcacao);
Vue.component("cadastro-embarcacao", CadastroEmbarcacao);
Vue.component("cadastro-equipamento", CadastroEquipamento);
Vue.component("cadastro-faina", CadastroFaina);

Vue.component("cadastro-fornecedor", CadastroFornecedor);
Vue.component("cadastro-fornecedor-equipamentoservico", CadastroFornecedorEquipamentoServico);

Vue.component("cadastro-funcao", CadastroFuncao);

Vue.component("cadastro-funcionario", CadastroFuncionario);

Vue.component("cadastro-porto", CadastroPorto);
Vue.component("cadastro-porto-terno", CadastroPortoTerno);
Vue.component("cadastro-porto-localOperacao", CadastroPortoLocalOperacao);

Vue.component("cadastro-terno", CadastroTerno);
Vue.component("cadastro-unidadeMedida", CadastroUnidadeMedida);
Vue.component("cadastro-tipoServicoEquipamento", CadastroTipoServicoEquipamento);

Vue.component("cadastro-controleOrdemServico", CadastroControleOrdemServico);

Vue.component("cadastro-cotacao", CadastroCotacao);

Vue.component("cadastro-despesa", CadastroDespesa);
Vue.component("cadastro-tipodespesa", CadastroTipoDespesa);
Vue.component("cadastro-receita", CadastroReceita);
Vue.component("cadastro-tiporeceita", CadastroTipoReceita);

Vue.component("cadastro-proposta", CadastroProposta);
Vue.component("cadastro-proposta-equipamento", CadastroPropostaEquipamento);

Vue.component("cadastro-agendamento", CadastroAgendamento);
Vue.component("cadastro-agendamento-equipamentoservico", CadastroAgendamentoEquipamentoServico);

Vue.component("cadastro-relatorioMedicao", CadastroRelatorioMedicao);

Vue.component("cadastro-registroOperacional", CadastroRegistroOperacional);
Vue.component("cadastro-registroOperacional-cabeco", CadastroRegistroOperacionalCabeco);
Vue.component("cadastro-registroOperacional-terno", CadastroRegistroOperacionalTerno);
Vue.component("cadastro-registroOperacional-faina", CadastroRegistroOperacionalFaina);
Vue.component("cadastro-registroOperacional-equipamento", CadastroRegistroOperacionalEquipamento);
Vue.component("cadastro-registroOperacional-equipamento-descritivo", CadastroRegistroOperacionalEquipamentoDescritivo);
Vue.component("cadastro-registroOperacional-funcionario", CadastroRegistroOperacionalFuncionario);
Vue.component("seleciona-registroOperacional-equipamento", SelecionaRegistroOperacional);

Vue.component("cadastro-pedidoCompra", CadastroPedidoCompra);
Vue.component("cadastro-pedidoCompra-equipamento", CadastroPedidoCompraEquipamento);

Vue.component("cadastro-cabeco", CadastroCabeco);

Vue.component("cadastro-empresa", CadastroEmpresa);
Vue.component("cadastro-usuario", CadastroUsuario);

Vue.component("cadastro-smtpEmpresa", CadastroSmtpEmpresa);