import { Empresa, SmtpEmpresa } from ".";

export class Parametro implements Shared.IEntity{

    id: number = 0;
    empresaId: number = 0;
    empresa!: Empresa;
    recuperarSenhaSMTPId? : number;
    recuperarSenhaSMTP! : SmtpEmpresa;
    porcentagemLocalOperacaoNoturno: number = 0;
    porcentagemLocalOperacaoFimSemanaFeriado: number = 0;
    agendamentoNotificacaoUsuarioIds: string = '';
    smtpEmpresas: SmtpEmpresa[] = [];
    

    constructor(model?: Parametro){

        if(!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;
        this.recuperarSenhaSMTPId = model.recuperarSenhaSMTPId;
        this.porcentagemLocalOperacaoNoturno = model.porcentagemLocalOperacaoNoturno;
        this.porcentagemLocalOperacaoFimSemanaFeriado = model.porcentagemLocalOperacaoFimSemanaFeriado;
        this.agendamentoNotificacaoUsuarioIds = model.agendamentoNotificacaoUsuarioIds;
        this.smtpEmpresas = model.smtpEmpresas;
        
    }
}