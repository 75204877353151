import Vue from 'vue'
import VueRouter, { NavigationGuard, NavigationGuardNext, RouteConfig } from 'vue-router'
import Swal from 'sweetalert2'
import { EnumTipoUsuario } from '@/core/models/shared/Enumerados'

Vue.use(VueRouter)

const tipoUsuario = EnumTipoUsuario;

const verificaPerfil = (to: any, next: NavigationGuardNext<Vue>, perfilPermitido: number[]) => {

  var sessionApp: any;

  if (localStorage.sessionApp) {
    sessionApp = JSON.parse(localStorage.sessionApp);
    if (perfilPermitido.find(a => a == sessionApp.dados.perfilId) == undefined || perfilPermitido.find(a => a == sessionApp.dados.perfilId) == null) {
      next({ name: 'home' });
    }    
    else {
      if(sessionApp.dados.perfilId == tipoUsuario.Cliente && sessionApp.dados.clienteId == null && to.path != "/home"){
        Swal.fire({
          title: "Aviso",
          text: `Seu usuário não está vinculado a nenhum cliente. Favor, contatar a ${sessionApp.dados.empresaNome}`,
          icon: "warning"
        });
        next({ name: 'home' });
      }
      else{
        next();
      }
    }
  }
  else {
    next({ name: 'login' });
  }
}

const routes: Array<RouteConfig> = [
  { path: "/", redirect: "/home", beforeEnter: (to, from, next) => verificaPerfil(to, next, [tipoUsuario.Administrador, tipoUsuario.Operacional, tipoUsuario.Financeiro, tipoUsuario.Cliente, tipoUsuario.Comercial]) },

  { path: '/login', name: 'login', component: () => import("../views/Login.vue") },
  { path: '/login/:token', name: 'loginToken', component: () => import("../views/Login.vue") },
  { path: '/home', name: 'home', component: () => import("../views/Home.vue"), beforeEnter: (to, from, next) => verificaPerfil(to, next, [tipoUsuario.Administrador, tipoUsuario.Operacional, tipoUsuario.Financeiro, tipoUsuario.Cliente, tipoUsuario.Comercial]) },

  { path: '/cliente', name: 'listaCliente', component: () => import("../views/geral/cliente/Lista.vue"), beforeEnter: (to, from, next) => verificaPerfil(to, next, [tipoUsuario.Administrador, tipoUsuario.Comercial]) },
  { path: '/funcao', name: 'listaFuncao', component: () => import("../views/geral/funcao/Lista.vue"), beforeEnter: (to, from, next) => verificaPerfil(to, next, [tipoUsuario.Administrador, tipoUsuario.Comercial]) },
  { path: '/funcionario', name: 'listaFuncionario', component: () => import("../views/geral/funcionario/Lista.vue"), beforeEnter: (to, from, next) => verificaPerfil(to, next, [tipoUsuario.Administrador, tipoUsuario.Financeiro, tipoUsuario.Comercial]) },
  { path: '/funcionario/ficha/:id', name: 'fichaFuncionario', component: () => import("../views/geral/funcionario/Detalhar.vue"), beforeEnter: (to, from, next) => verificaPerfil(to, next, [tipoUsuario.Administrador, tipoUsuario.Operacional, tipoUsuario.Financeiro, tipoUsuario.Comercial]) },
  
  { path: '/fornecedor', name: 'listaFornecedor', component: () => import("../views/geral/fornecedor/Lista.vue"), beforeEnter: (to, from, next) => verificaPerfil(to, next, [tipoUsuario.Administrador, tipoUsuario.Comercial]) },

  { path: '/contato', name: 'listaContato', component: () => import("../views/geral/contato/Lista.vue"), beforeEnter: (to, from, next) => verificaPerfil(to, next, [tipoUsuario.Administrador, tipoUsuario.Comercial]) },
  { path: '/tipoEmbarcacao', name: 'listaTipoEmbarcacao', component: () => import("../views/geral/tipoEmbarcacao/Lista.vue"), beforeEnter: (to, from, next) => verificaPerfil(to, next, [tipoUsuario.Administrador, tipoUsuario.Comercial]) },
  { path: '/embarcacao', name: 'listaEmbarcacao', component: () => import("../views/geral/embarcacao/Lista.vue"), beforeEnter: (to, from, next) => verificaPerfil(to, next, [tipoUsuario.Administrador, tipoUsuario.Financeiro, tipoUsuario.Comercial]) },
  { path: '/equipamento', name: 'listaEquipamento', component: () => import("../views/geral/equipamento/Lista.vue"), beforeEnter: (to, from, next) => verificaPerfil(to, next, [tipoUsuario.Administrador, tipoUsuario.Financeiro, tipoUsuario.Comercial]) },
  { path: '/faina', name: 'listaFaina', component: () => import("../views/geral/faina/Lista.vue"), beforeEnter: (to, from, next) => verificaPerfil(to, next, [tipoUsuario.Administrador, tipoUsuario.Comercial]) },
  { path: '/porto', name: 'listaPorto', component: () => import("../views/geral/porto/Lista.vue"), beforeEnter: (to, from, next) => verificaPerfil(to, next, [tipoUsuario.Administrador, tipoUsuario.Comercial]) },
  { path: '/terno', name: 'listaTerno', component: () => import("../views/geral/terno/Lista.vue"), beforeEnter: (to, from, next) => verificaPerfil(to, next, [tipoUsuario.Administrador, tipoUsuario.Comercial]) },
  { path: '/unidadeMedida', name: 'listaUnidadeMedida', component: () => import("../views/geral/unidadeMedida/Lista.vue"), beforeEnter: (to, from, next) => verificaPerfil(to, next, [tipoUsuario.Administrador, tipoUsuario.Comercial]) },
  { path: '/tipoServicoEquipamento', name: 'listaTipoServicoEquipamento', component: () => import("../views/geral/tipoServicoEquipamento/Lista.vue"), beforeEnter: (to, from, next) => verificaPerfil(to, next, [tipoUsuario.Administrador, tipoUsuario.Comercial]) },

  // { path: '/cotacao', name: 'listaCotacao', component: () => import("../views/financeiro/cotacao/Lista.vue"), beforeEnter: (to, from, next) => verificaPerfil(to, next, [1, 3]) },
  { path: '/despesa', name: 'listaDespesa', component: () => import("../views/financeiro/despesa/Lista.vue"), beforeEnter: (to, from, next) => verificaPerfil(to, next, [tipoUsuario.Administrador, tipoUsuario.Financeiro, tipoUsuario.Comercial]) },
  { path: '/despesa/relatorio', name: 'ralatorioDespesa', component: () => import("../views/financeiro/despesa/Relatorio.vue"), beforeEnter: (to, from, next) => verificaPerfil(to, next, [tipoUsuario.Administrador, tipoUsuario.Financeiro, tipoUsuario.Comercial]) },
  { path: '/tipoDespesa', name: 'listaTipoDespesa', component: () => import("../views/financeiro/tipoDespesa/Lista.vue"), beforeEnter: (to, from, next) => verificaPerfil(to, next, [tipoUsuario.Administrador, tipoUsuario.Comercial]) },
  { path: '/receita', name: 'listaReceita', component: () => import("../views/financeiro/receita/Lista.vue"), beforeEnter: (to, from, next) => verificaPerfil(to, next, [tipoUsuario.Administrador, tipoUsuario.Financeiro, tipoUsuario.Comercial]) },
  { path: '/tipoReceita', name: 'listaTipoReceita', component: () => import("../views/financeiro/tipoReceita/Lista.vue"), beforeEnter: (to, from, next) => verificaPerfil(to, next, [tipoUsuario.Administrador, tipoUsuario.Comercial]) },
  { path: '/receita/relatorio', name: 'relatorioReceita', component: () => import("../views/financeiro/receita/Relatorio.vue"), beforeEnter: (to, from, next) => verificaPerfil(to, next, [tipoUsuario.Administrador, tipoUsuario.Financeiro, tipoUsuario.Comercial]) },
  { path: '/receita/relatorio2', name: 'relatorioReceita2', component: () => import("../views/financeiro/receita/Relatorio2.vue"), beforeEnter: (to, from, next) => verificaPerfil(to, next, [tipoUsuario.Administrador, tipoUsuario.Financeiro, tipoUsuario.Comercial]) },

  { path: '/proposta', name: 'listaProposta', component: () => import("../views/operacional/proposta/Lista.vue"), beforeEnter: (to, from, next) => verificaPerfil(to, next, [tipoUsuario.Administrador, tipoUsuario.Financeiro, tipoUsuario.Comercial]) },
  { path: '/agendamento', name: 'listaAgendamento', component: () => import("../views/operacional/agendamento/Lista.vue"), beforeEnter: (to, from, next) => verificaPerfil(to, next, [tipoUsuario.Administrador, tipoUsuario.Operacional, tipoUsuario.Cliente, tipoUsuario.Comercial]) },
  { path: '/registroOperacional', name: 'listaRegistroOperacional', component: () => import("../views/operacional/registroOperacional/Lista.vue"), beforeEnter: (to, from, next) => verificaPerfil(to, next, [tipoUsuario.Administrador, tipoUsuario.Operacional, tipoUsuario.Financeiro, tipoUsuario.Comercial]) },
  { path: '/registroOperacional/relatorio', name: 'ralatorioRegistroOperacional', component: () => import("../views/operacional/registroOperacional/Relatorio.vue"), beforeEnter: (to, from, next) => verificaPerfil(to, next, [tipoUsuario.Administrador, tipoUsuario.Operacional, tipoUsuario.Financeiro, tipoUsuario.Comercial]) },
  { path: '/registroOperacional/detalhar/:id', name: 'detalharRegistroOperacional', component: () => import("../views/operacional/registroOperacional/Detalhar.vue"), beforeEnter: (to, from, next) => verificaPerfil(to, next, [tipoUsuario.Administrador, tipoUsuario.Operacional, tipoUsuario.Financeiro, tipoUsuario.Comercial]) },
  { path: '/registroOperacional/boletimMedicao/:id', name: 'boletimMedicaoRegistroOperacional', component: () => import("../views/operacional/registroOperacional/BoletimMedicao.vue"), beforeEnter: (to, from, next) => verificaPerfil(to, next, [tipoUsuario.Administrador, tipoUsuario.Cliente, tipoUsuario.Financeiro, tipoUsuario.Comercial]) },
  { path: '/relatoriomedicao', name: 'listaRelatorioMedicao', component: () => import("../views/operacional/relatorioMedicao/Lista.vue"), beforeEnter: (to, from, next) => verificaPerfil(to, next, [tipoUsuario.Administrador, tipoUsuario.Operacional, tipoUsuario.Cliente, tipoUsuario.Financeiro, tipoUsuario.Comercial]) },
  { path: '/relatoriomedicao/:id', name: 'detalharRelatorioMedicao', component: () => import("../views/operacional/relatorioMedicao/Detalhar.vue"), beforeEnter: (to, from, next) => verificaPerfil(to, next, [tipoUsuario.Administrador, tipoUsuario.Operacional, tipoUsuario.Cliente, tipoUsuario.Financeiro, tipoUsuario.Comercial]) },
  { path: '/controleOrdemServico', name: 'controleOrdemServico', component: () => import("../views/operacional/controleOrdemServico/Lista.vue"), beforeEnter: (to, from, next) => verificaPerfil(to, next, [tipoUsuario.Administrador, tipoUsuario.Operacional]) },
  { path: '/pedidoCompra', name: 'listaPedidoCompra', component: () => import("../views/operacional/pedidoCompra/Lista.vue"), beforeEnter: (to, from, next) => verificaPerfil(to, next, [tipoUsuario.Administrador, tipoUsuario.Operacional, tipoUsuario.Financeiro, tipoUsuario.Comercial]) },
  { path: '/pedidoCompra/detalhar/:id', name: 'detalharPedidoCompra', component: () => import("../views/operacional/pedidoCompra/Detalhar.vue"), beforeEnter: (to, from, next) => verificaPerfil(to, next, [tipoUsuario.Administrador, tipoUsuario.Operacional, tipoUsuario.Financeiro, tipoUsuario.Comercial]) },
  { path: '/configuracao/agendaFuncionario', name: 'configuracaoAgendaFuncionario', component: () => import("../views/operacional/agendaFuncionario/Configuracao.vue"), beforeEnter: (to, from, next) => verificaPerfil(to, next, [tipoUsuario.Administrador, tipoUsuario.Operacional]) },
  { path: '/agendaFuncionario', name: 'relatorioAgendaFuncionario', component: () => import("../views/operacional/agendaFuncionario/Relatorio.vue"), beforeEnter: (to, from, next) => verificaPerfil(to, next, [tipoUsuario.Administrador, tipoUsuario.Operacional, tipoUsuario.Comercial, tipoUsuario.Financeiro]) },
  // { path: '/cabeco', name: 'listaCabeco', component: () => import("../views/operacional/cabeco/Lista.vue"), beforeEnter: (to, from, next) => verificaPerfil(to, next, [1, 2, 3]) },
  
  //AVALIACAO RO
  { path: '/registroOperacional/avaliar/:id/:token', name: 'avaliarRegistroOperacional', component: () => import("../views/operacional/registroOperacional/Aprovacao.vue") },

  { path: '/configuracao', name: 'listaConfiguracao', component: () => import("../views/administrador/Configuracao.vue"), beforeEnter: (to, from, next) => verificaPerfil(to, next, [tipoUsuario.Administrador]) },
  { path: '/configuracao/impressaoAtracacao', name: 'impressaoAtracacao', component: () => import("../views/administrador/RelatorioAtracacao.vue"), beforeEnter: (to, from, next) => verificaPerfil(to, next, [tipoUsuario.Administrador]) },
  { path: '/configuracao/impressaoTerno', name: 'impressaoTerno', component: () => import("../views/administrador/RelatorioTerno.vue"), beforeEnter: (to, from, next) => verificaPerfil(to, next, [tipoUsuario.Administrador]) },
  { path: '/configuracao/impressaoFaina', name: 'impressaoFaina', component: () => import("../views/administrador/RelatorioFaina.vue"), beforeEnter: (to, from, next) => verificaPerfil(to, next, [tipoUsuario.Administrador]) },
  { path: '/configuracao/impressaoEquipamento', name: 'impressaoEquipamento', component: () => import("../views/administrador/RelatorioEquipamento.vue"), beforeEnter: (to, from, next) => verificaPerfil(to, next, [tipoUsuario.Administrador]) },
  { path: '/configuracao/impressaoDespesaReceita', name: 'impressaoDespesaReceita', component: () => import("../views/administrador/RelatorioDespesaReceita.vue"), beforeEnter: (to, from, next) => verificaPerfil(to, next, [tipoUsuario.Administrador]) },
  { path: '/configuracao/fornecedorDetalharEquipamentoServico', name: 'fornecedorDetalharEquipamentoServico', component: () => import("../views/geral/fornecedor/DetalharEquipamentoServico.vue"), beforeEnter: (to, from, next) => verificaPerfil(to, next, [tipoUsuario.Administrador]) },

  { path: '/empresa', name: 'listaEmpresa', component: () => import("../views/geral/empresa/Lista.vue"), beforeEnter: (to, from, next) => verificaPerfil(to, next, [tipoUsuario.Administrador]) },
  { path: '/usuario', name: 'listaUsuario', component: () => import("../views/geral/usuario/Lista.vue"), beforeEnter: (to, from, next) => verificaPerfil(to, next, [tipoUsuario.Administrador]) },
  { path: '/parametros', name: 'listaParametro', component: () => import("../views/configuracao/parametro/Lista.vue"), beforeEnter: (to, from, next) => verificaPerfil(to, next, [tipoUsuario.Administrador]) },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
