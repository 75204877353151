import { Empresa } from "../geral";

export class Terno implements Shared.IEntity{

    id: number = 0;
    empresaId: number = 0;
    empresa!: Empresa;
    horaInicial: string = '';
    horaFinal: string = '';
    ativo: boolean = true;

    constructor(model?: Terno){

        if(!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;
        this.horaInicial = model.horaInicial;
        this.horaFinal = model.horaFinal;
        this.ativo = model.ativo;
    }
}