import { LocalOperacaoPeriodo } from ".";
import { Porto } from "../geral";
import { Empresa } from "../geral/Empresa";


export class LocalOperacao implements Shared.IEntity {
    id: number = 0;
    empresaId: number = 0;
    empresa!: Empresa;
    portoId?: number = 0;
    porto!: Porto;
    nome: string = '';
    anteriorTempoMinimo: number = 0;
    tempoMinimo: number = 0;
    calcularDWT: boolean = false;
    calcularPeriodoOperanteInoperante: boolean = false;
    ativo: boolean = true;

    periodos: LocalOperacaoPeriodo[] = [];

    constructor(model?: LocalOperacao) {
        if (!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;
        this.empresa = model.empresa;
        this.portoId = model.portoId;
        this.porto = model.porto;
        this.nome = model.nome;
        this.anteriorTempoMinimo = model.anteriorTempoMinimo;
        this.tempoMinimo = model.tempoMinimo;
        this.calcularDWT = model.calcularDWT;
        this.calcularPeriodoOperanteInoperante = model.calcularPeriodoOperanteInoperante;
        this.ativo = model.ativo;

        this.periodos = model.periodos;
    }
}