import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import VueTheMask from 'vue-the-mask';
import Swal, { SweetAlertOptions } from 'sweetalert2'

Vue.use(VueTheMask);

import './registerServiceWorker'

require('./components/');
import './assets/scripts/formatter'

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
