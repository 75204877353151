import { Cabeco, RegistroOperacional } from '.';

export class RegistroOperacionalCabeco implements Shared.IEntity{

    id: number = 0;
    registroOperacionalId: number = 0;
    registroOperacional!: RegistroOperacional;
    cabecoId: number = 0;
    cabeco!: Cabeco;

    constructor(model?: RegistroOperacionalCabeco){

        if(!model)
            return;

        this.id = model.id;
        this.registroOperacionalId = model.registroOperacionalId;
        this.cabecoId = model.cabecoId;
        this.cabeco = model.cabeco;
    }

}