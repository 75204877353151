import { Empresa } from '.';
import { TipoCliente } from './TipoCliente';
import { PessoaJuridica } from './../shared/Pessoa';

export class Cliente extends PessoaJuridica{
    
    empresaId: number = 0;
    empresa!: Empresa;
    tipoId: number = 0;
    tipo!: TipoCliente;

    constructor(model?: Cliente){

        super(model);
        
        if(!model)
            return;

        this.empresaId = model.empresaId;
        this.tipoId = model.tipoId;
    }

}