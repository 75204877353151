


































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { AgendamentoDocumento } from "@/core/models/operacional";

@Component
export default class CadastroDocumento extends Vue {
    @Prop() private item!: AgendamentoDocumento;
    @Prop() private fullScreen!: boolean;
    @Prop() private value!: string;
 
    valid = true;
    dialog = false;
    fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
    $refs!: {
        form: HTMLFormElement
    }

    @Watch('item')
    Item() {
        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }
    }

    @Watch("value")
    Value() {
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (!this.dialog) {
            this.$emit("fechou");
        }
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    Salvar(){
        if (this.$refs.form.validate()) {    
            this.$emit('salvou');
            this.Close();
        }
    }

    Close() {
        this.dialog = false;
    }
}
