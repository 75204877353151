import { Proposta } from './Proposta';
import { Equipamento } from './Equipamento';
import { PropostaEquipamentoPeriodo } from './PropostaEquipamentoPeriodo';

export class PropostaEquipamento implements Shared.IEntity{

    id: number = 0;
    propostaId: number = 0;
    proposta!: Proposta;
    equipamentoId: number = 0;
    equipamento!: Equipamento;
    tempoMinimo: number = 0;
    porcentagemExcedente: number = 0;
    valor: number = 0;
    periodoId: number = 0;
    periodo!: PropostaEquipamentoPeriodo;

    constructor(model?: PropostaEquipamento){

        if(!model)
            return;

        this.id = model.id;
        this.propostaId = model.propostaId;
        this.proposta = model.proposta;
        this.equipamentoId = model.equipamentoId;
        this.equipamento = model.equipamento;
        this.porcentagemExcedente = model.porcentagemExcedente;
        this.valor = model.valor;
        this.periodoId = model.periodoId;
        this.periodo = model.periodo;
    }
}