

























































































































































































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import jiff from 'jiff';
import { PortoTerno, Proposta, PropostaEquipamento, PropostaLocalOperacaoPeriodo, PropostaTerno, Terno } from "@/core/models/operacional";
import { PropostaService, TernoService } from "@/core/services/operacional";
import { ClienteService, ContatoService, FuncionarioService, PortoService } from "@/core/services/geral";
import { Cliente, Contato, Funcionario, Porto } from "@/core/models/geral";
import { LocalOperacao } from "@/core/models/operacional/LocalOperacao";
import { PageBase } from "@/core/models/shared";
import { EnumTipoUsuario } from "@/core/models/shared/Enumerados";
import { AlertExcludeQuestion, AlertSimple, AlertSimpleErr, AlertSimpleRes } from "@/core/services/shared/AlertService";

@Component
export default class CadastroProposta extends PageBase {
  @Prop() private item!: Proposta;
  @Prop() private value!: string;

  itemOriginal!: Proposta;

  tipoUsuario = EnumTipoUsuario;
  
  valid: boolean = true;
  validTerno: boolean = true;

  loading: boolean = false;
  service = new PropostaService();
  dialog = false;
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
  numberFieldRules: any[] = [(v: any) => (!isNaN(parseFloat(v)) && v >= 0) || "Campo obrigatório"];
  $refs!: {
    form: HTMLFormElement
    formTerno: HTMLFormElement
  }
  
  tabActive: any = {};

  headerTernos: any[] = [
    { text: '',value:'actions' ,sortable: false },
    { text: 'Horas', value: 'horas'},
    { text: 'Valor', value: 'valor'},
    { text: 'Porcentagem Noturna', value: 'porcentagemNoturna'},
    { text: 'Valor Noturno', value: 'valorPorcentagemNoturna'},
    { text: 'Porcentagem Fim de Semana/Feriado', value: 'porcentagemFimSemanaFeriado'},
    { text: 'Valor Fim de Semana/Feriado', value: 'valorPorcentagemFimSemanaFeriado'}
  ];

  headerEquipamentoServico: any[] = [
    { text: '',value:'actions' ,sortable: false },
    { text: 'Nome', value: 'equipamento.nomeTipo'},
    { text: 'Valor Unitário', value: 'valor'},
    { text: 'Mínimo', value: 'tempoMinimo'},
    { text: 'Unidade', value: 'periodo.nome'}
  ];

  headerLocalOPeracaoPeriodos: any[] = [
    { text: '',value:'actions' ,sortable: false },
    { text: 'Tempo (Horas)', value: 'periodo.tempo' },
    { text: 'Valor', value: 'valor' }
  ];

  contatoService = new ContatoService();
  contatos: Contato [] = [];
  onSearchContato: any = null;
  isContatoLoading: boolean = false;

  responsavelSerivce: FuncionarioService = new FuncionarioService();
  responsaveis: Funcionario[] = [];
  onSearchResponsavel: any = null;
  isResponsavelLoading: boolean = false;

  clienteService = new ClienteService();
  clientes: Cliente[] = [];
  onSearchCliente: any = null;
  isClienteLoading: boolean = false;

  dialogEquipamento: boolean = false;
  propostaEquipamento: PropostaEquipamento = new PropostaEquipamento();
  editaEquipamento: boolean = false;
  
  ternoService: TernoService = new TernoService();
  propostaTerno: PropostaTerno = new PropostaTerno();
  horasTernos: number[] = [];

  localOperacao: LocalOperacao = new LocalOperacao();
  locaisOperacao: LocalOperacao[] = [];

  portos: Porto[] = [];
  portoService = new PortoService();

  @Watch('item')
  Item() {
    if (this.$refs.form) {
      this.$refs.form.resetValidation();
    }
  }

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;

    if (this.dialog){
      this.itemOriginal = jiff.clone(this.item);

      if(this.item.contato)
        this.contatos.push(this.item.contato);
      if(this.item.cliente)
        this.clientes.push(this.item.cliente);
      if(this.item.responsavel)
        this.responsaveis.push(this.item.responsavel);
      if(this.item.porto)
        this.portos.push(this.item.porto);
      if(this.item.localOperacao){
        this.localOperacao = this.item.localOperacao;
        this.locaisOperacao.push(this.item.localOperacao);
      }
    }
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  @Watch('onSearchCliente')
  SearchCliente (val: string) {
    if (this.isClienteLoading) return;
    if (!val) return;
    this.isClienteLoading = true
    this.clienteService.AutoComplete(val).then(
      res => {
        this.clientes = res.data;
      },
      err => AlertSimpleErr("Aviso!", err)
    ).finally(() => (this.isClienteLoading = false));
  }

  @Watch('onSearchContato')
  SearchContato (val: string) {
    if (this.isContatoLoading) return;
    if (!val) return;
    this.isContatoLoading = true
    this.contatoService.AutoComplete(val).then(
      res => {
        this.contatos = res.data;
      },
      err => AlertSimpleErr("Aviso!", err)
    ).finally(() => (this.isContatoLoading = false));
  }

  @Watch('onSearchResponsavel')
  SearchResponsavel (val: string) {
    if (this.isResponsavelLoading) return;
    if (!val) return;
    this.isResponsavelLoading = true
    this.responsavelSerivce.AutoComplete(val).then(
      res => {
        this.responsaveis = res.data;
      },
      err => AlertSimpleErr("Aviso!", err)
    ).finally(() => (this.isResponsavelLoading = false));
  }

  @Watch('item.portoId')
  WatchPorto(){
    if(this.item.portoId){
      var porto = this.portos.find(x => x.id == this.item.portoId)!;
      porto.locaisOperacao.forEach(x => {
        this.locaisOperacao.push(x)
      })
    }
    else{
      this.localOperacao = new LocalOperacao();
      this.locaisOperacao = [];
      return
    }
  }

  @Watch('item.localOperacaoId')
  WatchLocalOperacao(){
    this.localOperacao = new LocalOperacao();
    this.localOperacao = this.locaisOperacao.find(x => x.id == this.item.localOperacaoId)!;
    if(this.item.id == 0){
      this.ResetLocalOperacaoPeriodos();
    }
  }

  beforeUpdate(){
    if (!this.dialog){
      this.$emit('fechou');
    }
  }

  mounted() {
    this.portoService.ListarTudo("Ternos, LocaisOperacao.Periodos").then(
      res => {
        this.portos = res.data.items;
      }
    )
    this.ternoService.Horas().then(
      res => this.horasTernos = res.data,
      err => AlertSimpleErr("Aviso!", err)
    )    
  }

  ResetLocalOperacaoPeriodos(){
    this.item.localOperacaoPeriodos = [];
    this.localOperacao.periodos.forEach(periodo => {
      let propostaPeriodo = new PropostaLocalOperacaoPeriodo();
      propostaPeriodo.periodoId = periodo.id;
      propostaPeriodo.periodo = periodo;
      this.item.localOperacaoPeriodos.push(propostaPeriodo);
    });
  }

  AtualizarPeriodos(){
    this.item.localOperacaoPeriodos = [];
    this.localOperacao.periodos.forEach(periodo => {
      let propostaPeriodo = new PropostaLocalOperacaoPeriodo();
      propostaPeriodo.periodoId = periodo.id;
      propostaPeriodo.periodo = periodo;
      this.item.localOperacaoPeriodos.push(propostaPeriodo);
    });
  }

  AdicionarTerno(){
    if(this.$refs.formTerno.validate()){
      if(this.item.ternos.find(x => x.horas == this.propostaTerno.horas)){
        AlertSimple("Aviso!", "Hora escolhida já se encontra adicionada!", "warning");
        return;
      }

      this.item.ternos.push(this.propostaTerno);
      this.propostaTerno = new PropostaTerno();
    }
  }

  ExcluirTerno(item: PropostaTerno){
    const context = this;
    const excluir = function () {
      return new Promise( async function (resolve, reject){
        const index = context.item.ternos.indexOf(item);
        context.item.ternos.splice(index,1);
      });
    }
    AlertExcludeQuestion(excluir, true);
  }

  AbrirDialogEquipamentoServico(item?: PropostaEquipamento){
    if(item){
      this.propostaEquipamento = new PropostaEquipamento()
      this.propostaEquipamento = item;
      this.editaEquipamento = true;
    }
    else{
      this.propostaEquipamento = new PropostaEquipamento();
      this.editaEquipamento = false;
    }
    this.dialogEquipamento = true;
  }

  SalvarEquipamento(){
    if(!this.editaEquipamento){
      if(this.item.equipamentos.some(x => x.equipamentoId == this.propostaEquipamento.equipamentoId)){
        AlertSimple("Aviso", "O equipamento selecionado já se encontra cadastrado.", "warning" );
      }
      else{
        this.item.equipamentos.push(this.propostaEquipamento);
      }
    }
    this.dialogEquipamento = false;
  }

  ExcluirEquipamentoServico(item: PropostaEquipamento){
    const context = this;
    const excluir = function () {
      return new Promise( async function (resolve, reject){
        const index = context.item.equipamentos.indexOf(item);
        context.item.equipamentos.splice(index,1);
      });
    }
    AlertExcludeQuestion(excluir, true);
  }  

  Salvar() {
    if (this.$refs.form.validate()) {
      this.loading = true;
      let pacthModel = jiff.diff(this.itemOriginal, this.item, false);
      (this.item.id > 0 ? this.service.Salvar(pacthModel, this.item.id) : this.service.Salvar(this.item)).then(
        res => {
          AlertSimpleRes("Aviso!", res);
          this.$emit("salvou");
          this.Close();
        },
        err => AlertSimpleErr("Aviso!", err)
      ).finally(() => {
        this.loading = false;
      });
    }
  }

  Close() {
    this.dialog = false;
  }
}
