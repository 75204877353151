






























import { Component, Prop, Watch } from "vue-property-decorator";
import { PageBase } from '@/core/models/shared';
import { PortoTerno, Terno } from "@/core/models/operacional";
import { TernoService } from "@/core/services/operacional";
import { AlertSimpleErr } from "@/core/services/shared/AlertService";

@Component
export default class CadastroPortoTerno extends PageBase{
    @Prop() private item!: PortoTerno;
    @Prop() private value!: string;

    validDialogResiduo = true;
    dialog = false;

    fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

    $refs!: {
        form: HTMLFormElement
    }

    ternoService = new TernoService()
    ternos: Terno[] = [];
    
    Salvar(){

        if (this.$refs.form.validate()) {

            this.item.terno = new Terno(this.ternos.find(x => x.id == this.item.ternoId));

            this.$emit("salvou");
            this.dialog = false;
        }
    }    

    @Watch('item')
    Item(){

        if(this.$refs.form){
            this.$refs.form.resetValidation();
        }
    }

    @Watch("value")
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (!this.dialog) {
        this.$emit("fechou");
        }
    }

    beforeUpdate() {
        if(!this.dialog){
            this.$emit("fechou");
        }
    }

    mounted() {
        this.ternoService.ListarTudo().then(
            res=>{
                this.ternos = res.data.items;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    Close() {
        this.dialog = false;
    }
}   

