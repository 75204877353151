import { Empresa } from '.';

export class Funcao implements Shared.IEntity{
    
    id:number = 0;
    empresaId:number = 0;
    empresa!:Empresa;
    nome: string = "";
    ativo: boolean = true;

    constructor(model?: Funcao){
        
        if(!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;
        this.nome = model.nome;
        this.ativo = model.ativo;        
    }
}