import { Terno } from '.';
import { Porto } from '../geral';

export class PortoTerno {

    portoId: number = 0;
    porto!: Porto;
    ternoId: number = 0;
    terno!: Terno;

    constructor(model?: PortoTerno){

        if(!model)
            return;

        this.portoId = model.portoId;
        this.ternoId = model.ternoId;
        
    }
}