import { JSONToCSVConvertor, TableToExcel } from "@/assets/scripts/helper";
import { Vue } from "vue-property-decorator";

export class PageBase extends Vue{

    public sessionApp: any;
    public app: {empresaId: number, empresaLogo: any, empresaNome: string, perfilId: number, temaEscuro: boolean, usuarioId: number, usuarioNome: string, usuarioFoto: any, primeiroAcesso: boolean, clienteId?: number} = {
        empresaId: 0, 
        empresaLogo: '', 
        empresaNome: '', 
        perfilId: 0, 
        temaEscuro: false, 
        usuarioId: 0, 
        usuarioNome: '', 
        usuarioFoto: '',
        primeiroAcesso: false
    };

    constructor(){

        super();

        if(!localStorage.sessionApp){
            this.$router.push({name: 'login'});
            return;
        }

        this.sessionApp = JSON.parse(localStorage.sessionApp);

        this.app.empresaId = this.sessionApp.dados.empresaId;
        this.app.empresaNome = this.sessionApp.dados.empresaNome;
        this.app.empresaLogo = this.sessionApp.dados.empresaLogo;
        this.app.perfilId = this.sessionApp.dados.perfilId;        
        this.app.usuarioId = this.sessionApp.dados.usuarioId;
        this.app.usuarioNome = this.sessionApp.dados.usuarioNome;
        this.app.usuarioFoto = this.sessionApp.dados.usuarioFoto;
        this.app.temaEscuro = this.sessionApp.dados.temaEscuro;
        this.app.primeiroAcesso = this.sessionApp.dados.primeiroAcesso;
        this.app.clienteId = this.sessionApp.dados.clienteId;

    }

    JsonToCSV(JSONData: JSON, ReportTitle: string, ShowLabel: boolean){
        JSONToCSVConvertor(JSONData, ReportTitle, ShowLabel);
    }

    ExportarExcel(tabela: string, worksheet?: string){
        TableToExcel(tabela, worksheet);
    }

    MudarTema(temaEscuro: boolean){
        this.app.temaEscuro = temaEscuro;
        this.sessionApp.dados.temaEscuro = temaEscuro;
        localStorage.setItem('sessionApp', JSON.stringify(this.sessionApp));
        this.$vuetify.theme.dark = temaEscuro;
    }

}