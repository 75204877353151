import { Funcao, Funcionario } from "../geral";
import { RegistroOperacional } from "./RegistroOperacional";

export class RegistroOperacionalFuncionario implements Shared.IEntity{

    id: number = 0;
    registroOperacionalId: number = 0;
    registroOperacional!: RegistroOperacional;
    funcionarioId: number = 0;
    funcionario!: Funcionario;    
    funcaoId: number = 0;
    funcao!: Funcao;

    constructor(model?: RegistroOperacionalFuncionario){

        if(!model)
            return;

        this.id  = model.registroOperacionalId;
        this.registroOperacionalId = model.registroOperacionalId;
        this.registroOperacional = model.registroOperacional;
        this.funcionarioId = model.funcionarioId;
        this.funcionario = model.funcionario;
        this.funcaoId = model.funcaoId;
        this.funcao = model.funcao;
    }
}