import { http } from '@/core/ApiConfig';
import { Service } from '../shared/';

export class DocumentoService extends Service{

  constructor(){
    super('documento');
  }

  Gravar(id: number, arquivo: any){
    let bodyFormData = new FormData();
    bodyFormData.append('arquivo', new Blob([arquivo]));

    return http.post(`${this.GetNomeControle()}/${id}/arquivo`, bodyFormData, {
      headers: {'Content-Type': 'multipart/form-data' }
    });
  }

  async Ler(id: number, nome: string){
    return http.get(`${this.GetNomeControle()}/${id}/arquivo`, {
      responseType: 'blob',
      params: {
        nome
      }
    }).then(res => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', nome);
      document.body.appendChild(link);
      link.click();
    });
  }

  DeletarDocumento(id: number, nome: string){
    return http.delete(`${this.GetNomeControle()}/${id}/arquivo`, {
      params: {
        nome
      }
    });
  }

}