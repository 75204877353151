import { Cotacao } from ".";
import { Contato } from "../geral";

export class CotacaoResposta implements Shared.IEntity{
    
    id: number = 0;
    cotacaoId: number = 0;
    cotacao!: Cotacao;
    contatoId: number = 0;
    contato!: Contato;
    token: string = '';
    valor?: number;

    aceite!: Aceitar;
    rejeicao!: Rejeitar;

    constructor(model?: CotacaoResposta){
        
        if(!model)
            return;

        this.id = model.id;
        this.cotacaoId = model.cotacaoId;
        this.contatoId = model.contatoId;
        this.contato = model.contato;
        this.token = model.token;
        this.valor = model.valor;

        this.aceite = model.aceite;
        this.rejeicao = model.rejeicao;
    }
}

class Aceitar{
    observacao?: string;
    dataHora?: string;
}

class Rejeitar{
    observacao?: string;
    dataHora?: string;
}