import { http, httpHeader } from '@/core/ApiConfig';
import { Service } from '../shared';

export class FainaService extends Service {

  constructor() {
    super('faina');
  }

  public AutoComplete(q: string) {
    return http.get(`${this.GetNomeControle()}/AutoComplete`, {
      params: {
        q: q
      },
      headers: httpHeader.headers
    });
  }
}