import { Empresa, FornecedorEquipamentoServico } from ".";
import { PessoaJuridica } from "../shared/Pessoa";

export class Fornecedor extends PessoaJuridica{
    
    empresaId: number = 0;
    empresa!: Empresa;
    link: string = '';

    equipamentosServicos: FornecedorEquipamentoServico[] = [];

    constructor(model?: Fornecedor){

        super(model);
        
        if(!model)
            return;

        this.empresaId = model.empresaId;
        this.link = model.link;

        this.equipamentosServicos = model.equipamentosServicos;
    }
}