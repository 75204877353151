import { http, httpHeader } from '@/core/ApiConfig';
import { RegistroOperacionalEnvioAprovacao, RegistroOperacionalEquipamento, RegistroOperacionalEquipamentoDocumento, RegistroOperacionalFainaDocumento } from '@/core/models/operacional';
import { Service } from '../shared';

export class RegistroOperacionalService extends Service {

  constructor() {
    super('registroOperacional');
  }

  private GetFiltro(parametros: string, filter: any) {

    if (filter) {
      let paramTemp = '';

      if (filter.numero) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `numero eq ${filter.numero}`;
      }

      if (filter.dataDocumentoInicial) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `dataDocumento ge ${filter.dataDocumentoInicial}`;
      }

      if (filter.dataDocumentoFinal) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `dataDocumento le ${filter.dataDocumentoFinal}`;
      }

      if (filter.atracacaoEntradaInicial) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `atracacao/dataHoraEntrada ge ${new Date(filter.atracacaoEntradaInicial).toYYYYMMDD}`;
      }

      if (filter.atracacaoEntradaFinal) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `atracacao/dataHoraEntrada le ${new Date(filter.atracacaoEntradaFinal).toYYYYMMDD}`;
      }

      if (filter.atracacaoSaidaInicial) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `atracacao/dataHoraSaida ge ${new Date(filter.atracacaoSaidaInicial).toYYYYMMDD}`;
      }

      if (filter.atracacaoSaidaFinal) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `atracacao/dataHoraSaida le ${new Date(filter.atracacaoSaidaFinal).toYYYYMMDD}`;
      }

      if (filter.clienteId) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `proposta/clienteId eq ${filter.clienteId}`;
      }

      if (filter.propostaId) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `proposta/id eq ${filter.propostaId}`;
      }

      if (filter.osNumero) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `equipamentos/any(o: o/sequencia eq ${filter.osNumero})`;
      }

      if (filter.embarcacaoId) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `embarcacaoId eq ${filter.embarcacaoId}`;
      }

      if (filter.situacaoId) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `situacaoId eq ${filter.situacaoId}`;
      }

      if (filter.responsavelId) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `proposta/responsavelId eq ${filter.responsavelId}`;
      }

      if (filter.portoId) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `portoId eq ${filter.portoId}`;
      }

      if (filter.localOperacaoId) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `atracacao/localOperacaoId eq ${filter.localOperacaoId}`;
      }

      if (filter.atracacao != null) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `hasAtracacao eq ${filter.atracacao}`;
      }

      if (paramTemp) {

        if (parametros) {
          parametros += '&';
        }
        else {
          parametros += '?';
        }

        parametros += `$filter=${paramTemp}`;
      }

    }

    return parametros;
  }

  ListarComFiltro(page: number, pageSize: number, sortBy: any[], desc: any[], search: any, columns: any[], filter: any, include?: string) {

    let parametros = this.GetParamentroPaginacaoOrdenacao(page, pageSize, sortBy, desc, search, columns);

    parametros = this.GetFiltro(parametros, filter);

    return http.get(`${this.GetNomeControle()}${parametros}`, this.GetHeader(include));
  }

  ConcluirManualmente(id: number){
    return http.post(`${this.GetNomeControle()}/${id}/ConcluirManualmente`);
  }

  public AutoComplete(q: string) {
    return http.get(`${this.GetNomeControle()}/AutoComplete`, {
      params: {
        q: q
      },
      headers: httpHeader.headers
    });
  }

  public Validar(usuarioId: number, registroOperacionalId: number) {

    return http.post(`${this.GetNomeControle()}/Validar`, null, {
      params: {
        usuarioId: usuarioId,
        registroOperacionalId: registroOperacionalId
      },
      headers: httpHeader.headers
    });
  }

  public EnviarROEmail(registro: RegistroOperacionalEnvioAprovacao) {
    return http.post(`${this.GetNomeControle()}/EnviarROEmail`, registro, {
      headers: httpHeader.headers
    });
  }

  public GetAvaliar(id: string, token: string) {
    return http.get(`${this.GetNomeControle()}/avaliar`, {
      params: {
        registroOperacionalId: id,
        token: token
      },
      headers: httpHeader.headers
    });
  }

  public ListarFuncionarios(id: number) {
    return http.get(`${this.GetNomeControle()}/${id}/Funcionarios`);
  }

  public Avaliar(id: string, token: string, justificativa: string, aprovado: boolean) {
    return http.post(`${this.GetNomeControle()}/avaliar`, null, {
      params: {
        registroOperacionalId: id,
        token: token,
        justificativa: justificativa,
        aprovado: aprovado
      },
      headers: httpHeader.headers
    });
  }

  public ListarEquipamentosSemPO(id?: number, fornecedorId?: number, equipamentoId?: number){
    return http.get(`${this.GetNomeControle()}/ListarEquipamentosSemPO`, {
      params: {
        id,
        fornecedorId,
        equipamentoId
      }
    });
  }

  // CONTROLE OS
  private GetFiltroOS(parametros: string, filter: any) {
    if (filter) {
      let paramTemp = '';

      if (filter.numeroOs) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `sequencia eq ${filter.numeroOs}`;
      }

      if (filter.numeroRo) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `registroOperacional/numero eq ${filter.numeroRo}`;
      }

      if (filter.portoId) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `registroOperacional/portoId eq ${filter.portoId}`;
      }

      if (filter.localOperacaoId) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `registroOperacional/atracacao/localOperacaoId eq ${filter.localOperacaoId}`;
      }

      if (filter.equipamentoId) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `equipamentoId eq ${filter.equipamentoId}`;
      }

      if (filter.fornecedorId) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `fornecedorId eq ${filter.fornecedorId}`;
      }

      if (paramTemp) {
        if (parametros)
          parametros += '&';        
        else
          parametros += '?';

        parametros += `$filter=${paramTemp}`;
      }
    }
    return parametros;
  }

  public Equipamentos(page: number, pageSize: number, sortBy: any[], desc: any[], search: any, columns: any[], filter: any) {
    
    let parametros = this.GetParamentroPaginacaoOrdenacao(page, pageSize, sortBy, desc, search, columns);
    
    parametros = this.GetFiltroOS(parametros, filter);

    return http.get(`${this.GetNomeControle()}/Equipamentos${parametros}`);
  }

  public Equipamento(id: number){
    return http.get(`${this.GetNomeControle()}/Equipamento/${id}`)
  }

  public EquipamentoSalvar(id:number, model: any){
    return http.patch(`${this.GetNomeControle()}/Equipamento/${id}`, model, this.GetHeader());
  }  
  //
  
  //RELATORIOS
  public RelatorioAtracacao(numero?: number, propostaId?: number, situacaoId?: number, atracacao?: boolean, clienteId?: number, responsavelId?: number, embarcacaoId?: number, portoId?: number, localOperacaoId?: number) {
    return http.get(`${this.GetNomeControle()}/RelatorioAtracacao`, {
      params: {
        numero,
        propostaId,
        situacaoId,
        atracacao,
        clienteId,
        responsavelId,
        embarcacaoId,
        portoId,
        localOperacaoId
      }
    })
  }

  public RelatorioTernos(numero?: number, propostaId?: number, situacaoId?: number, atracacao?: boolean, clienteId?: number, responsavelId?: number, embarcacaoId?: number, portoId?: number, localOperacaoId?: number) {
    return http.get(`${this.GetNomeControle()}/RelatorioTernos`, {
      params: {
        numero,
        propostaId,
        situacaoId,
        atracacao,
        clienteId,
        responsavelId,
        embarcacaoId,
        portoId,
        localOperacaoId
      }
    })
  }

  public RelatorioFainas(numero?: number, propostaId?: number, situacaoId?: number, atracacao?: boolean, clienteId?: number, responsavelId?: number, embarcacaoId?: number, portoId?: number, localOperacaoId?: number) {
    return http.get(`${this.GetNomeControle()}/RelatorioFainas`, {
      params: {
        numero,
        propostaId,
        situacaoId,
        atracacao,
        clienteId,
        responsavelId,
        embarcacaoId,
        portoId,
        localOperacaoId
      }
    })
  }

  public RelatorioEquipamentos(numero?: number, propostaId?: number, situacaoId?: number, atracacao?: boolean, clienteId?: number, responsavelId?: number, embarcacaoId?: number, portoId?: number, localOperacaoId?: number) {
    return http.get(`${this.GetNomeControle()}/RelatorioEquipamentos`, {
      params: {
        numero,
        propostaId,
        situacaoId,
        atracacao,
        clienteId,
        responsavelId,
        embarcacaoId,
        portoId,
        localOperacaoId
      }
    })
  }

  public ExcelAtracacoes(numero?: number, propostaId?: number, situacaoId?: number, atracacao?: boolean, clienteId?: number, responsavelId?: number, embarcacaoId?: number, portoId?: number, localOperacaoId?: number, atracacaoEntradaInicial?: string, atracacaoEntradaFinal?: string, atracacaoSaidaInicial?: string, atracacaoSaidaFinal?: string) {
    return http.get(`${this.GetNomeControle()}/ExcelAtracacao`, {
      params: {
        numero,
        propostaId,
        situacaoId,
        atracacao,
        clienteId,
        responsavelId,
        embarcacaoId,
        portoId,
        localOperacaoId,
        atracacaoEntradaInicial,
        atracacaoEntradaFinal,
        atracacaoSaidaInicial,
        atracacaoSaidaFinal,
      }
    })
  }

  public ExcelTernos(numero?: number, propostaId?: number, situacaoId?: number, atracacao?: boolean, clienteId?: number, responsavelId?: number, embarcacaoId?: number, portoId?: number, localOperacaoId?: number, atracacaoEntradaInicial?: string, atracacaoEntradaFinal?: string, atracacaoSaidaInicial?: string, atracacaoSaidaFinal?: string) {
    return http.get(`${this.GetNomeControle()}/ExcelTerno`, {
      params: {
        numero,
        propostaId,
        situacaoId,
        atracacao,
        clienteId,
        responsavelId,
        embarcacaoId,
        portoId,
        localOperacaoId,
        atracacaoEntradaInicial,
        atracacaoEntradaFinal,
        atracacaoSaidaInicial,
        atracacaoSaidaFinal
      }
    })
  }

  public ExcelFainas(numero?: number, propostaId?: number, situacaoId?: number, atracacao?: boolean, clienteId?: number, responsavelId?: number, embarcacaoId?: number, portoId?: number, localOperacaoId?: number, atracacaoEntradaInicial?: string, atracacaoEntradaFinal?: string, atracacaoSaidaInicial?: string, atracacaoSaidaFinal?: string) {
    return http.get(`${this.GetNomeControle()}/ExcelFaina`, {
      params: {
        numero,
        propostaId,
        situacaoId,
        atracacao,
        clienteId,
        responsavelId,
        embarcacaoId,
        portoId,
        localOperacaoId,
        atracacaoEntradaInicial,
        atracacaoEntradaFinal,
        atracacaoSaidaInicial,
        atracacaoSaidaFinal
      }
    })
  }

  public ExcelEquipamento(numero?: number, propostaId?: number, situacaoId?: number, atracacao?: boolean, clienteId?: number, responsavelId?: number, embarcacaoId?: number, portoId?: number, localOperacaoId?: number, atracacaoEntradaInicial?: string, atracacaoEntradaFinal?: string, atracacaoSaidaInicial?: string, atracacaoSaidaFinal?: string) {
    return http.get(`${this.GetNomeControle()}/ExcelEquipamento`, {
      params: {
        numero,
        propostaId,
        situacaoId,
        atracacao,
        clienteId,
        responsavelId,
        embarcacaoId,
        portoId,
        localOperacaoId,
        atracacaoEntradaInicial,
        atracacaoEntradaFinal,
        atracacaoSaidaInicial,
        atracacaoSaidaFinal
      }
    })
  }

  public ExcelRO(numero?: number, propostaId?: number, situacaoId?: number, atracacao?: boolean, clienteId?: number, responsavelId?: number, embarcacaoId?: number, portoId?: number, localOperacaoId?: number) {
    return http.get(`${this.GetNomeControle()}/ExcelRO`, {
      params: {
        numero,
        propostaId,
        situacaoId,
        atracacao,
        clienteId,
        responsavelId,
        embarcacaoId,
        portoId,
        localOperacaoId
      }
    })
  }
  //

  public BoletimMedicao(id: number){
    return http.get(`${this.GetNomeControle()}/${id}/BoletimMedicao`);
  }

  public AtualizarValor(id: number, equipamentoId: number, osId: number){
    return http.post(`${this.GetNomeControle()}/${id}/AtualizarValor/${equipamentoId}/${osId}`);
  }

  public AgendaFuncionario(roNumero?: number, funcionarioId?: number, clienteId?: number, embarcacaoId?: number){
    return http.get(`${this.GetNomeControle()}/AgendaFuncionario`, {
      params: {
        roNumero,
        funcionarioId,
        clienteId,
        embarcacaoId
      }
    });
  }

  //FAINA DOCUMENTO
  ListarFainaDocumento(fainaId: number){
    return http.get(`${this.GetNomeControle()}/fainaDocumento/${fainaId}`);
  }

  EnviarFainaDocumento(model: RegistroOperacionalFainaDocumento, arquivo: any){
    let bodyFormData = new FormData();
    bodyFormData.append("model", JSON.stringify(model));
    bodyFormData.append('arquivo', new Blob([arquivo], { type: 'application/pdf' }), model.documento.arquivo.name);

    return http.post(`${this.GetNomeControle()}/fainaDocumento`, bodyFormData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Accept': 'application/json'
      }
    });
  }

  DeletarFainaDocumento(documentoId: number){
    return http.delete(`${this.GetNomeControle()}/fainaDocumento/${documentoId}`);
  }

  //EQUIPAMENTO DOCUMENTO
  ListarEquipamentoDocumento(equipamentoId: number){
    return http.get(`${this.GetNomeControle()}/equipamentoDocumento/${equipamentoId}`);
  }

  EnviarEquipamentoDocumento(model: RegistroOperacionalEquipamentoDocumento, arquivo: any){
    let bodyFormData = new FormData();
    bodyFormData.append("model", JSON.stringify(model));
    bodyFormData.append('arquivo', new Blob([arquivo], { type: 'application/pdf' }), model.documento.arquivo.name);

    return http.post(`${this.GetNomeControle()}/equipamentoDocumento`, bodyFormData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Accept': 'application/json'
      }
    });
  }

  DeletarEquipamentoDocumento(documentoId: number){
    return http.delete(`${this.GetNomeControle()}/equipamentoDocumento/${documentoId}`);
  }
}