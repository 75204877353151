import Vue from 'vue';
import { colors } from 'vuetify/lib';
import Vuetify from 'vuetify/lib/framework';
import pt from 'vuetify/src/locale/pt';

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { pt },
    current: 'pt',
  },
  theme: {
    dark: false,
    default: 'light',
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#2b415f',
        secondary: '#95aed0',
        toolbar: '#ECEFF1',
        warning: '#F4BB44',
        widget: colors.blueGrey.lighten5,
        changed: colors.deepOrange.lighten4,
        roEnviada: '#FFF176',
        roAprovada: '#81C784',
        roReprovada: '#EB8F8F'
      },
      dark: {
        primary: '#2b415f',
        secondary: '#95aed0',
        toolbar: '#4f4f4f',
        warning: '#F4BB44',
        widget: colors.blueGrey.lighten5,
        changed: colors.deepOrange.lighten4,
        roEnviada: '#ccab33',
        roAprovada: '#1B5E20',
        roReprovada: '#430A0A'
      }
    },
  }
});
