


























































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import jiff from 'jiff';
import { EnderecoService, FornecedorService,  } from "@/core/services/geral";
import { Fornecedor, FornecedorEquipamentoServico } from "@/core/models/geral";
import { Equipamento } from "@/core/models/operacional";
import { AlertExcludeQuestion, AlertSimpleErr, AlertSimpleRes } from "@/core/services/shared/AlertService";


@Component
export default class CadastroFuncionario extends Vue {
  @Prop() private item!: Fornecedor;
  @Prop() private value!: string;

  itemOriginal!: Fornecedor;

  loading: boolean = false;
  service = new FornecedorService();
  valid = true;
  dialog = false;
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
  $refs!: {
    form: HTMLFormElement
  }

  tabActive: any = {};

  enderecoService = new EnderecoService();
  estados: any[] = [];
  municipios: any[] = [];

  dialogCadastroFornecedorEquipamentoServico: boolean = false;
  fornecedorEquipamentoServico: FornecedorEquipamentoServico = new FornecedorEquipamentoServico();
  equipamentoServicoHeaders: any[] = [
    { text: '',value:'actions' ,sortable: false },
    { text: 'Nome', value: 'equipamentoServico.nome' },
    { text: 'Período', value: 'periodo.nome' },
    { text: 'LocalOperação', value: 'localOperacao.nome' },
    { text: 'Valor', value: 'valor' }
  ];

  mskCnpj = process.env.VUE_APP_MSK_CNPJ;
  mskTelefone = process.env.VUE_APP_MSK_TELEFONE;
  mskCep = process.env.VUE_APP_MSK_CEP;

  @Watch("item.endereco.estadoId")
  onEstadoIdChanged(value: number) {
    this.CarregarMunicipio(value);
  } 

  @Watch('item')
  Item() {
    if (this.$refs.form) {
      this.$refs.form.resetValidation();
    }
  }

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;

    if (this.dialog){
      this.itemOriginal = jiff.clone(this.item);
    }
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  beforeUpdate(){
    if (!this.dialog){
      this.$emit('fechou');
    }
  }

  mounted() {
    this.enderecoService.ObterEstados().then(
      res => {
        this.estados = res.data.items;
      },
      err => AlertSimpleErr("Aviso!", err)
    );
  }

  CarregarMunicipio(estadoId: number){
    this.enderecoService.ObterMunicipios(estadoId).then(
      res => {
        this.municipios = res.data.items;
      },
      err => AlertSimpleErr("Aviso!", err)
    );
  }

  ObterEndereco(cep: string){
    this.enderecoService.ObterPorCep(cep).then(
      res => {
        const endereco = res.data;
        if (endereco) {
          this.item.endereco.logradouro = endereco.logradouro;
          this.item.endereco.bairro = endereco.bairro;
          this.item.endereco.estadoId = endereco.estadoId;
          this.item.endereco.municipioId = endereco.municipioId;
        }
      },
      err => AlertSimpleErr("Aviso!", err)
    );
  }

  DialogCadastroEquipamentoServico(){
    this.fornecedorEquipamentoServico = new FornecedorEquipamentoServico();
    this.fornecedorEquipamentoServico.fornecedorId = this.item.id;
    this.dialogCadastroFornecedorEquipamentoServico = true;
  }

  SalvarEquipamentoServico(){
    this.item.equipamentosServicos.push(this.fornecedorEquipamentoServico);
    this.dialogCadastroFornecedorEquipamentoServico = false;
  }

  ExcluirEquipamentoServico(item: FornecedorEquipamentoServico){
    const context = this;
    const excluir = function () {
      return new Promise( async function (resolve, reject){
        const index = context.item.equipamentosServicos.lastIndexOf(item);
        context.item.equipamentosServicos.splice(index, 1);
      });
    }
    AlertExcludeQuestion(excluir, true);
  }

  Salvar() {
    if (this.$refs.form.validate()) {
      this.loading = true;
      let pacthModel = jiff.diff(this.itemOriginal, this.item, false);
      (this.item.id > 0 ? this.service.Salvar(pacthModel, this.item.id) : this.service.Salvar(this.item)).then(
        res => {
          AlertSimpleRes("Aviso!", res);
          this.$emit("salvou");
          this.Close();
        },
        err => AlertSimpleErr("Aviso!", err)
      ).finally(() => {
        this.loading = false;
      });
    }
  }

  Close() {
    this.dialog = false;
  }
}
