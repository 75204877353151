import { Empresa } from "../geral";

export class TipoReceita implements Shared.IEntity {
    id: number = 0;
    empresaId: number = 0;
    empresa!: Empresa;
    nome: string = "";

    constructor(model?: TipoReceita) {
        if (!model)
            return

        this.id = model.id;
        this.empresaId = model.empresaId;
        this.empresa = model.empresa;
        this.nome = model.nome;
    }
}