import { Endereco } from './Endereco';

export class Pessoa implements Shared.IEntity{
    id: number = 0;
    nome: string = '';
    telefone1: string = '';
    telefone2: string = '';
    endereco: Endereco = new Endereco();
    email: string = '';
    ativo: boolean = true;

    constructor(model?: Pessoa){
        if(!model)
            return;
        this.id = model.id;
        this.nome = model.nome;
        this.telefone1 = model.telefone1;
        this.telefone2 = model.telefone2;
        this.endereco = model.endereco;
        this.email = model.email;
        this.ativo = model.ativo;
    }
}

export class PessoaJuridica extends Pessoa{
    cnpj: string = '';
    nomeFantasia: string = '';
    inscricaoEstadual: string = '';
    inscricaoMunicipal: string = '';

    constructor(model?: PessoaJuridica){
        super(model);
        
        if(!model)
            return;

        this.cnpj = model.cnpj;
        this.nomeFantasia = model.nomeFantasia;
        this.inscricaoEstadual = model.inscricaoEstadual;
        this.inscricaoMunicipal = model.inscricaoMunicipal;
    }
}

export class PessoaFisica extends Pessoa{
    cpf: string = '';
    identidade: string = '';

    constructor(model?: PessoaFisica){
        super(model);

        if(!model)
            return;
            
        this.cpf = model.cpf;
        this.identidade = model.identidade;
    }
}