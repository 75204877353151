import { Agendamento } from "./Agendamento";
import { Equipamento } from "./Equipamento";

export class AgendamentoEquipamento {
    
    agendamentoId: number = 0;
    agendamento!: Agendamento;
    equipamentoId: number = 0;
    equipamento!: Equipamento;
    quantidade: number = 1;
    dataHoraDesejada: string = '';
    dataHoraTerminoPrevista: string = '';
    observacao: string = '';

    atendido: boolean = false;

    constructor(model?: AgendamentoEquipamento) {
        
        if (!model)
            return;

        this.agendamentoId = model.agendamentoId;
        this.agendamento = model.agendamento;
        this.equipamentoId = model.equipamentoId;
        this.equipamento = model.equipamento;
        this.quantidade = model.quantidade;
        this.dataHoraDesejada = model.dataHoraDesejada;
        this.dataHoraTerminoPrevista = model.dataHoraTerminoPrevista;
        this.observacao = model.observacao;
    }
}