import {http} from '../../ApiConfig';
import { Service } from '../shared/';

export class ParametroService extends Service{

  constructor(){
    super('parametro');
  }

  public GetParametroPelaEmpresa(id: number){
    return http.get(`${this.GetNomeControle()}/empresa/${id}`);
  }
  
}