import { TipoEmbarcacao } from './TipoEmbarcacao';
import { Empresa } from "../geral";

export class Embarcacao implements Shared.IEntity{

    id: number = 0;
    empresaId: number = 0;
    empresa!: Empresa;
    nome: string = '';
    tipoId: number = 0;
    tipo!: TipoEmbarcacao;
    dimensao: string = '';
    bandeira: string = '';
    dwt: string = '';
    imo: string = '';
    porto: string = '';
    foto: any = null;
    link: string = '';

    constructor(model?: Embarcacao){

        if(!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;
        this.nome = model.nome;
        this.tipoId = model.tipoId;
        this.dimensao = model.dimensao;
        this.bandeira = model.bandeira;
        this.dwt = model.dwt;
        this.imo = model.imo;
        this.porto = model.porto;
        this.foto = model.foto;
        this.link = model.link;
    }
}