
































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import jiff from 'jiff';
import { Cliente, Contato, Empresa } from "@/core/models/geral";
import { ClienteService, ContatoService } from "@/core/services/geral";
import { ContatoCliente } from "@/core/models/geral/ContatoCliente";
import { ContatoRepresentante } from "@/core/models/geral/ContatoRepresentante";
import { AlertSimpleErr, AlertSimpleHtmlRes } from "@/core/services/shared/AlertService";


@Component
export default class CadastroContato extends Vue {
  @Prop() private item!: Contato;
  @Prop() private value!: string;

  itemOriginal!: Contato;

  loading: boolean = false;
  service = new ContatoService();
  valid = true;
  dialog = false;
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
  $refs!: {
    form: HTMLFormElement
  }
  mskTelefone = process.env.VUE_APP_MSK_TELEFONE;

  clienteService = new ClienteService();
  clientes: Cliente[] = [];
  selectedEmpresas: Cliente[] = [];
  selectedRepresentantes: Cliente[] = [];

  @Watch('item')
  Item() {

    this.selectedEmpresas = [];

    this.item.clientes.forEach( x=> {
      this.selectedEmpresas.push(x.cliente);
    });

    this.selectedRepresentantes = [];
    this.item.representantes.forEach( x=> {
      this.selectedRepresentantes.push(x.representante);
    });

    if (this.$refs.form) {
      this.$refs.form.resetValidation();
    }
  }

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;

    if (this.dialog){
      this.itemOriginal = jiff.clone(this.item);
    }
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  beforeUpdate(){
    if (!this.dialog){
        this.$emit('fechou');
    }
  }

  mounted() {

    this.clienteService.ListarTudo("","nome").then(
      res=>{
        this.clientes = res.data.items;        
      },
      err => AlertSimpleErr("Aviso!", err)
    )
  }

  SalvarRepresentante(){
    var representanteUsuario: Cliente[] = [];
    var representanteSelect: Cliente[] = [];

    this.item.representantes.forEach(x => {
      representanteUsuario.push(x.representante);
    });

    this.selectedRepresentantes.forEach(x => {
      representanteSelect.push(x);
    });

    if(JSON.stringify(representanteUsuario) != JSON.stringify(representanteSelect)){
      
      let testeA: Cliente[] = [];
      testeA = representanteSelect.filter(w => !representanteUsuario.includes(w));

      let testeB: Cliente[] = [];
      testeB = representanteUsuario.filter(w => !representanteSelect.includes(w));

      if(testeA){ //RESULTADOS GRAVADOS AO USUARIO

        testeA.forEach(x =>{

          var carinha = new ContatoRepresentante();
          carinha.contatoId = this.item.id;
          carinha.representanteId = x.id;

          this.item.representantes.push(carinha);
        });

      }

      if(testeB){ //RESULTADOR RETIRADOS DO USUARIO

        testeB.forEach(x =>{
          
          const carinha = this.item.representantes.find(y => y.representanteId == x.id)
          const index = this.item.representantes.indexOf(carinha!);

          this.item.representantes.splice(index,1);
          
        });

      }

    }
  }

  SalvarCliente(){

    var empresaUsuario: Cliente[] = [];
    var empresaSelect: Cliente[] = [];

    this.item.clientes.forEach(x => {
      empresaUsuario.push(x.cliente);
    });

    this.selectedEmpresas.forEach(x => {
      empresaSelect.push(x);
    });

    if(JSON.stringify(empresaUsuario) != JSON.stringify(empresaSelect)){
      
      let testeA: Cliente[] = [];
      testeA = empresaSelect.filter(w => !empresaUsuario.includes(w));

      let testeB: Cliente[] = [];
      testeB = empresaUsuario.filter(w => !empresaSelect.includes(w));

      if(testeA){ //RESULTADOS GRAVADOS AO USUARIO

        testeA.forEach(x =>{

          var carinha = new ContatoCliente();
          carinha.contatoId = this.item.id;
          carinha.clienteId = x.id;

          this.item.clientes.push(carinha);

        });

      }

      if(testeB){ //RESULTADOR RETIRADOS DO USUARIO

        testeB.forEach(x =>{
          
          const carinha = this.item.clientes.find(y => y.clienteId == x.id)
          const index = this.item.clientes.indexOf(carinha!);

          this.item.clientes.splice(index,1);
          
        });

      }

    }

  }

  Salvar() {
    if (this.$refs.form.validate()) {
      this.SalvarCliente();
      this.SalvarRepresentante();
      this.loading = true;
      let pacthModel = jiff.diff(this.itemOriginal, this.item, false);
      (this.item.id > 0 ? this.service.Salvar(pacthModel, this.item.id) : this.service.Salvar(this.item)).then(
        res => {
          AlertSimpleHtmlRes("Aviso!", res);
          this.$emit("salvou");
          this.Close();
        },
        err => AlertSimpleErr("Aviso!", err)
      ).finally(() => {
        this.loading = false;
      });
    }
  }

  Close() {
    this.dialog = false;
  }
}
