import { Empresa, TipoServicoEquipamento } from "../geral";
import { Categoria } from "./Categoria";

export class Equipamento implements Shared.IEntity {

    id: number = 0;
    empresaId: number = 0;
    empresa!: Empresa;
    nome: string = '';
    nomeIngles: string = '';
    categoriaId?: number;
    categoria!: Categoria;
    tipoServicoEquipamentoId?: number;
    tipoServicoEquipamento!: TipoServicoEquipamento;

    constructor(model?: Equipamento) {

        if (!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;
        this.nome = model.nome;
        this.nomeIngles = model.nomeIngles;
        this.categoriaId = model.categoriaId;
        this.categoria = model.categoria;
        this.tipoServicoEquipamentoId = model.tipoServicoEquipamentoId;
        this.tipoServicoEquipamento = model.tipoServicoEquipamento;
    }
}