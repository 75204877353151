import { http, httpHeader } from "@/core/ApiConfig";
import { Service } from "../shared";

export class CabecoService extends Service {
    constructor() {
        super('cabeco');
    }

    public AutoComplete(q: string) {
        return http.get(`${this.GetNomeControle()}/AutoComplete`, {
            params: {
                q: q
            },
            headers: httpHeader.headers
        });
    }

    public ByPorto(portoId: number) {
        return http.get(`${this.GetNomeControle()}/Porto/${portoId}`)
    }
}