


























































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import jiff from 'jiff';
import { Cliente, TipoCliente } from "@/core/models/geral";
import { ClienteService, EnderecoService, TipoClienteService } from "@/core/services/geral";
import { AlertSimpleErr, AlertSimpleRes } from "@/core/services/shared/AlertService";


@Component
export default class CadastroCliente extends Vue {
  @Prop() private item!: Cliente;
  @Prop() private value!: string;

  itemOriginal!: Cliente;
  loading: boolean = false;
  service = new ClienteService();
  valid = true;
  dialog = false;
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
  $refs!: {
    form: HTMLFormElement
  }
  enderecoService = new EnderecoService();
  estados: any[] = [];
  municipios: any[] = [];

  tipos: TipoCliente[] = [];
  tipoClienteService = new TipoClienteService();

  mskCnpj = process.env.VUE_APP_MSK_CNPJ;
  mskTelefone = process.env.VUE_APP_MSK_TELEFONE;
  mskCep = process.env.VUE_APP_MSK_CEP;

  @Watch("item.endereco.estadoId")
  onEstadoIdChanged(value: number) {
    this.CarregarMunicipio(value);
  } 

  @Watch('item')
  Item() {
    if (this.$refs.form) {
      this.$refs.form.resetValidation();
    }
  }

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;

    if (this.dialog){
      this.itemOriginal = jiff.clone(this.item);
    }
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  beforeUpdate(){
    if (!this.dialog){
        this.$emit('fechou');
    }
  }

  mounted() {
    this.enderecoService.ObterEstados().then(
      res => {
        this.estados = res.data.items;
      },
      err => AlertSimpleErr("Aviso!", err)        
    );
    this.tipoClienteService.ListarTudo().then(
      res=>{
        this.tipos = res.data.items;
      },
      err => AlertSimpleErr("Aviso!", err)
    );
  }

  CarregarMunicipio(estadoId: number){
    if(estadoId == 99){
      this.item.cnpj = "";
      this.item.endereco.cep = "";
    }
    this.enderecoService.ObterMunicipios(estadoId).then(
      res => {
        this.municipios = res.data.items;
      },
      err => AlertSimpleErr("Aviso!", err)
    );
  }

  ObterEndereco(cep: string){
    this.enderecoService.ObterPorCep(cep).then(
      res => {
        const endereco = res.data;
        if (endereco) {
          this.item.endereco.logradouro = endereco.logradouro;
          this.item.endereco.bairro = endereco.bairro;
          this.item.endereco.estadoId = endereco.estadoId;
          this.item.endereco.municipioId = endereco.municipioId;
        }
      },
      err => AlertSimpleErr("Aviso!", err)
    );
  }

  Salvar() {
    if (this.$refs.form.validate()) {
      this.loading = true;
      let pacthModel = jiff.diff(this.itemOriginal, this.item, false);
      (this.item.id > 0 ? this.service.Salvar(pacthModel, this.item.id) : this.service.Salvar(this.item)).then(
        res => {
          AlertSimpleRes("Aviso!", res);
          this.$emit("salvou");
          this.Close();
        },
        err => AlertSimpleErr("Aviso!", err)
      ).finally(() => {
        this.loading = false;
      });
    }
  }

  Close() {
    this.dialog = false;
  }
}
