






































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import jiff from 'jiff';
import { Receita, TipoReceita } from "@/core/models/financeiro";
import { ReceitaService, TipoReceitaService } from "@/core/services/financeiro";
import { Equipamento, RegistroOperacional } from "@/core/models/operacional";
import { EquipamentoService, RegistroOperacionalService } from "@/core/services/operacional";
import { Cliente } from "@/core/models/geral";
import { ClienteService } from "@/core/services/geral";
import { Categoria } from "@/core/models/operacional/Categoria";
import { CategoriaService } from "@/core/services/operacional/CategoriaService";
import { AlertSimpleErr, AlertSimpleRes } from "@/core/services/shared/AlertService";


@Component
export default class CadastroReceita extends Vue {
  @Prop() private value!: string;
  @Prop() item!: Receita;

  itemOriginal!: Receita;

  loading: boolean = false;
  service = new ReceitaService();
  valid = true;
  dialog = false;
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
  $refs!: {
    form: HTMLFormElement
  }

  categorias: Categoria[] = [];
  categoriaService = new CategoriaService();

  equipamentoServicoService = new EquipamentoService()
  equipamentoServicos: Equipamento[] = [];
  isEquipamentoServicoLoading: boolean = false;
  onSearchEquipamentoServico: any = null;  
  
  tipoReceitas: TipoReceita[] = [];
  tipoService = new TipoReceitaService();
  isTipoLoading: boolean = false;
  onSearchTipo: any = null;

  registroOperacoes: RegistroOperacional[] = [];
  roService: RegistroOperacionalService = new RegistroOperacionalService();
  isROLoading: boolean = false;
  onSearchRO: any = null;
  
  ClienteService = new ClienteService();
  clientes: Cliente[] = [];
  isClienteLoading: boolean = false;
  onSearchCliente: any = null;

  imposto: number = 0;
  valorUnitario: number = 0;
  quantidade: number = 0;
  total: number = 0;
  totalGeral: number = 0;

  @Watch('item')
  Item() {
    if (this.$refs.form) {
      this.$refs.form.resetValidation();
    }
    if(this.item.id > 0 && this.item.registroOperacionalId > 0){
      this.registroOperacoes = [];
      this.registroOperacoes.push(this.item.registroOperacional);
    }
    if(this.item.id > 0 && this.item.clienteId > 0){
      this.clientes = [];
      this.clientes.push(this.item.cliente);
    }
    if (this.item.id > 0 && this.item.tipoId > 0){
      this.tipoReceitas = [];
      this.tipoReceitas.push(this.item.tipo)
    }
    if(this.item.id > 0 && this.item.equipamentoServicoId! > 0){
      this.equipamentoServicos = [];
      this.equipamentoServicos.push(this.item.equipamentoServico);
    }
  }

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;

    if (this.dialog){
      this.itemOriginal = jiff.clone(this.item);
    }
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  @Watch('item.valorUnitario')
  @Watch('item.imposto')
  @Watch('item.quantidade')
  Valores(){
    this.quantidade = this.item.quantidade;
    this.valorUnitario = this.item.valorUnitario;
    this.imposto = this.item.imposto;

    this.total = this.quantidade * this.valorUnitario;
    this.imposto = this.total * (this.imposto / 100);
    this.totalGeral = this.total + this.imposto;
  }


  @Watch('onSearchCliente')
  SearchCliente (val: string) {
    if (this.isClienteLoading) return;
    if(this.item.clienteId) return;
    if (!val) return;
    this.isClienteLoading = true
    this.ClienteService.AutoComplete(val).then(
      res => {
        this.clientes = res.data;
      },
      err => AlertSimpleErr("Aviso!", err)
    )
    .finally(() => (this.isClienteLoading = false));
  }

  @Watch('onSearchEquipamentoServico')
  SearchEquipamentoServico(val:string){
    if(this.isEquipamentoServicoLoading)return;
    if(this.item.equipamentoServicoId)return;
    if(!val) return;
    this.isEquipamentoServicoLoading = true;
    this.equipamentoServicoService.AutoComplete(val).then(
      res => {
        this.equipamentoServicos = res.data;
      },
      err => AlertSimpleErr("Aviso!", err)
    ).finally(() => (this.isEquipamentoServicoLoading = false));
  }

  @Watch('onSearchRO')
  SearchRO (val: string) {
    if (this.isROLoading) return;
    if(this.item.registroOperacionalId) return;
    if (!val) return;
    this.isROLoading = true
    this.roService.AutoComplete(val).then(
      res => {
        this.registroOperacoes = res.data;
      },
      err => AlertSimpleErr("Aviso!", err)
    ).finally(() => (this.isROLoading = false));
  }

  beforeUpdate(){
    if (!this.dialog){
      this.$emit('fechou');
    }
  }  

  mounted() {
    this.tipoService.ListarTudo().then(
      res => {
        this.tipoReceitas = res.data.items;
      },
      err => AlertSimpleErr("Aviso!", err)
    )    
  }

  Salvar() {
    if (this.$refs.form.validate()) {
      this.loading = true;
      let pacthModel = jiff.diff(this.itemOriginal, this.item, false);
      (this.item.id > 0 ? this.service.Salvar(pacthModel, this.item.id) : this.service.Salvar(this.item)).then(
        res => {
          AlertSimpleRes("Aviso!", res);
          this.$emit("salvou");
          this.Close();
        },
        err => AlertSimpleErr("Aviso!", err)
      ).finally(() => {
        this.loading = false;
      });
    }
  }

  Close() {
    this.dialog = false;
  }
}
