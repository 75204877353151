import { http, httpHeader } from '@/core/ApiConfig';
import { Service } from '../shared';

export class FornecedorService extends Service{

  constructor(){
    super('fornecedor');
  }
  
  public AutoComplete(q: string) {
    return http.get(`${this.GetNomeControle()}/AutoComplete`, {
      params: {
        q: q
      },
      headers: httpHeader.headers
    });
  }

  public RelatorioServicoEquipamento(id?: number, equipamentoId?: number, periodoId?: number) {
    return http.get(`${this.GetNomeControle()}/RelatorioEquipamentoServico`, {
      params: {
        id,
        equipamentoId,
        periodoId
      },
      headers: httpHeader.headers
    });
  }
}