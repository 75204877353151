import { RelatorioMedicaoItem } from ".";
import { Empresa } from "../geral";
import { RegistroOperacional } from "./RegistroOperacional";

export class RelatorioMedicao implements Shared.IEntity {

    id: number = 0;
    empresaId: number = 0;
    empresa!: Empresa;
    numero: number = 0;
    dataReferencia: string = "";
    dataHoraCriacao: string = "";
    registroOperacionalId: number = 0;
    registroOperacional!: RegistroOperacional;
    observacao: string = "";

    itens: RelatorioMedicaoItem[] = [];

    constructor(model?: RelatorioMedicao) {

        if (!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;
        this.numero = model.numero;
        this.dataReferencia = model.dataReferencia;
        this.dataHoraCriacao = model.dataHoraCriacao;
        this.registroOperacionalId = model.registroOperacionalId;
        this.registroOperacional = model.registroOperacional;
        this.observacao = model.observacao;

        this.itens = model.itens;
    }
}