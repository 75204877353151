
































import { Component, Prop, Watch } from "vue-property-decorator";
import { PageBase } from '@/core/models/shared';
import { Funcao, Funcionario } from "@/core/models/geral";
import { FuncaoService, FuncionarioService } from "@/core/services/geral";
import { RegistroOperacionalFuncionario } from "@/core/models/operacional";

@Component
export default class CadastroRegistroOperacionalFuncionario extends PageBase{
    @Prop() private item!: RegistroOperacionalFuncionario;    
    @Prop() private value!: string;

    valid = true;
    dialog = false;

    fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

    $refs!: {
        form: HTMLFormElement
    }

    funcoes: Funcao[] = [];
    funcaoService: FuncaoService = new FuncaoService();

    funcionarios: Funcionario[] = [];
    funcionarioService: FuncionarioService = new FuncionarioService();
    
    Salvar(){
        if (this.$refs.form.validate()) {
            this.$emit("salvou");
            this.dialog = false;
        }
    }    

    @Watch('item')
    Item(){
        if(this.$refs.form){
            this.$refs.form.resetValidation();
        }
    }

    @Watch("value")
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (this.dialog) {
            this.Carregar();
        }
        else{            
            this.$emit("fechou");
        }
    }

    @Watch("item.funcionarioId")
    WatchFuncionario(){
        if(this.item.funcionarioId > 0){
            this.item.funcionario = this.funcionarios.find(x => x.id == this.item.funcionarioId)!;
            
            if(this.item.id == 0){
                this.item.funcaoId = this.item.funcionario.funcaoId!;                          
            }
        }
    }

    @Watch("item.funcaoId")
    WatchFuncao(){
        if(this.item.funcaoId > 0){
            this.item.funcao = this.funcoes.find(x => x.id == this.item.funcaoId)!;
        }
    }

    beforeUpdate() {
        if(!this.dialog){
            this.$emit("fechou");
        }
    }

    Carregar(){
        this.funcaoService.ListarTudo().then(
            res => {
                this.funcoes = res.data.items;
            }
        )
        this.funcionarioService.ListarTudo().then(
            res => {
                this.funcionarios = res.data.items;
            }
        )
    }

    Close() {
        this.dialog = false;
        this.$emit("fechou");
    }

}   

