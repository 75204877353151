import { Documento } from "../geral";
import { Agendamento } from "./Agendamento";

export class AgendamentoDocumento {
    
    id: number = 0;
    agendamentoId: number = 0;
    agendamento!: Agendamento;
    documentoId: number = 0;
    documento: Documento = new Documento();
    excluir: boolean = false;

    constructor(model?: AgendamentoDocumento) {
        
        if (!model)
            return;
            
        this.id = model.id;
        this.agendamentoId = model.agendamentoId;
        this.agendamento = model.agendamento;
        this.documentoId = model.documentoId;
        this.documento = model.documento;
    }
}