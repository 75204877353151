


































































































































































































































































































































































































































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import jiff from 'jiff';
import { Agendamento, AgendamentoEquipamento, Cabeco, Embarcacao, Equipamento, PortoTerno, Proposta, RegistroOperacional, RegistroOperacionalCabeco, RegistroOperacionalEquipamento, RegistroOperacionalEquipamentoDescritivo, RegistroOperacionalFaina, RegistroOperacionalFuncionario, RegistroOperacionalTerno, Terno } from "@/core/models/operacional";
import { AgendamentoService, CabecoService, EmbarcacaoService, PropostaService, RegistroOperacionalService } from "@/core/services/operacional";
import { PortoService } from "@/core/services/geral";
import { Porto } from "@/core/models/geral";
import { PageBase } from "@/core/models/shared";
import { EnumTipoUsuario } from "@/core/models/shared/Enumerados";
import { AlertExcludeQuestion, AlertSimple, AlertSimpleErr, AlertSimpleRes } from "@/core/services/shared/AlertService";

@Component
export default class CadastroRegistroOperacional extends PageBase {
  @Prop() private item!: RegistroOperacional;
  @Prop() private value!: string;

  itemOriginal!: RegistroOperacional;  

  tipoUsuario = EnumTipoUsuario;
  
  loading: boolean = false;
  service = new RegistroOperacionalService();
  valid = true;
  dialog = false;
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
  $refs!: {
    form: HTMLFormElement
  }

  tabActive: any = {};

  embarcacaoService = new EmbarcacaoService();
  embarcacoes: Embarcacao[] = [];
  isEmbarcacaoLoading: boolean = false;
  onSearchEmbarcacao: any = null;

  portoService = new PortoService();
  portos: Porto[] = [];
  onSearchPorto: any = null;
  isPortoLoading: boolean = false;

  cabecos : Cabeco[]= [];
  cabecoService = new CabecoService();
  isCabecoLoading: boolean = false;

  propostaHoras: string = '';
  propostaService = new PropostaService();
  propostas: Proposta[] = [];
  onSearchProposta: any = null;
  isPropostaLoading: boolean = false;

  dialogCabeco: boolean = false;
  editaCabeco: boolean = false;
  registroOperacionalCabeco: RegistroOperacionalCabeco = new RegistroOperacionalCabeco();

  dialogFuncionario: boolean = false;
  editaFuncionario: boolean = false;
  registroOperacionalFuncionario: RegistroOperacionalFuncionario = new RegistroOperacionalFuncionario();

  dialogTerno: boolean = false;
  editaTerno: boolean = false;
  registroOperacionalTerno: RegistroOperacionalTerno = new RegistroOperacionalTerno();

  dialogFaina: boolean = false;
  editaFaina: boolean = false;
  registroOperacionalFaina: RegistroOperacionalFaina = new RegistroOperacionalFaina();

  dialogEquipamento: boolean = false;
  editaEquipamento: boolean = false;
  registroOperacionalEquipamento: RegistroOperacionalEquipamento = new RegistroOperacionalEquipamento();
  equipamentos: Equipamento[] = [];
  avulso: boolean = false;

  dialogEquipamentoDescritivo: boolean = false;
  editaEquipamentoDescritivo: boolean = false;
  registroOperacionalEquipamentoDescritivo: RegistroOperacionalEquipamentoDescritivo = new RegistroOperacionalEquipamentoDescritivo();

  isAgendamentoLoading: boolean = false;
  onSearchAgendamento: any = null;
  agendamentos: Agendamento[] = [];
  agendamentoService: AgendamentoService = new AgendamentoService();
  agendamento: Agendamento = new Agendamento();

  equipamentoAgendamentoDialog: boolean = false;
  equipamentosAgendamento: AgendamentoEquipamento[] = [];
  equipamentoAgendamentoSelecionado: AgendamentoEquipamento = new AgendamentoEquipamento();

  locaisOperacao : any [] = [];  

  headerCabeco: any[] = [
    { text: '',value:'actions' ,sortable: false },
    { text: 'Cabeço', value: 'cabeco.nome' },
    { text: 'Operador', value:'cabeco.operador.nome'}
  ];

  headerFuncionario: any[] = [
    { text: '',value:'actions' ,sortable: false },
    { text: 'Funcionário', value: 'funcionario.nome' },
    { text: 'Função', value:'funcao.nome'}
  ];

  headerTerno: any[] = [
    { text: '',value:'actions' ,sortable: false },
    { text: 'Data', value: 'data' },
    { text: 'Terno', value: 'terno' },
    { text: 'Fim Semana / Feriado', value: 'fimSemanaFeriado'}
  ];

  headerFaina: any[] = [
    { text: '',value:'actions' ,sortable: false },
    { text: 'Nome', value: 'faina.nome' },
    { text: 'Quantidade', value: 'quantidade' },
    { text: 'Volume', value: 'volume' },
    { text: 'Unidade Medida', value: 'unidadeMedida.sigla' },
    { text: 'Data/Hora Inicio', value: 'dataHoraInicio' },
    { text: 'Data/Hora Término', value: 'dataHoraTermino' }
  ];

  headerEquipamento: any[] = [
    { text: '',value:'actions' ,sortable: false },
    { text: '#', value: 'sequencia' },
    { text: 'Equipamento/Serviço', value: 'equipamento.nome' },
    { text: 'Fornecedor', value: 'fornecedor.nome' },
    { text: 'Quantidade', value: 'quantidade' },
    { text: 'Data/Hora Inicio', value: 'dataHoraInicio' },
    { text: 'Data/Hora Término', value: 'dataHoraTermino' },
    // { text: 'Gerar Cotação', value: 'gerarCotacao' },
    // { text: 'Gerar Pedido Compra', value: 'gerarPedidoCompra' },
    { text: 'Observação', value: 'observacao' }
  ];

  headerEquipamentoDescritivo: any[] = [
    { text: '',value:'actions' ,sortable: false },
    { text: 'Equipamento/Serviço', value: 'equipamento.nome' },
    { text: 'Quantidade', value: 'quantidade' },
    { text: 'Valor (Unitário)', value: 'valorUnitario' },
    { text: 'Data/Hora Inicio', value: 'dataHoraInicio' },
    { text: 'Data/Hora Término', value: 'dataHoraTermino' },
    { text: 'Observação', value: 'observacao' }
  ]; 

  agendamentoEquipamentoHeader: any[] = [
    { text: '', value: 'actions' },
    { text: 'Equipamento/Serviço', value: 'equipamento.nome' },
    { text: 'Quantidade', value: 'quantidade' },
    { text: 'Data/Hora Desejada', value: 'dataHoraDesejada' },
    { text: 'Data/Hora Término (Prevista)', value: 'dataHoraTerminoPrevista' }
  ];

  situacaoRegistroOperacionalEquipamentoClass(item:any){
    if(!item.verificado)
      return 'warning';
    else if(item.alterado)
      return 'changed';
    else
      return '';    
  }

  situacaoAgendamentoEquipamentoClass(item: any){
    if(item.atendido)
      return 'success';
    else
      return 'warning';
  }

  @Watch('item')
  Item() {
    if (this.$refs.form) {
      this.$refs.form.resetValidation();
    }
    if(this.item.id > 0){
      if(this.item.agendamentoId){
        this.agendamentos = [];
        this.agendamentoService.ObterPorId(this.item.agendamentoId).then(
          res => {
            this.agendamentos.push(new Agendamento(res.data));
          },
          err => AlertSimpleErr("Aviso!", err)
        )
      }
      if(this.item.atracacao.localOperacaoId){
        this.locaisOperacao = [];
        this.locaisOperacao.push(this.item.atracacao.localOperacao);
      }
      if(this.item.embarcacaoId){
        this.embarcacoes = [];
        this.embarcacaoService.ObterPorId(this.item.embarcacaoId).then(
          res => {
            let embarcacao = new Embarcacao(res.data);
            this.embarcacoes.push(embarcacao);
          },
          err => AlertSimpleErr("Aviso!", err)
        )
      }
      if(this.item.portoId){
        this.portoService.ObterPorId(this.item.portoId).then(
          res => {
            this.portos.push(new Porto(res.data));
          }
        )
      }
    }   
  }

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;
    if (this.dialog){
      this.itemOriginal = jiff.clone(this.item);
    }
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  mounted() {
    if(this.app.perfilId == this.tipoUsuario.Administrador || this.app.perfilId == this.tipoUsuario.Financeiro || this.app.perfilId == this.tipoUsuario.Comercial){
      this.headerEquipamento.push(
        { text: 'Desconto', value: 'desconto' }
      )
      this.headerTerno.push(
        { text: 'Desconto', value: 'desconto' }
      )
    }
  }

  @Watch("item.propostaId")
  WatchProposta(){
    if(this.item.propostaId > 0){
      this.propostaService.ObterPorId(this.item.propostaId, "Cliente, Porto, LocalOperacao, Ternos, Equipamentos.Equipamento").then(
        res => {
          var proposta = new Proposta(res.data);
          if(proposta){
            if(!this.item.portoId && proposta.portoId){
              this.item.portoId = proposta.portoId;
              this.portos.push(proposta.porto);
            }
            if(!this.item.atracacao.localOperacaoId && proposta.localOperacaoId){
              this.item.atracacao.localOperacaoId = proposta.localOperacaoId;
              this.locaisOperacao.push(proposta.localOperacao);
            }
            proposta.equipamentos.forEach(equipamento => {
              this.equipamentos.push(equipamento.equipamento);
            });
            
            this.propostaHoras = proposta.ternos.map(x => x.horas).join(',');

            this.propostas.push(proposta);
          }
        },
        err=> AlertSimpleErr("Aviso!", err)
      )      
    }    
  }

  @Watch("item.portoId")
  PortoTernos(){

    if(!this.item.portoId)
      return;

    this.portoService.ObterPorId(this.item.portoId, "Ternos.Terno, LocaisOperacao").then(
      res=>{
        var porto = new Porto(res.data);
        this.locaisOperacao = porto.locaisOperacao.filter(x => x.ativo == true);
      },
      err => AlertSimpleErr("Aviso!", err)
    )

    // this.cabecoService.ByPorto(this.item.portoId).then(
    //   res =>{
    //     this.cabecos= res.data;
    //   },
    //   err=> AlertSimpleErr("Aviso!", err)
    // )
  }

  @Watch("item.agendamentoId")
  WatchAgendamento(){
    if(this.item.agendamentoId && this.item.id == 0){
      this.agendamentoService.ObterPorId(this.item.agendamentoId, "Porto, Embarcacao, Equipamentos.Equipamento").then(
        res => {
          this.agendamento = res.data;
          this.item.portoId = this.agendamento.portoId;
          this.portos.push(this.agendamento.porto);
          this.item.hasAtracacao = this.agendamento.atracacao;
          this.item.atracacao.dataHoraEntrada = this.agendamento.dataHoraEntrada;
          if(this.agendamento.embarcacaoId){
            this.item.embarcacaoId = this.agendamento.embarcacaoId;
            this.embarcacoes.push(this.agendamento.embarcacao);
          }
          this.PopularEquipamentos();
        },
        err => AlertSimpleErr("Aviso!", err)
      )
    }
  }

  @Watch('onSearchEmbarcacao')
  SearchEmbarcacao (val: string) {
    if (this.isEmbarcacaoLoading) return;
    if (this.item.embarcacaoId) return;
    if (!val) return;
    this.isEmbarcacaoLoading = true
    this.embarcacaoService.AutoComplete(val).then(
      res => {
        this.embarcacoes = res.data;
      },
      err => AlertSimpleErr("Aviso!", err)
    ).finally(() => {
      this.isEmbarcacaoLoading = false
    });
    
  }

  @Watch('onSearchPorto')
  SearchResponsavel (val: string) {
    if (this.isPortoLoading) return;
    if (this.item.portoId) return;
    if (!val) return;
    this.isPortoLoading = true
    this.portoService.AutoComplete(val).then(
      res => {
        this.portos = res.data;
      },
      err => AlertSimpleErr("Aviso!", err)
    ).finally(() => {
      this.isPortoLoading = false
    });
  }

  @Watch('onSearchProposta')
  SearchProposta (val: string) {
    if (this.isPropostaLoading) return;
    if (this.item.propostaId) return;
    if (!val) return;
    this.isPropostaLoading = true
    this.propostaService.AutoComplete(val).then(
      res => {
        this.propostas = res.data;
      },
      err => AlertSimpleErr("Aviso!", err)
    ).finally(() => {
      this.isPropostaLoading = false
    });
  }

  @Watch('onSearchAgendamento')
  SearchAgendamento (val: string) {
    if (this.isAgendamentoLoading) return;
    if (this.item.agendamentoId) return;
    if (!val) return;
    this.isAgendamentoLoading = true
    this.agendamentoService.AutoComplete(val).then(
      res => {
        this.agendamentos = res.data;
      },
      err => AlertSimpleErr("Aviso!", err)
    ).finally(() => {
      this.isAgendamentoLoading = false
    });    
  }

  // @Watch('item.atracacao.dataHoraEntrada')
  // @Watch('item.atracacao.dataHoraSaida')
  VerificarDatas(){
    if(!this.item.atracacao.dataHoraSaida || !this.item.atracacao.dataHoraEntrada)
      return;
    if(this.item.atracacao.dataHoraSaida < this.item.atracacao.dataHoraEntrada){
      AlertSimple('Atenção!','A data de saída da Atracação/Atendimento não pode ser menor que a data de entrada da Atracação/Atendimento','warning');
      this.valid = false;
    }
  }

  beforeUpdate(){
    if (!this.dialog){
      this.$emit('fechou');
    }
  }

  // AbrirDialogCabeco(registroOperacionalCabeco?: RegistroOperacionalCabeco){

  //   if(registroOperacionalCabeco){
  //     this.registroOperacionalCabeco = new RegistroOperacionalCabeco();
  //     this.registroOperacionalCabeco = registroOperacionalCabeco;
  //     this.editaCabeco = true;
  //   }
  //   else{
  //     this.registroOperacionalCabeco = new RegistroOperacionalCabeco();
  //     this.registroOperacionalCabeco.registroOperacionalId = this.item.id;
  //     this.editaCabeco = false;
  //   }
  //   this.dialogCabeco = true;
  // }

  // SalvarCabeco(){
  //   if(this.editaCabeco == false){
  //     this.item.cabecos.push(this.registroOperacionalCabeco);
  //   }

  //   this.dialogTerno = false;
  // }

  // ExcluirCabeco(registroOperacionalCabeco: RegistroOperacionalCabeco){
  //   this.$swal({
  //     title: 'Atenção!',
  //     text: 'Tem certeza que deseja excluir o registro atual?',
  //     icon: 'question',
  //     showCancelButton: true,
  //     confirmButtonText: 'Sim',
  //     cancelButtonText: 'Não',
  //     showCloseButton: true,
  //     showLoaderOnConfirm: true,
  //     preConfirm: () => {
  //       const index = this.item.cabecos.indexOf(registroOperacionalCabeco);
  //       this.item.cabecos.splice(index,1);
  //       return true;
  //     },
  //     // @ts-ignore: Unreachable code error
  //     allowOutsideClick: () => !this.$swal.isLoading()
  //   })
  // }

  //USADO PARA PEGAR OS EQUIPAMENTOS DO AGENDAMENTO AUTOMATICAMENTE
  PopularEquipamentos(){
    this.agendamento.equipamentos.forEach(equipamento => {
      var roEquipamento = new RegistroOperacionalEquipamento();
      roEquipamento.equipamentoId = equipamento.equipamentoId;
      roEquipamento.equipamento = equipamento.equipamento;
      roEquipamento.dataHoraInicio = equipamento.dataHoraDesejada;
      roEquipamento.dataHoraTermino = equipamento.dataHoraTerminoPrevista;
      roEquipamento.quantidade = equipamento.quantidade;
      roEquipamento.verificado = false;
      this.item.equipamentos.push(roEquipamento);
      if(!this.equipamentos.find(x => x.id == equipamento.equipamentoId)){
        this.equipamentos.push(equipamento.equipamento);
      }
    });
  }

  AbrirDialogFuncionario(registroOperacionalFuncionario?: RegistroOperacionalFuncionario){
    if(registroOperacionalFuncionario){
      this.registroOperacionalFuncionario = new RegistroOperacionalFuncionario();
      this.registroOperacionalFuncionario = registroOperacionalFuncionario;
      this.editaFuncionario = true;
    }
    else{
      this.registroOperacionalFuncionario = new RegistroOperacionalFuncionario();
      this.registroOperacionalFuncionario.registroOperacionalId = this.item.id;
      this.editaFuncionario = false;
    }
    this.dialogFuncionario = true;  
  }

  SalvarFuncionario(){
    if(this.editaFuncionario == false){
      this.item.funcionarios.push(this.registroOperacionalFuncionario);
    }
    this.dialogFuncionario = false;
  }

  ExcluirFuncionario(registroOperacionalFuncionario: RegistroOperacionalFuncionario){
    const context = this;
    const excluir = function () {
      return new Promise( async function (resolve, reject){
        const index = context.item.funcionarios.indexOf(registroOperacionalFuncionario);
        context.item.funcionarios.splice(index, 1);
      });
    }
    AlertExcludeQuestion(excluir, true);
  }

  AbrirDialogTerno(registroOperacionalTerno?: RegistroOperacionalTerno){
    if(registroOperacionalTerno){
      this.registroOperacionalTerno = new RegistroOperacionalTerno();
      this.registroOperacionalTerno = registroOperacionalTerno;
      this.registroOperacionalTerno.data = this.registroOperacionalTerno.data.toDateYYYYMMDD();
      this.editaTerno = true;
    }
    else{
      this.registroOperacionalTerno = new RegistroOperacionalTerno();
      this.registroOperacionalTerno.registroOperacionalId = this.item.id;
      this.editaTerno = false;
    }
    this.dialogTerno = true;  
  }

  SalvarTerno(){
    if(this.editaTerno == false){
      this.item.ternos.push(this.registroOperacionalTerno);
    }
    this.dialogTerno = false;
  }

  ExcluirTerno(registroOperacionalTerno: RegistroOperacionalTerno){
    const context = this;
    const excluir = function () {
      return new Promise( async function (resolve, reject){
        const index = context.item.ternos.indexOf(registroOperacionalTerno);
        context.item.ternos.splice(index,1);
      });
    }
    AlertExcludeQuestion(excluir, true);
  }

  AbrirDialogFaina(registroOperacionalFaina?: RegistroOperacionalFaina){
    if(registroOperacionalFaina){
      this.registroOperacionalFaina = new RegistroOperacionalFaina();
      this.registroOperacionalFaina = registroOperacionalFaina;
      this.editaFaina = true;
    }
    else{
      this.registroOperacionalFaina = new RegistroOperacionalFaina();
      this.registroOperacionalFaina.registroOperacionalId = this.item.id;
      this.editaFaina = false;
    }
    this.dialogFaina = true;
  }

  SalvarFaina(){
    if(this.editaFaina == false){
      this.item.fainas.push(this.registroOperacionalFaina);
    }
    this.dialogFaina = false;
  }

  ExcluirFaina(registroOperacionalFaina: RegistroOperacionalFaina){
    const context = this;
    const excluir = function () {
      return new Promise( async function (resolve, reject){
        const index = context.item.fainas.indexOf(registroOperacionalFaina);
        context.item.fainas.splice(index,1);
      });
    }
    AlertExcludeQuestion(excluir, true);
  }

  AbrirDialogEquipamento(registroOperacionalEquipamento?: RegistroOperacionalEquipamento){
    if(registroOperacionalEquipamento){
      this.registroOperacionalEquipamento = new RegistroOperacionalEquipamento();
      this.registroOperacionalEquipamento = registroOperacionalEquipamento;
      this.editaEquipamento = true;
    }
    else{
      this.registroOperacionalEquipamento = new RegistroOperacionalEquipamento();
      this.registroOperacionalEquipamento.registroOperacionalId = this.item.id;
      this.editaEquipamento = false;
    }
    this.dialogEquipamento = true;
  }

  AbrirDialogEquipamentoAvulso(){
    this.registroOperacionalEquipamento = new RegistroOperacionalEquipamento();
    this.registroOperacionalEquipamento.registroOperacionalId = this.item.id;
    this.registroOperacionalEquipamento.avulso = true;
    this.editaEquipamento = false;
    this.avulso = true;
    this.dialogEquipamento = true;
  }

  FechouEquipamento(){
    this.dialogEquipamento = false;
    this.avulso = false;
  }

  SalvarEquipamento(){
    if(this.editaEquipamento == false){
      this.item.equipamentos.push(this.registroOperacionalEquipamento);
    }
    this.dialogEquipamento = false;
  }

  ExcluirEquipamento(registroOperacionalEquipamento: RegistroOperacionalEquipamento){    
    const context = this;
    const excluir = function () {
      return new Promise( async function (resolve, reject){
        const index = context.item.equipamentos.indexOf(registroOperacionalEquipamento);
        context.item.equipamentos.splice(index,1);        
      });
    }
    AlertExcludeQuestion(excluir, true);
  }

  // AbrirDialogEquipamentoDescritivo(registroOperacionalEquipamentoDescritivo?: RegistroOperacionalEquipamentoDescritivo){
  //   if(registroOperacionalEquipamentoDescritivo){
  //     this.registroOperacionalEquipamentoDescritivo = new RegistroOperacionalEquipamentoDescritivo();
  //     this.registroOperacionalEquipamentoDescritivo = registroOperacionalEquipamentoDescritivo;
  //     this.editaEquipamentoDescritivo = true;
  //   }
  //   else{
  //     this.registroOperacionalEquipamentoDescritivo = new RegistroOperacionalEquipamento();
  //     this.registroOperacionalEquipamentoDescritivo.registroOperacionalId = this.item.id;
  //     this.editaEquipamentoDescritivo = false;
  //   }
  //   this.dialogEquipamentoDescritivo = true;
  // }

  // FechouEquipamentoDescritivo(){
  //   this.dialogEquipamentoDescritivo = false;
  // }

  // SalvaEquipamentoDescritivo(){
  //   if(this.editaEquipamentoDescritivo == false){
  //     this.item.descritivos.push(this.registroOperacionalEquipamentoDescritivo);
  //   }
  //   this.dialogEquipamentoDescritivo = false;
  // }

  // ExcluirEquipamentoDescritivo(registroOperacionalEquipamentoDescritivo: RegistroOperacionalEquipamentoDescritivo){    
  //   this.$swal({
  //     title: 'Atenção!',
  //     text: 'Tem certeza que deseja excluir o registro atual?',
  //     icon: 'question',
  //     showCancelButton: true,
  //     confirmButtonText: 'Sim',
  //     cancelButtonText: 'Não',
  //     showCloseButton: true,
  //     showLoaderOnConfirm: true,
  //     preConfirm: () => {
  //       const index = this.item.descritivos.indexOf(registroOperacionalEquipamentoDescritivo);
  //       this.item.descritivos.splice(index,1);
  //       return true;
  //     },
  //     // @ts-ignore: Unreachable code error
  //     allowOutsideClick: () => !this.$swal.isLoading()
  //   })
  // }

  AbrirDialogEquipamentoAgendamento(){
    this.agendamentoService.ObterPorId(this.item.agendamentoId!, "Equipamentos.Equipamento").then(
      res => {
        var resp = res.data;
        this.equipamentosAgendamento = resp.equipamentos;
        this.equipamentosAgendamento.forEach(x => {
          if(this.item.equipamentos.find(y => y.equipamentoId == x.equipamentoId && y.dataHoraInicio == x.dataHoraDesejada))
            x.atendido = true;
        })
        this.equipamentoAgendamentoDialog = true;
      },
      err => AlertSimpleErr("Aviso!", err)
    )
  }

  AgendamentoEquipamentoIncluir(equipamento: AgendamentoEquipamento){
    //ADICIONAR ESSE ITEM NA RO
    var roEquipamento = new RegistroOperacionalEquipamento();
    roEquipamento.equipamentoId = equipamento.equipamentoId;
    roEquipamento.equipamento = equipamento.equipamento;
    roEquipamento.dataHoraInicio = equipamento.dataHoraDesejada;
    roEquipamento.dataHoraTermino = equipamento.dataHoraTerminoPrevista;
    roEquipamento.quantidade = equipamento.quantidade;
    roEquipamento.verificado = false;
    this.item.equipamentos.push(roEquipamento);
    if(!this.equipamentos.find(x => x.id == equipamento.equipamentoId)){
      this.equipamentos.push(equipamento.equipamento);
    }
  }

  AtualizarValor(item: RegistroOperacionalEquipamento){
    this.service.AtualizarValor(item.registroOperacionalId, item.equipamentoId, item.id).then(
      res =>{
        item.valorUnitario = res.data;
        item.alterado = true;
        this.dialog = false;
        this.dialog = true;
      },
      err => AlertSimpleErr("Aviso!", err)
    )
  }  

  Salvar() {
    if (this.$refs.form.validate()) {
      this.loading = true;
      let pacthModel = jiff.diff(this.itemOriginal, this.item, false);
      if(this.item.equipamentos.some(x => x.verificado == false)){
        AlertSimple("Aviso", 'Um ou mais "Equipamento/Serviço" não foi verificado', "warning" );
      }
      else{
        (this.item.id > 0 ? this.service.Salvar(pacthModel, this.item.id) : this.service.Salvar(this.item)).then(
          res => {
            AlertSimpleRes("Aviso", res);
            this.$emit("salvou");
            this.Close();
          },
          err => AlertSimpleErr("Aviso!", err)
        ).finally(() => {
          this.loading = false;
        });
      }
    }
  }

  Close() {
    this.embarcacoes = [];
    this.locaisOperacao = [];
    this.cabecos = []
    this.portos = [];
    this.equipamentos = [];
    this.item.propostaId = 0;
    this.dialog = false;
  }
}
