











































import { Component, Prop, Watch } from "vue-property-decorator";
import { PageBase } from '@/core/models/shared';
import { Equipamento, PropostaEquipamento, PropostaEquipamentoPeriodo } from "@/core/models/operacional";
import { EquipamentoService, PropostaEquipamentoPeriodoService } from "@/core/services/operacional";

@Component
export default class CadastroPropostaEquipamento extends PageBase{
    @Prop() private item!: PropostaEquipamento;
    @Prop() private value!: string;

    dialog: boolean = false;
    validDialog: boolean = true;
    fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
    $refs!: {
        form: HTMLFormElement
    }

    equipamentos: Equipamento[] = [];
    equipamentoService: EquipamentoService = new EquipamentoService();

    periodos: PropostaEquipamentoPeriodo[] = [];
    periodoService: PropostaEquipamentoPeriodoService = new PropostaEquipamentoPeriodoService();
    
    @Watch('item')
    Item(){
        if(this.$refs.form){
            this.$refs.form.resetValidation();
        }
    }

    @Watch("value")
    Value(){
        this.dialog = this.value ? true : false;

        if(this.dialog){
            this.Carregar();
        }
    }

    @Watch("dialog")
    Dialog() {
        if (!this.dialog) {
            this.$emit("fechou");
        }
    }

    beforeUpdate() {
        if(!this.dialog){
            this.$emit("fechou");
        }
    }

    Carregar(){
        this.equipamentoService.ListarTudo("TipoServicoEquipamento").then(
            res => {
                this.equipamentos = res.data.items;
            }
        )
        this.periodoService.ListarTudo(undefined, 'nome').then(
            res => {
                this.periodos = res.data.items;
            }
        )
    }

    Salvar(){
        if (this.$refs.form.validate()) {
            this.item.equipamento = this.equipamentos.find(x => x.id == this.item.equipamentoId)!;
            this.item.periodo = this.periodos.find(x => x.id == this.item.periodoId)!;
            this.Salvou();
        }
    }  

    Salvou(){
        this.$emit("salvou");
        this.Close();
    }

    Close() {
        this.dialog = false;
    }
}   
