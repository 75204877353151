import { Parametro } from ".";

export class SmtpEmpresa implements Shared.IEntity{

    id: number = 0;
    parametroId: number = 0;
    parametro!: Parametro;
    descricao: string = '';
    smtp: string = '';
    porta: string = '';
    usuario: string = '';
    senha: string = '';
    usarSsl: boolean = false;
    email: string = '';
    nome: string = '';
    
    

    constructor(model?: SmtpEmpresa){

        if(!model)
            return;

        this.id = model.id;
        this.parametroId = model.parametroId;
        this.parametro = model.parametro;
        this.descricao = model.descricao;
        this.smtp = model.smtp;
        this.porta = model.porta;
        this.usuario = model.usuario;
        this.senha = model.senha;
        this.usarSsl = model.usarSsl;
        this.email = model.email;
        this.nome = model.nome;
    }
}