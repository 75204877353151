import { Equipamento, PedidoCompra, PedidoCompraEquipamento, RegistroOperacional } from './../operacional';
import { Empresa, Fornecedor } from './../geral';
import { TipoDespesa } from '.';
import { Categoria } from '../operacional/Categoria';

export class Despesa implements Shared.IEntity {

    id: number = 0;
    empresaId: number = 0;
    empresa!: Empresa;
    registroOperacionalId?: number;
    registroOperacional!: RegistroOperacional;
    observacao: string = "";
    fornecedorId: number = 0;
    fornecedor!: Fornecedor;
    tipoId: number = 0;
    tipo!: TipoDespesa;
    categoriaId?: number;
    categoria!: Categoria;
    pedidoCompraEquipamentoId?: number;
    pedidoCompraEquipamento!: PedidoCompraEquipamento;
    equipamentoServicoId?: number;
    equipamentoServico!: Equipamento;
    pedidoCompraId?: number;
    pedidoCompra!: PedidoCompra;
    equipamento: string = "";
    dataEmissao?: string;
    dataPagamento: string = "";
    notaFiscal: string = "";
    fatLocacao: string = "";
    valorUnitario: number = 0;
    quantidade: number = 1;
    tipoImposto: string = "";
    imposto: number = 0;
    linkNotaFiscal: string = "";
    linkFatLocacao: string = "";

    constructor(model?: Despesa) {

        if (!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;
        this.registroOperacionalId = model.registroOperacionalId;
        this.registroOperacional = model.registroOperacional;
        this.observacao = model.observacao;
        this.fornecedorId = model.fornecedorId;
        this.fornecedor = model.fornecedor;
        this.tipoId = model.tipoId;
        this.tipo = model.tipo;
        this.categoriaId = model.categoriaId;
        this.categoria = model.categoria;
        this.pedidoCompraEquipamentoId = model.pedidoCompraEquipamentoId;
        this.pedidoCompraEquipamento = model.pedidoCompraEquipamento;
        this.equipamentoServicoId = model.equipamentoServicoId;
        this.equipamentoServico = model.equipamentoServico;
        this.pedidoCompraId = model.pedidoCompraId;
        this.pedidoCompra = model.pedidoCompra;
        this.dataEmissao = model.dataEmissao ? model.dataEmissao.toDateYYYYMMDD() : '';
        this.dataPagamento = model.dataPagamento ? model.dataPagamento.toDateYYYYMMDD() : '';
        this.notaFiscal = model.notaFiscal;
        this.fatLocacao = model.fatLocacao;
        this.valorUnitario = model.valorUnitario;
        this.quantidade = model.quantidade;
        this.tipoImposto = model.tipoImposto;
        this.imposto = model.imposto;
        this.linkNotaFiscal = model.linkNotaFiscal;
        this.linkFatLocacao = model.linkFatLocacao;
        this.equipamentoServico = model.equipamentoServico,
        this.equipamento = model.equipamento;
    }
}