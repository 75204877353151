







































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import jiff from 'jiff';
import { Cotacao, CotacaoResposta, SituacaoCotacao } from "@/core/models/financeiro";
import { CotacaoService, SituacaoCotacaoService } from "@/core/services/financeiro";
import { Contato } from "@/core/models/geral";
import { ContatoService } from "@/core/services/geral";
import { AlertSimple, AlertSimpleErr, AlertSimpleRes } from "@/core/services/shared/AlertService";

@Component
export default class CadastroCotacao extends Vue {
  @Prop() private item!: Cotacao;
  @Prop() private value!: string;

  itemOriginal!: Cotacao;

  service = new CotacaoService();
  valid = true;
  dialog = false;
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
  $refs!: {
    form: HTMLFormElement,
    formContato: HTMLFormElement
  }

  situacaoId: number = 0;

  contatoId: number = 0;
  contatos: Contato[] = [];
  contatoService: ContatoService = new ContatoService();

  situacoes: SituacaoCotacao[] = [];
  situacaoService: SituacaoCotacaoService = new SituacaoCotacaoService();

  dialogCotacaoResposta: boolean = false;
  cotacaoResposta: CotacaoResposta = new CotacaoResposta();
  headerResposta: any[] = [
    { text: '', value: 'actions', sortable: false },
    { text: 'Contato', value: 'contato.complementar.nome' },
    { text: 'Contato (E-mail)', value: 'contato.complementar.email' },
    { text: 'Valor', value: 'valor' },
    { text: 'Situacao', value: 'situacao', sortable: false },
    { text: 'Observação', value: 'observacao' },
  ];

  @Watch('item')
  Item() {
    if (this.$refs.form) {
      this.$refs.form.resetValidation();
    }
    this.situacaoId = this.item.situacaoId;
  }

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;

    if (this.dialog){
      this.itemOriginal = jiff.clone(this.item);
    }
  }

  @Watch("dialog")
  Dialog() {
    if (this.dialog) {
      this.Carregar();
    }
    else{
      this.$emit("fechou");
    }
  }

  beforeUpdate(){
    if (!this.dialog){
      this.$emit('fechou');
    }
  }

  mounted() {
    
  }

  Carregar(){
    this.situacaoService.ListarTudo().then(
      res => {
        this.situacoes = res.data.items;
      }
    )
    this.contatoService.ListarTudo().then(
      res => {
        this.contatos = res.data.items;
      }
    )
  }

  AdicionarContato(){
    if (this.$refs.formContato.validate()) {
      var contato = this.contatos.find(x => x.id == this.contatoId);
      if(contato!.complementar.email){
        if(this.item.respostas.find(x => x.contatoId == this.contatoId)){
          AlertSimple("Aviso", "O contato já foi adicionado.", "warning" );
        }
        else{
          this.cotacaoResposta = new CotacaoResposta();
          this.cotacaoResposta.cotacaoId = this.item.id;
          this.cotacaoResposta.contatoId = this.contatoId;
          this.cotacaoResposta.contato = contato!;
          this.item.respostas.push(this.cotacaoResposta);
          this.contatoId = 0;
          if (this.$refs.formContato) {
            this.$refs.formContato.resetValidation();
          }
        }
      }
      else{
        AlertSimple("Aviso", "O contato selecionado não possui e-mail cadastrado.", "warning");
      }
    }
  }

  ExcluirContato(item: CotacaoResposta){
    var index = this.item.respostas.indexOf(item);
    this.item.respostas.splice(index, 1);
  }

  Salvar() {
    if (this.$refs.form.validate()) {      
      let pacthModel = jiff.diff(this.itemOriginal, this.item, false);
      (this.item.id > 0 ? this.service.Salvar(pacthModel, this.item.id) : this.service.Salvar(this.item)).then(
        res => {
          AlertSimpleRes("Aviso!", res);
          this.$emit("salvou");
          this.Close();
        },
        err => AlertSimpleErr("Aviso!", err)
      );
    }
  }

  Close() {
    this.dialog = false;
  }
}
