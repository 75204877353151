import { ContatoRepresentante } from './ContatoRepresentante';
import { ContatoCliente } from './ContatoCliente';
import { Empresa } from './Empresa';
import { Complementar } from "../shared";

export class Contato implements Shared.IEntity{

    id: number = 0;
    empresaId: number = 0;
    empresa!: Empresa;
    complementar: Complementar = new Complementar();
    observacao: string = '';
    clientes: ContatoCliente[] = [];
    representantes: ContatoRepresentante[] = [];
    ativo: boolean = true;

    constructor(model?: Contato){

        if(!model)
            return;

        this.id = model.id;
        this.complementar = model.complementar;
        this.observacao = model.observacao;
        this.clientes = model.clientes;
        this.representantes = model.representantes;
        this.ativo = model.ativo;
    }
}