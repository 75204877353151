import { Cliente } from './Cliente';
import { Contato } from './Contato';


export class ContatoRepresentante {

    contatoId: number = 0;
    contato!: Contato;
    representanteId: number = 0;
    representante!: Cliente;
    

    constructor(model?: ContatoRepresentante){

        if(!model)
            return;

        this.contatoId = model.contatoId;
        this.contato = model.contato;
        this.representanteId = model.representanteId;
        this.representante = model.representante;
    }
}