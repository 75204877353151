import { Empresa, Funcao, Usuario } from ".";
import { PessoaFisica } from "../shared/Pessoa";

export class Funcionario extends PessoaFisica {
    
    empresaId: number = 0;
    empresa!: Empresa;
    usuarioId?: number;
    usuario!: Usuario;

    dataNascimento?: string;
    nomeConjuge: string = '';
    telefoneEmergencia: string = '';
    nomePlanoSaude: string = '';
    numeroPlanoSaude: string = '';
    tipoSanguineo: string = '';
    dataAdmissao?: string;
    dataExamePeriodico?: string;
    remarks: string = '';
    linkPasta: string = '';
    funcaoId?: number;
    funcao!: Funcao;

    constructor(model?: Funcionario){

        super(model);
        
        if(!model)
            return;

        this.empresaId = model.empresaId;
        this.usuarioId = model.usuarioId;

        this.dataNascimento = model.dataNascimento ? model.dataNascimento.toDateYYYYMMDD() : '';
        this.nomeConjuge = model.nomeConjuge;
        this.telefoneEmergencia = model.telefoneEmergencia;
        this.nomePlanoSaude = model.nomePlanoSaude;
        this.numeroPlanoSaude = model.numeroPlanoSaude;
        this.tipoSanguineo = model.tipoSanguineo;
        this.dataAdmissao = model.dataAdmissao ? model.dataAdmissao.toDateYYYYMMDD() : '';
        this.dataExamePeriodico = model.dataExamePeriodico ? model.dataExamePeriodico.toDateYYYYMMDD() : '';
        this.remarks = model.remarks;
        this.linkPasta = model.linkPasta;
        this.funcaoId = model.funcaoId;
        this.funcao = model.funcao;
    }

}