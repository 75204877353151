import { Fornecedor } from ".";
import { Equipamento, PropostaEquipamentoPeriodo } from "../operacional";
import { LocalOperacao } from "../operacional/LocalOperacao";

export class FornecedorEquipamentoServico {
    
    fornecedorId: number = 0;
    fornecedor!: Fornecedor;
    equipamentoServicoId: number = 0;
    equipamentoServico!: Equipamento;
    periodoId: number = 0;
    periodo!: PropostaEquipamentoPeriodo;
    localOperacaoId: number = 0;
    localOperacao!: LocalOperacao;
    valor: number = 0;

    constructor(model?: FornecedorEquipamentoServico){

        if(!model)
            return;
        
        this.fornecedorId = model.fornecedorId;
        this.fornecedor = model.fornecedor;
        this.equipamentoServicoId = model.equipamentoServicoId;
        this.equipamentoServico = model.equipamentoServico;
        this.periodoId = model.periodoId;
        this.periodo = model.periodo;
        this.localOperacaoId = model.localOperacaoId;
        this.localOperacao = model.localOperacao;
        this.valor = model.valor;
    }
}