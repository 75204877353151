enum EnumTipoUsuario {
    Administrador = 1,
    Operacional = 2,
    Financeiro = 3,
    Cliente = 4,
    Comercial = 5
}

export {
    EnumTipoUsuario
}