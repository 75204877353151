import { Empresa, PerfilUsuario } from ".";
import { Complementar } from "../shared";
import { Cliente } from "./Cliente";

export class Usuario implements Shared.IEntity{

    id: number = 0;
    empresaId: number = 0;
    empresa!: Empresa;
    login: string = '';
    senha: string = '';
    complementar: Complementar = new Complementar();
    perfilId: number = 1;
    perfil!: PerfilUsuario;
    ativo: boolean = true;
    foto: any = null;
    assinatura: any = null;
    carimbo: any = null;
    temaEscuro: boolean = false;
    clienteId?: number;
    cliente!: Cliente;

    constructor(model?: Usuario){

        if(!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;
        this.login = model.login;
        this.senha = model.senha;
        this.complementar = model.complementar;
        this.perfilId = model.perfilId;
        this.perfil = model.perfil;
        this.foto = model.foto;
        this.ativo = model.ativo;
        this.temaEscuro = model.temaEscuro;
        this.clienteId = model.clienteId;
        this.cliente = model.cliente;
    }
}