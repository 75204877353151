import { http, httpHeader } from '@/core/ApiConfig';
import { Service } from '../shared';

export class FuncionarioService extends Service{

  constructor(){
    super('funcionario');
  }
  
  public AutoComplete(q: string) {
    return http.get(`${this.GetNomeControle()}/AutoComplete`, {
      params: {
        q: q
      },
      headers: httpHeader.headers
    });
  }

  public ExportarExcel(){
    return http.get(`${this.GetNomeControle()}/ExportarExcel`);
  }

}