var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"pa-0"},[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){return _vm.Close()}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v("Cadastro Relatório Medição")]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"dark":"","text":"","disabled":!_vm.valid,"loading":_vm.loading},on:{"click":function($event){return _vm.Salvar()}}},[_vm._v("Salvar")])],1)],1)],1),_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card-text',{staticClass:"mt-3"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-select',{attrs:{"disabled":"","items":_vm.registroOperacoes,"rules":_vm.fieldRules,"item-text":"numeroCliente","item-value":"id","label":"Porto","placeholder":"Escolha o Porto","outlined":"","dense":""},model:{value:(_vm.item.registroOperacionalId),callback:function ($$v) {_vm.$set(_vm.item, "registroOperacionalId", $$v)},expression:"item.registroOperacionalId"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-textarea',{staticClass:"mt-4",attrs:{"dense":"","outlined":""},model:{value:(_vm.item.observacao),callback:function ($$v) {_vm.$set(_vm.item, "observacao", $$v)},expression:"item.observacao"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headerItens,"items":_vm.item.itens,"items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":""},on:{"click":function($event){return _vm.ExcluirEquipamentoServico(item)}}},on),[_vm._v("mdi-delete")])]}}],null,true)},[_c('span',[_vm._v("Excluir")])])]}},{key:"item.registroOperacionalEquipamento.dataHoraInicio",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.registroOperacionalEquipamento.dataHoraInicio? item.registroOperacionalEquipamento.dataHoraInicio.toDateDDMMYYYY(): '')+" ")]}},{key:"item.registroOperacionalEquipamento.dataHoraTermino",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.registroOperacionalEquipamento.dataHoraTermino? item.registroOperacionalEquipamento.dataHoraTermino.toDateDDMMYYYY(): '')+" ")]}},{key:"item.observacao",fn:function(ref){
var item = ref.item;
return [_c('v-textarea',{staticClass:"mt-4",attrs:{"counter":500,"maxlength":"500","dense":"","outlined":"","height":"85px"},model:{value:(item.observacao),callback:function ($$v) {_vm.$set(item, "observacao", $$v)},expression:"item.observacao"}})]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }