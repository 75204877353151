import { Documento } from '../geral';
import { Faina } from './Faina';

export class RegistroOperacionalFainaDocumento implements Shared.IEntity{

    id: number = 0;
    fainaId: number = 0;
    faina!: Faina;
    documentoId: number = 0;
    documento: Documento = new Documento();
    descricao: string = "";
    excluir: boolean = false;

    constructor(model?: RegistroOperacionalFainaDocumento){

        if(!model)
            return;

        this.id = model.id;
        this.fainaId = model.fainaId;
        this.faina = model.faina;
        this.documentoId = model.documentoId;
        this.documento = model.documento;
        this.descricao = model.descricao;
    }
}