import { Parametro } from '.';
import { PessoaJuridica } from './../shared/Pessoa';

export class Empresa extends PessoaJuridica{

    dominio: string = '';
    logo: any = null;
    parametro!: Parametro;
    dominios: any[] = [];

    constructor(model?: Empresa){
        
        super(model);
        if(!model)
            return;

        this.dominio = model?.dominio;
        this.logo = model.logo;
        this.parametro = model.parametro;
        this.dominios = model.dominios;
    }

}