import { Contato } from './../geral/Contato';
import { Cliente, Empresa, Funcionario, Porto } from "../geral";
import { PropostaEquipamento } from './PropostaEquipamento';
import { LocalOperacao, PropostaLocalOperacaoPeriodo, PropostaTerno } from '.';
export class Proposta implements Shared.IEntity{

    id: number = 0;
    empresaId: number = 0;
    empresa!: Empresa;
    clienteId: number = 0;
    cliente!: Cliente;
    contatoId: number = 0;
    contato!: Contato;
    responsavelId: number = 0;
    responsavel!: Funcionario;
    portoId: number = 0;
    porto!: Porto;
    localOperacaoId: number = 0;
    localOperacao!: LocalOperacao;
    localOperacaoValor: number = 0;
    ternoValor: number = 0;
    ternoPorcentagemNoturna: number = 0;
    ternoPorcentagemFimSemanaFeriado: number = 0;
    numero: number = 0;
    data: string = new Date().toYYYYMMDD();
    link: string = '';
    observacao: string = '';
    valorAtracacaoDesatracacao: number = 0;
    valorAtracacaoDesatracacaoAnterior: number = 0;
    valorPeriodoOperante: number = 0;
    localOperacaoPeriodo: number = 0;
    valorDWT: number = 0;
    cobrarDiaria: boolean = false;
    
    localOperacaoPeriodos: PropostaLocalOperacaoPeriodo[] = [];
    ternos: PropostaTerno[] = [];
    equipamentos: PropostaEquipamento[] = [];

    numeroCliente: string = `${this.numero} - ${this.cliente ? this.cliente.nome : ''}`;

    constructor(model?: Proposta){

        if(!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;
        this.clienteId = model.clienteId;
        this.cliente = model.cliente;
        this.contatoId = model.contatoId;
        this.contato = model.contato;
        this.responsavelId = model.responsavelId;
        this.responsavel = model.responsavel;
        this.portoId = model.portoId;
        this.porto = model.porto;
        this.localOperacaoId = model.localOperacaoId;
        this.localOperacao = model.localOperacao;
        this.localOperacaoValor = model.localOperacaoValor;
        this.ternoValor = model.ternoValor;
        this.ternoPorcentagemNoturna = model.ternoPorcentagemNoturna;
        this.ternoPorcentagemFimSemanaFeriado = model.ternoPorcentagemFimSemanaFeriado;
        this.numero = model.numero;
        this.data = model.data.toDateYYYYMMDD();
        this.link = model.link;
        this.observacao = model.observacao;
        this.valorAtracacaoDesatracacao = model.valorAtracacaoDesatracacao; //VIRA PERIODO INOPERANTE
        this.valorAtracacaoDesatracacaoAnterior = model.valorAtracacaoDesatracacaoAnterior;
        this.valorPeriodoOperante = model.valorPeriodoOperante;
        this.localOperacaoPeriodo = model.localOperacaoPeriodo;
        this.valorDWT = model.valorDWT;
        this.cobrarDiaria = model.cobrarDiaria;
        
        this.localOperacaoPeriodos = model.localOperacaoPeriodos;
        this.ternos = model.ternos;
        this.equipamentos = model.equipamentos;

        this.numeroCliente = model.numeroCliente;
    }
}