




























import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import jiff from 'jiff';
import { TipoReceita } from "@/core/models/financeiro";
import { TipoReceitaService } from "@/core/services/financeiro";
import { AlertSimpleErr, AlertSimpleRes } from "@/core/services/shared/AlertService";


@Component
export default class CadastroTipoReceita extends Vue {
    @Prop() private item!: TipoReceita;
    @Prop() private value!: string;

    itemOriginal!: TipoReceita;

    service = new TipoReceitaService();
    valid = true;
    dialog = false;
    fieldRules : any [] = [(v:any) => !!v || "Campo obrigatório"];
    $refs!:{
        form: HTMLFormElement
    }

    @Watch('item')
    Item(){
        if (this.$refs.form){
            this.$refs.form.resetValidation();
        }
    }

    @Watch("value")
    Value(){
        this.dialog = this.value ? true : false;

        if (this.dialog){
            this.itemOriginal = jiff.clone(this.item);
        }
    }

    @Watch("dialog")
    Dialog(){
        if (!this.dialog) {
            this.$emit("fechou");
        }
    }

    beforeUpdate() {
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    Salvar(){
        if (this.$refs.form.validate()){
            let pacthModel = jiff.diff(this.itemOriginal,this.item,false);
            (this.item.id > 0 ? this.service.Salvar(pacthModel,this.item.id) : this.service.Salvar(this.item)).then(
                res => {
                    AlertSimpleRes("Aviso!", res)
                    this.$emit("salvou");
                    this.Close();
                },
                err => AlertSimpleErr("Aviso!", err)
            )
        }
    }

    Close (){
        this.dialog = false
    }
}
