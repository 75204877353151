import { Empresa } from '.';
import { PortoTerno } from '../operacional';
import { LocalOperacao } from '../operacional/LocalOperacao';
export class Porto implements Shared.IEntity{
    
    id: number = 0;
    empresaId: number = 0;
    empresa!: Empresa;
    nome: string = '';
    ativo: boolean = true;
    ternos: PortoTerno[] = [];
    locaisOperacao: LocalOperacao[] = [];

    constructor(model?: Porto){

        if(!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;
        this.nome = model.nome;
        this.ativo = model.ativo;
        this.ternos = model.ternos;
        this.locaisOperacao = model.locaisOperacao;
    }

}