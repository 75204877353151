import {http} from '../../ApiConfig';
import { Service } from '../shared/';

export class EmpresaService extends Service{

  constructor(){
    super('empresa');
  }

  public ObterLogo(){
    return http.get(`${this.GetNomeControle()}/logo`);
  }
  
}