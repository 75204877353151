import { http } from "@/core/ApiConfig";
import { Service } from "../shared";

export class ReceitaService extends Service {
    constructor() {
        super('receita');
    }

    private GetFiltro(parametros: string, filter: any) {
        if (filter) {
            let paramTemp = '';

            if (filter.dataAtendimentoInicio) {
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `registroOperacional/dataDocumento ge ${filter.dataAtendimentoInicio}`;
              }
        
              if (filter.dataAtendimentoFim) {
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `registroOperacional/dataDocumento le ${filter.dataAtendimentoFim}`;
              }
        
              if (filter.dataEmissaoInicio) {
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `dataEmissao ge ${filter.dataEmissaoInicio}`;
              }
        
              if (filter.dataEmissaoFim) {
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `dataEmissao le ${filter.dataEmissaoFim}`;
              }

            if (filter.dataInicio) {
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `dataPagamento ge ${filter.dataInicio}`;
            }

            if (filter.dataFim) {
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `dataPagamento le ${filter.dataFim}`;
            }

            if (filter.clienteId) {
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `clienteId eq ${filter.clienteId}`;
            }

            if (filter.embarcacaoId) {
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `registroOperacional/embarcacaoId eq ${filter.embarcacaoId}`;
            }

            if (filter.tipoReceitaId) {
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `tipoId eq ${filter.tipoReceitaId}`;
            }

            if (filter.numeroRo) {
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `registroOperacional/numero eq ${filter.numeroRo}`;
            }

            if (paramTemp) {

                if (parametros) {
                    parametros += '&';
                }
                else {
                    parametros += '?';
                }

                parametros += `$filter=${paramTemp}`;
            }

        }
        return parametros;
    }

    ListarComFiltro(page: number, pageSize: number, sortBy: any[], desc: any[], search: any, columns: any[], filter: any, include?: string) {

        let parametros = this.GetParamentroPaginacaoOrdenacao(page, pageSize, sortBy, desc, search, columns);

        parametros = this.GetFiltro(parametros, filter);

        return http.get(`${this.GetNomeControle()}${parametros}`, this.GetHeader(include));
    }

    public DetalharEquipamento(numeroRo: number, clienteId?: number, embarcacaoId?: number, tipoReceitaId?: number, dataInicio?: string, dataFim?: string, dataSaida?: string) {
        return http.get(`${this.GetNomeControle()}/DetalharEquipamento`, {
            params: {
                numeroRo,
                clienteId,
                embarcacaoId,
                tipoReceitaId,
                dataInicio,
                dataFim,
                dataSaida
            }
        })
    }

    public ExcelEquipamentoReceita(numeroRo: number, clienteId?: number, embarcacaoId?: number, tipoReceitaId?: number, dataInicio?: string, dataFim?: string) {
        return http.get(`${this.GetNomeControle()}/ExcelEquipamentoReceita`, {
            params: {
                numeroRo,
                clienteId,
                embarcacaoId,
                tipoReceitaId,
                dataInicio,
                dataFim,
            }
        })
    }
}