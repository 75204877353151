





















































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import jiff from 'jiff';

import { PerfilUsuarioService, UsuarioService, ClienteService } from "@/core/services/geral";
import { Usuario, PerfilUsuario, Cliente } from "@/core/models/geral";
import { ArquivoService } from '@/core/services/shared';
import { EnumTipoUsuario } from "@/core/models/shared/Enumerados";
import { AlertSimpleErr, AlertSimpleRes } from "@/core/services/shared/AlertService";

@Component
export default class CadastroUsuario extends Vue {
  @Prop() private item!: Usuario;
  @Prop() private value!: string;

  itemOriginal!: Usuario;

  tipoUsuario = EnumTipoUsuario;

  loading: boolean = false;
  service = new UsuarioService();
  valid = true;
  dialog = false;
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
  mskTelefone = process.env.VUE_APP_MSK_TELEFONE;
  perfis: PerfilUsuario[] = [];
  $refs!: {
    formUsuario: HTMLFormElement
  }
  onSearchTransportadora: any = null;
  isTransportadoraLoading: boolean = false;
  foto: any = null;
  assinatura: any = null;
  carimbo: any = null;
  
  onSearchCliente: any = null;
  isClienteLoading: boolean = false;
  clientes: Cliente[] = [];
  clienteService: ClienteService = new ClienteService();

  @Watch('item')
  Item() {
    if (this.$refs.formUsuario) {
      this.$refs.formUsuario.resetValidation();
    }
    if(this.item.clienteId)
      this.clientes.push(this.item.cliente);
  }

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;

    if (this.dialog){
      this.itemOriginal = jiff.clone(this.item);
    }
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  @Watch('onSearchCliente')
  SearchProposta (val: string) {
    if (this.isClienteLoading) return;
    if (!val) return;
    this.isClienteLoading = true
    this.clienteService.AutoComplete(val).then(
      res => {
        this.clientes = res.data;
      },
      err => AlertSimpleErr("Aviso!", err)
    ).finally(() => (this.isClienteLoading = false));
  }

  beforeUpdate(){
    if (!this.dialog){
      this.$emit('fechou');
    }
  }

  mounted() {
    const perfilervice = new PerfilUsuarioService();
    perfilervice.ListarTudo().then(
      res => {
        this.perfis = res.data.items;
      },
      err => AlertSimpleErr("Aviso!", err)
    );
  }

  Salvar() {
    if (this.$refs.formUsuario.validate()) {
      this.loading = true;
      let pacthModel = jiff.diff(this.itemOriginal, this.item, false);
      (this.item.id > 0 ? this.service.Salvar(pacthModel, this.item.id) : this.service.Salvar(this.item)).then(
        res => {
          AlertSimpleRes("Aviso!", res);
          this.$emit("salvou");
          this.Close();
        },
        err => AlertSimpleErr("Aviso!", err)
      ).finally(() => {
        this.loading = false;
      });
    }
  }

  async LoadImage(){

    if (!this.foto)
      return;

    const arquivoService = new ArquivoService();
    const dados = await arquivoService.Ler(this.foto);
    this.item.foto = dados.replace(/^[^,]*,/, "");
  }  

  RemoveImage(){
    this.item.foto = null;
    this.foto = null;
  }

  async LoadAssinatura(){

    if (!this.assinatura)
      return;

    const arquivoService = new ArquivoService();
    const dados = await arquivoService.Ler(this.assinatura);
    this.item.assinatura = dados.replace(/^[^,]*,/, "");
  }

  RemoveAssinatura(){
    this.item.assinatura = null;
    this.assinatura = null;
  }

  async LoadCarimbo(){

    if (!this.carimbo)
      return;

    const arquivoService = new ArquivoService();
    const dados = await arquivoService.Ler(this.carimbo);
    this.item.carimbo = dados.replace(/^[^,]*,/, "");
  }

  RemoveCarimbo(){
    this.item.carimbo = null;
    this.carimbo = null;
  }

  Close() {
    this.foto = null;
    this.assinatura = null;
    this.carimbo = null;
    this.dialog = false;
  }
}
