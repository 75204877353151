





























































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import jiff from 'jiff';
import { Embarcacao, TipoEmbarcacao } from "@/core/models/operacional";
import { EmbarcacaoService, TipoEmbarcacaoService } from "@/core/services/operacional";
import { ArquivoService } from "@/core/services/shared";
import { AlertSimpleErr, AlertSimpleRes } from "@/core/services/shared/AlertService";

@Component
export default class CadastroEmbarcacao extends Vue {
  @Prop() private item!: Embarcacao;
  @Prop() private value!: string;

  itemOriginal!: Embarcacao;

  loading: boolean = false;
  service = new EmbarcacaoService();
  valid = true;
  dialog = false;
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
  $refs!: {
    form: HTMLFormElement
  }
  tipoEmbarcacaoService =  new TipoEmbarcacaoService();
  tipoEmbarcacoes: TipoEmbarcacao[] = []
  foto: any = null;


  @Watch('item')
  Item() {
    if (this.$refs.form) {
      this.$refs.form.resetValidation();
    }
  }

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;

    if (this.dialog){
      this.itemOriginal = jiff.clone(this.item);
    }    
  }

  @Watch("dialog")
  Dialog() {
    
    if (this.dialog) {
      if(this.item.id > 0){
        this.service.GetFoto(this.item.id).then(
          res => {
            this.item.foto = res.data;
          },
          err => AlertSimpleErr("Aviso!", err)
        )
      }
    }
    else{
      this.$emit("fechou");      
    }
  }

  beforeUpdate(){
    if (!this.dialog){
      this.$emit('fechou');
    }
  }

  mounted() {
    this.tipoEmbarcacaoService.ListarTudo().then(
      res=>{
        this.tipoEmbarcacoes = res.data.items;
      },
      err => AlertSimpleErr("Aviso!", err)
    )
  }

  async LoadImage(){

    if (!this.foto)
      return;

    const arquivoService = new ArquivoService();
    const dados = await arquivoService.Ler(this.foto);
    this.item.foto = dados.replace(/^[^,]*,/, "");
  }

  RemoveImage(){
    this.item.foto = null;
    this.foto = null;
  }

  Salvar() {
    if (this.$refs.form.validate()) {
      this.loading = true;
      let pacthModel = jiff.diff(this.itemOriginal, this.item, false);
      (this.item.id > 0 ? this.service.Salvar(pacthModel, this.item.id) : this.service.Salvar(this.item)).then(
        res => {
          AlertSimpleRes("Aviso!", res);
          this.$emit("salvou");
          this.Close();
        },
        err => AlertSimpleErr("Aviso!", err)
      ).finally(() => {
        this.loading = false;
      });
    }
  }

  Close() {
    this.foto = null;
    this.dialog = false;
  }
}
