


















































































import { Component, Prop, Watch } from "vue-property-decorator";
import { PageBase } from '@/core/models/shared';
import { LocalOperacao } from "@/core/models/operacional/LocalOperacao";
import { EnumTipoUsuario } from "@/core/models/shared/Enumerados";
import { LocalOperacaoPeriodo } from "@/core/models/operacional";
import { AlertExcludeQuestion } from "@/core/services/shared/AlertService";

@Component
export default class CadastroPortoLocalOperacao extends PageBase{
    @Prop() private item!: LocalOperacao;
    @Prop() private value!: string;

    validPeriodo: boolean = true;
    validDialog: boolean = true;
    dialog: boolean = false;
    fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
    numberGtZeroFieldRules: any[] = [(v: any) => (!isNaN(parseFloat(v)) && v > 0) || "Campo obrigatório"];
    tipoUsuario = EnumTipoUsuario;

    periodo: LocalOperacaoPeriodo = new LocalOperacaoPeriodo();
    periodoHeaders: any[] = [
        { text: '',value:'actions' ,sortable: false },
        { text: 'Tempo (Horas)', value: 'tempo' },
    ];

    $refs!: {
        form: HTMLFormElement,
        formPeriodo: HTMLFormElement
    }
    
    Salvar(){

        if (this.$refs.form.validate()) {
            this.$emit("salvou");
            this.Close();
        }
    }    

    @Watch('item')
    Item(){

        if(this.$refs.form){
            this.$refs.form.resetValidation();
        }
    }

    @Watch("value")
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (!this.dialog) {
            this.$emit("fechou");
        }
    }

    beforeUpdate() {
        if(!this.dialog){
            this.$emit("fechou");
        }
    }

    AdicionarPeriodo(){
        if(this.$refs.formPeriodo.validate()){
            this.periodo.localOperacaoId = this.item.id;
            this.item.periodos.push(this.periodo);
            this.periodo = new LocalOperacaoPeriodo();
            if (this.$refs.formPeriodo) {
                this.$refs.formPeriodo.resetValidation();
            }
        }
    }

    ExcluirPeriodo(item: LocalOperacaoPeriodo){
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                const index = context.item.periodos.indexOf(item);
                context.item.periodos.splice(index, 1);
            });
        }
        AlertExcludeQuestion(excluir, true);
    }

    Close() {
        this.dialog = false;
    }

}   

