





























































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import jiff from 'jiff';
import { Equipamento, PedidoCompra, PedidoCompraEquipamento, RegistroOperacionalEquipamento } from "@/core/models/operacional";
import { EquipamentoService, PedidoCompraService } from "@/core/services/operacional";
import { Fornecedor, Funcionario } from "@/core/models/geral";
import { FornecedorService, FuncionarioService } from "@/core/services/geral";
import { AlertExcludeQuestion, AlertSimple, AlertSimpleErr, AlertSimpleRes } from "@/core/services/shared/AlertService";

@Component
export default class CadastroPedidoCompra extends Vue {
    @Prop() private item!: PedidoCompra;
    @Prop() private value!: string;

    itemOriginal!: PedidoCompra;

    loading: boolean = false;
    service = new PedidoCompraService();
    valid = true;
    dialog = false;
    fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
    $refs!: {
        form: HTMLFormElement
    }

    funcionarios: Funcionario[] = [];
    funcionarioService: FuncionarioService = new FuncionarioService();
    isFuncionarioLoading: boolean = false;
    onSearchFuncionario: any = null;

    fornecedorService = new FornecedorService()
    fornecedores: Fornecedor[] = [];
    isFornecedorLoading: boolean = false;
    onSearchFornecedor: any = null;

    equipamentoService = new EquipamentoService()
    equipamentos: Equipamento[] = [];
    isEquipamentoLoading: boolean = false;
    onSearchEquipamento: any = null;

    dialogSelecionaEquipamento: boolean = false;
    equipamentosSelecionados: RegistroOperacionalEquipamento[] = [];

    dialogCadastroEquipamento: boolean = false;
    equipamento: PedidoCompraEquipamento = new PedidoCompraEquipamento();
    headerEquipamentos: any[] = [
        { text: '',value:'actions' ,sortable: false },
        { text: '# RO', value: 'equipamento.registroOperacional.numero' },
        { text: '# OS', value: 'equipamento.sequencia' },
        { text: 'Equipamento/Fornecedor', value: 'equipamento.equipamentoFornecedor' },
        { text: 'Data/Hora Solicitação', value: 'dataHoraSolicitacao', type:'date' },
        { text: 'Data/Hora Liberação', value: 'dataHoraLiberacao', type:'date' },
        { text: 'Data Pagamento', value: 'dataPagamento', type:'date' },
        { text: 'Quantidade', value: 'quantidade', type:'number' },
        { text: 'Valor Proposta', value: 'valorProposta', type:'number' },
        { text: 'Valor Unitario', value: 'valorUnitario', type:'number' }
    ];

    @Watch('item')
    Item(){
        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }

        if(this.item.id > 0){
            if(this.item.responsavelId > 0){
                this.funcionarios.push(this.item.responsavel);
            }
            if(this.item.fornecedorId > 0){
                this.fornecedores.push(this.item.fornecedor);                
            }
            if(this.item.equipamentoId > 0){
                this.equipamentos.push(this.item.equipamento);
            }
        }
    }

    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
        if (this.dialog){
            this.itemOriginal = jiff.clone(this.item);
        }
    }

    @Watch("dialog")
    Dialog() {
        if (!this.dialog) {
            this.$emit("fechou");            
        }
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }   

    @Watch('onSearchFuncionario')
    SearchResponsavel (val: string) {

        if (this.isFuncionarioLoading) return;
        if (this.item.responsavelId) return;
        if (!val) return;

        this.isFuncionarioLoading = true
        this.funcionarioService.AutoComplete(val).then(
            res => {
                this.funcionarios = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => (this.isFuncionarioLoading = false));
    }

    @Watch('onSearchEquipamento')
    SearchEquipamento (val: string) {
        if (this.isEquipamentoLoading) return;
        if(this.item.equipamentoId) return;
        if (!val) return;
        this.isEquipamentoLoading = true
        this.equipamentoService.AutoComplete(val).then(
            res => {
                this.equipamentos = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => (this.isEquipamentoLoading = false));
    }

    @Watch('onSearchFornecedor')
    SearchFornecedor (val: string) {
        if (this.isFornecedorLoading) return;
        if(this.item.fornecedorId) return;
        if (!val) return;
        this.isFornecedorLoading = true
        this.fornecedorService.AutoComplete(val).then(
            res => {
                this.fornecedores = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => (this.isFornecedorLoading = false));
    }

    AbrirDialogSelecionarEquipamentoServico(){
        this.dialogSelecionaEquipamento = true;
        this.equipamentosSelecionados = [];
    }

    AbrirDialogEquipamento(item: PedidoCompraEquipamento){
        this.equipamento = item;
        item.dataPagamento = item.dataPagamento ? item.dataPagamento.toDateYYYYMMDD() : "";
        this.dialogCadastroEquipamento = true;
    }

    AdicionarEquipamentos(){
        this.equipamentosSelecionados.forEach(equipamento => {            
            if(this.item.equipamentos.find(x => x.equipamentoId == equipamento.id)){
                return;
            }
            let pedidoEquipamento = new PedidoCompraEquipamento();
            pedidoEquipamento.equipamento = equipamento;
            pedidoEquipamento.equipamentoId = equipamento.id;
            pedidoEquipamento.dataHoraSolicitacao = equipamento.dataHoraInicio;
            pedidoEquipamento.dataHoraLiberacao = equipamento.dataHoraTermino;
            pedidoEquipamento.dataPagamento = equipamento.dataPagamento;
            pedidoEquipamento.quantidade = equipamento.quantidade;
            pedidoEquipamento.valorUnitario = equipamento.valorUnitario;
            this.item.equipamentos.push(pedidoEquipamento);
        });
    }

    ExcluirEquipamento(item: PedidoCompraEquipamento){
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                let index = context.item.equipamentos.indexOf(item);
                context.item.equipamentos.splice(index, 1);
            });
        }
        AlertExcludeQuestion(excluir, true);
    }

    SalvarEquipamento(){
        this.dialogCadastroEquipamento = false;
        this.equipamento = new PedidoCompraEquipamento();
    }

    Salvar() {
        if(this.item.equipamentos.length == 0){
            AlertSimple('Aviso', "Não é possível criar um pedido de compra sem itens vinculados!", 'warning')
        }
        else{
            if (this.$refs.form.validate()) {
                this.loading = true;
                let pacthModel = jiff.diff(this.itemOriginal, this.item, false);
                (this.item.id > 0 ? this.service.Salvar(pacthModel, this.item.id) : this.service.Salvar(this.item)).then(
                    res => {
                        AlertSimpleRes("Aviso!", res);
                        this.$emit("salvou");
                        this.Close();
                    },
                    err => AlertSimpleErr("Aviso!", err)
                ).finally(() => {
                    this.loading = false;
                });
            }
        }        
    }
    Limpar(){
        this.equipamentosSelecionados = [];
    }
    Close() {
        this.Limpar();
        this.dialog = false;
    }
}
