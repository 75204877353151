














































import { PedidoCompraEquipamento } from "@/core/models/operacional";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class CadastroPedidoCompraEquipamento extends Vue {
    @Prop() private item!: PedidoCompraEquipamento;
    @Prop() private value!: string;

    valid = true;
    dialog: boolean = false;
    loading: boolean = false;
    fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
    $refs!: {
        form: HTMLFormElement
    }

    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;        
    }

    @Watch("dialog")
    Dialog() {
        if (!this.dialog) {
            this.$emit("fechou");
        }                
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    Salvar(){
        this.$emit('salvou');
        this.Close();
    }

    Close(){
        this.dialog = false;        
    }
}
