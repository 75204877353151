


































































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import jiff from 'jiff';
import { Funcao, Funcionario, PerfilUsuario, Usuario } from "@/core/models/geral";
import { EnderecoService, FuncaoService, FuncionarioService, PerfilUsuarioService, UsuarioService } from "@/core/services/geral";
import { PageBase } from "@/core/models/shared";
import { EnumTipoUsuario } from "@/core/models/shared/Enumerados";
import { AlertSimpleErr, AlertSimpleRes } from "@/core/services/shared/AlertService";


@Component
export default class CadastroFuncionario extends PageBase {
  @Prop() private item!: Funcionario;
  @Prop() private value!: string;

  itemOriginal!: Funcionario;

  tipoUsuario = EnumTipoUsuario;

  loading: boolean = false;
  service = new FuncionarioService();
  valid = true;
  dialog = false;
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
  $refs!: {
    form: HTMLFormElement
  }
  mskCpf = process.env.VUE_APP_MSK_CPF;
  mskTelefone = process.env.VUE_APP_MSK_TELEFONE;
  mskCep = process.env.VUE_APP_MSK_CEP;

  enderecoService = new EnderecoService();
  estados: any[] = [];
  municipios: any[] = [];

  usuario: Usuario = new Usuario();
  perfis: PerfilUsuario[] = [];
  usuarios: Usuario[] = [];
  usuarioService: UsuarioService = new UsuarioService();

  funcoes: Funcao[] = [];
  funcaoService: FuncaoService = new FuncaoService();

  @Watch("item.endereco.estadoId")
  onEstadoIdChanged(value: number) {
    this.CarregarMunicipio(value);
  }

  @Watch('item')
  Item() {

    if(this.item.usuario != null){
      this.usuario = this.item.usuario;
    }

    if (this.$refs.form) {
      this.$refs.form.resetValidation();
    }
  }

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;

    if (this.dialog){
      this.itemOriginal = jiff.clone(this.item);
    }
  }

  @Watch("dialog")
  Dialog() {
    if(this.dialog){
      if(this.app.perfilId == this.tipoUsuario.Administrador){
        this.usuarioService.ListarTudo().then(
          res => {
            this.usuarios = res.data.items;
          },
          err => AlertSimpleErr("Aviso!", err)
        )
      }
    }
    else{
      this.usuario = new Usuario();
      this.$emit("fechou");
    }
  }

  beforeUpdate(){
    if (!this.dialog){
      this.$emit('fechou');
    }
  }

  mounted() {
    this.enderecoService.ObterEstados().then(
      res => {
        this.estados = res.data.items;
      },
      err => AlertSimpleErr("Aviso!", err)
    ); 
    const perfilervice = new PerfilUsuarioService();
    perfilervice.ListarTudo().then(
      res => {
        this.perfis = res.data.items;
      },
      err => AlertSimpleErr("Aviso!", err)
    );
    this.funcaoService.ListarTudo().then(
      res => {
        this.funcoes = res.data.items;
      },
      err => AlertSimpleErr("Aviso!", err)
    )
  }

  CarregarUsuario(){
    this.usuario = this.usuarios.find(x => x.id == this.item.usuarioId)!;
  }

  CarregarMunicipio(estadoId: number){
    this.enderecoService.ObterMunicipios(estadoId).then(
      res => {
        this.municipios = res.data.items;
      },
      err => AlertSimpleErr("Aviso!", err)
    );
  }

  ObterEndereco(cep: string){
    this.enderecoService.ObterPorCep(cep).then(
      res => {
        const endereco = res.data;
        if (endereco) {
          this.item.endereco.logradouro = endereco.logradouro;
          this.item.endereco.bairro = endereco.bairro;
          this.item.endereco.estadoId = endereco.estadoId;
          this.item.endereco.municipioId = endereco.municipioId;
        }
      },
      err => AlertSimpleErr("Aviso!", err)
    );
  }

  Salvar() {
    if (this.$refs.form.validate()) {
      this.loading = true;
      if(this.usuario.login.length > 0 && this.item.id == 0){
        this.usuario.complementar.email = this.item.email;
        this.usuario.complementar.nome = this.item.nome;
        this.usuario.empresaId = this.item.empresaId;
        this.item.usuario = this.usuario;
      }
      let pacthModel = jiff.diff(this.itemOriginal, this.item, false);
      (this.item.id > 0 ? this.service.Salvar(pacthModel, this.item.id) : this.service.Salvar(this.item)).then(
        res => {
          AlertSimpleRes("Aviso!", res);
          this.$emit("salvou");
          this.Close();
        },
        err => AlertSimpleErr("Aviso!", err)
      ).finally(() => {
        this.loading = false;
      });
    }
  }

  Close() {
    this.dialog = false;
  }
}
