import { http, httpHeader } from '@/core/ApiConfig';
import { Service } from '../shared';

export class EmbarcacaoService extends Service{

  constructor(){
    super('embarcacao');
  }

  public AutoComplete(q: string) {
    return http.get(`${this.GetNomeControle()}/AutoComplete`, {
        params: {
          q: q
        },
        headers: httpHeader.headers
    });
  }

  public GetFoto(id: number){
    return http.get(`${this.GetNomeControle()}/${id}/Foto`);
  }

}