import { http, httpHeader } from '@/core/ApiConfig';
import { Service } from '../shared';

export class EquipamentoService extends Service{

  constructor(){
    super('equipamento');
  }

  public AutoComplete(q: string) {
    return http.get(`${this.GetNomeControle()}/AutoComplete`, {
      params: {
        q: q
      },
      headers: httpHeader.headers
    });
  }

}