import { Arquivo } from "../shared";
import { Empresa } from "./Empresa";

export class Documento implements Shared.IEntity{
    
    id: number = 0;
    empresaId: number = 0;
    empresa!: Empresa;
    identificacao: string = '';
    nome: string = '';
    arquivo!: any;

    constructor(model?: Documento){
        
        if(!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;
        this.empresa = model.empresa;
        this.identificacao = model.identificacao;
        this.nome = model.nome;
    }

}