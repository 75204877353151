import { http, httpHeader } from '@/core/ApiConfig';
import { Service } from '../shared';

export class PropostaService extends Service{

  constructor(){
    super('proposta');
  }

  private GetFiltro(parametros: string, filter: any){

    if (filter) {
      let paramTemp = '';

      if (filter.numero){
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `numero eq ${filter.numero}`;
      }
      
      if (filter.clienteId){
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `clienteId eq ${filter.clienteId}`;
      }

      if (filter.contatoId){
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `contatoId eq ${filter.contatoId}`;
      }

      if (filter.responsavelId){
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `responsavelId eq ${filter.responsavelId}`;
      }

      if (filter.portoId){
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `portoId eq ${filter.portoId}`;
      }

      if (filter.localOperacaoId){
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `localOperacaoId eq ${filter.localOperacaoId}`;
      }

      if (paramTemp) {

        if(parametros){
          parametros += '&';
        }
        else {
          parametros += '?';
        }

        parametros += `$filter=${paramTemp}`;
      }

    }

    return parametros;
  }

  ListarComFiltro(page: number, pageSize: number, sortBy: any[], desc: any[], search: any, columns: any[], filter: any, include?: string){

    let parametros = this.GetParamentroPaginacaoOrdenacao(page, pageSize, sortBy, desc, search, columns);

    parametros = this.GetFiltro(parametros, filter);

    return http.get(`${this.GetNomeControle()}${parametros}`, this.GetHeader(include));
  }

  public Equipamentos(id: number) {
    return http.get(`${this.GetNomeControle()}/${id}/Equipamentos`);
  }

  public AutoComplete(q: string) {
    return http.get(`${this.GetNomeControle()}/AutoComplete`, {
      params: {
        q: q
      },
      headers: httpHeader.headers
    });
  }
}