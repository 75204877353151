






































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { RegistroOperacionalEquipamento, RegistroOperacionalEquipamentoDocumento } from "@/core/models/operacional";
import { RegistroOperacionalService } from "@/core/services/operacional";
import { Fornecedor } from "@/core/models/geral";
import { DocumentoService, FornecedorService } from "@/core/services/geral";
import jiff from 'jiff';
import { AlertExcludeQuestion, AlertSimple, AlertSimpleErr, AlertSimpleRes } from "@/core/services/shared/AlertService";
import { PageBase } from "@/core/models/shared";

@Component
export default class CadastroOrdemServico extends PageBase {
    @Prop() private item!: RegistroOperacionalEquipamento;
    @Prop() private value!: string;

    itemOriginal!:RegistroOperacionalEquipamento;

    tabActive: any = {};

    loading: boolean = false;
    service = new RegistroOperacionalService();
    valid = true;
    dialog = false;
    fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
    $refs!: {
        form: HTMLFormElement
    }

    fornecedorService = new FornecedorService()
    fornecedores: Fornecedor[] = [];
    isFornecedorLoading: boolean = false;
    onSearchFornecedor: any = null;

    documentos: RegistroOperacionalEquipamentoDocumento[] = [];
    dialogEquipamentoDocumento: boolean = false;
    equipamentoDocumento: RegistroOperacionalEquipamentoDocumento = new RegistroOperacionalEquipamentoDocumento();
    headerDocumentos: any[] = [
        { text: '', value: 'actions', sortable: false },
        { text: 'Descrição', value: 'documento.identificacao' },
        { text: 'Arquivo', value: 'documento.nome' }
    ]

    itemExcluido(item: any){
        return item.excluir ? 'itemExcluido' : ''
    }

    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
        if (this.dialog){
            this.itemOriginal = jiff.clone(this.item);
            this.Carregar();
        }
    }    

    @Watch("dialog")
    Dialog() {
        if (this.dialog) {
            this.ListarDocumentos(this.item.id);
        }   
        else{
            this.$emit("fechou");
        }     
        if(this.item.fornecedorId){
            this.fornecedores.push(this.item.fornecedor);
        }        
    }

    @Watch('item')
    Item(){
        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }        
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    @Watch('onSearchFornecedor')
    SearchFornecedor (val: string) {
        if (this.isFornecedorLoading) return;
        if(this.item.fornecedorId) return;
        if (!val) return;
        this.isFornecedorLoading = true
        this.fornecedorService.AutoComplete(val).then(
            res => {
                this.fornecedores = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => {
            this.isFornecedorLoading = false;
        });
    }

    mounted() {
        
    }

    Carregar(){
        
    }

    VerificarDatas(){
        if(!this.item.dataHoraInicio || !this.item.dataHoraTermino)
            return  
        if(this.item.dataHoraTermino < this.item.dataHoraInicio){
            AlertSimple('Atenção!','A data de término do Equipamento/Serviço informada é menor que a data de início do Equipamento/Serviço','warning');
        }
    }

    //DOCUMENTOS
    ListarDocumentos(equipamentoId: number){
        this.service.ListarEquipamentoDocumento(equipamentoId).then(
            res => {
                this.documentos = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }
    AbrirDialogDocumento(item?: RegistroOperacionalEquipamentoDocumento){
        if(item){
            console.log("Não implementado")
        }
        else{
            this.equipamentoDocumento = new RegistroOperacionalEquipamentoDocumento();
            this.equipamentoDocumento.equipamentoId = this.item.id;
            this.equipamentoDocumento.documento.empresaId = this.app.empresaId;
            this.dialogEquipamentoDocumento = true;
        }
    }
    AdicionarDocumento(){
        this.equipamentoDocumento.documento.nome = this.equipamentoDocumento.documento.arquivo.name;
        this.documentos.push(this.equipamentoDocumento);
        this.equipamentoDocumento = new RegistroOperacionalEquipamentoDocumento();
        this.dialogEquipamentoDocumento = false;
    }    
    BaixarDocumento(item: RegistroOperacionalEquipamentoDocumento){
        new DocumentoService().Ler(item.documentoId, item.documento.nome);
    }
    ExcluirDocumento(item: RegistroOperacionalEquipamentoDocumento){
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                const index = context.documentos.indexOf(item);
                if(item.id > 0){
                    context.documentos[index].excluir = true;
                }
                else{
                    context.documentos.splice(index, 1);
                }
                context.dialog = false;
                context.dialog = true;
            });
        }
        AlertExcludeQuestion(excluir, true);
    }
    SalvarDocumentos(equipamentoId?: number){
        this.documentos.forEach(documento => {
            if(!(documento.id > 0)){
                return new Promise(async () => {
                    
                    if(equipamentoId)
                        documento.equipamentoId = equipamentoId;
                    
                    let arquivo = await this.LerArquivo(documento.documento.arquivo);
                    this.service.EnviarEquipamentoDocumento(documento, arquivo).then(
                        res => {},
                        err => AlertSimpleErr("Aviso!", err)
                    )
                });
            }
            else{
                if(documento.excluir){
                    this.service.DeletarEquipamentoDocumento(documento.id).then(
                        res => {},
                        err => AlertSimpleErr("Aviso!", err)
                    )
                }
            }
        });
        
        AlertSimple("Aviso", "Operação realizada com sucesso!", "success");
        this.$emit("salvou");
        this.Close();
    }
    LerArquivo(arquivo: any){
        return new Promise((resolve, reject) => {
            let fr = new FileReader();
        
            fr.onload = (arquivo) => {
                resolve(arquivo.target!.result);
            };
        
            fr.readAsArrayBuffer(arquivo);
        });
    }

    Salvar() {
        if (this.$refs.form.validate()) {
            this.loading = true;
            let pacthModel = jiff.diff(this.itemOriginal, this.item, false);
            this.service.EquipamentoSalvar(this.item.id, pacthModel).then(
                res => {
                    if(this.documentos.length){
                        this.SalvarDocumentos(this.item.id);
                    }
                    else{
                        AlertSimpleRes("Aviso!", res)
                        this.$emit("salvou");
                        this.Close();
                    }
                },
                err => AlertSimpleErr("Aviso!", err)
            ).finally(() => {
                this.loading = false;
            });
        }
    }
    Close() {
        this.dialog = false;
    }
}
