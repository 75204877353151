












































import { Component, Prop, Watch } from "vue-property-decorator";
import { PageBase } from '@/core/models/shared';
import { Equipamento, RegistroOperacionalEquipamentoDescritivo } from "@/core/models/operacional";
import { EquipamentoService } from "@/core/services/operacional";
import { AlertSimple, AlertSimpleErr } from "@/core/services/shared/AlertService";

@Component
export default class CadastroRegistroOperacionalEquipamentoDescritivo extends PageBase{
    @Prop() private item!: RegistroOperacionalEquipamentoDescritivo;
    @Prop() private value!: string;

    validDialog = true;
    dialog = false;
    fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
    $refs!: {
        form: HTMLFormElement
    }

    equipamentos: Equipamento[] = [];

    @Watch('item')
    Item(){
        if(this.$refs.form){
            this.$refs.form.resetValidation();
        }
    }

    @Watch("value")
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if(this.dialog){
            this.Carregar();
        }
        else{
            this.$emit("fechou");
        }
    }

    VerificarDatas(){
        if(!this.item.dataHoraInicio || !this.item.dataHoraTermino)
            return
        if(this.item.dataHoraTermino < this.item.dataHoraInicio){
            AlertSimple('Atenção!','A data de término da Equipamento/Serviço não pode ser menor que a data de início da Equipamento/Serviço','warning');
            !this.validDialog;
        }
    }

    Carregar(){
        new EquipamentoService().ListarTudo().then(
            res => {
                this.equipamentos = res.data.items;
            },
            err => AlertSimpleErr("Aviso!", err)
        );
    }

    beforeUpdate() {
        if(!this.dialog){
            this.$emit("fechou");
        }
    }

    Salvar(){
        if (this.$refs.form.validate()) {
            this.item.equipamento = this.equipamentos.find(x => x.id == this.item.equipamentoId)!;
            this.$emit("salvou");
            this.Close();
        }
    }

    Close() {
        this.dialog = false;
    }
}   

